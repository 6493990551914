<template>
  <v-dialog
    fullscreen
    persistent
    transition="slide-x-transition"
    class="elevation-0"
    v-model="opened"
  >
    <div class="signed-transaction-container">
      <v-flex xs12 md6>
        <signature-header :signature="signature" :close="close" />
        <div
          class="windows-style-content pb-6"
          style="max-width: 100%;6min-height: 10vh; max-height: 80vh; overflow: auto;"
        >
          <v-flex xs12>
            <signature-origin :loading="loading" :signature="signature" />
            <signature-path :loading="loading" :signature="signature" />
            <signature-fine-mesh :loading="loading" :signature="signature" />
            <signature-files
              :signature="signature"
              v-model="signature.files"
              :loading="loading"
              @input="updateSignatureFiles"
            />
            <signature-form-payment
              :loading="loading"
              :signature="signature"
              :approve="approve"
              @cancel="cancel"
              v-model="signature.payment_form"
              :value="signature.payment_form"
              @input="val => updatePaymentForm(val)"
            />
            <signature-comments
              :loading="loading"
              :comments="signature.comments"
            />
          </v-flex>
        </div>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SignatureHeader from "@/apps/SignedTransaction/components/Header.vue";
import SignatureFineMesh from "@/apps/SignedTransaction/components/FineMesh.vue";
import SignatureOrigin from "@/apps/SignedTransaction/components/Origin.vue";
import SignaturePath from "@/apps/SignedTransaction/components/Path.vue";
import SignatureFiles from "@/apps/SignedTransaction/components/Files.vue";
import SignatureComments from "@/apps/SignedTransaction/components/Comments.vue";
import SignatureFormPayment from "@/apps/SignedTransaction/components/FormPayment.vue";

export default {
  props: ["updateSignInList"],
  data() {
    return {
      opened: false,
      loading: false,
      signature: {}
    };
  },
  components: {
    SignatureHeader,
    SignatureFineMesh,
    SignatureOrigin,
    SignaturePath,
    SignatureFiles,
    SignatureComments,
    SignatureFormPayment
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createConfirmAction"]),
    setPaymentForm(val) {
      this.signature = {
        ...this.signature,
        payment_form: val
      };
      this.$forceUpdate();
    },
    open(signature) {
      this.signature = signature;
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.signature = {};
    },
    updateSignatureFiles() {
      const files = this.signature.files;
      this.loading = true;
      this.$run("signed_transaction/update-files", {
        files,
        _id: this.signature._id
      }).then(res => {
        this.signature.files = res.data.files;
        this.signature.comments = res.data.comments;
        this.loading = false;
        this.updateSignInList(this.signature);
      });
    },
    approve() {
      this.loading = true;
      this.$run("signed_transaction/sign-transaction", this.signature).then(
        res => {
          this.loading = false;
          this.signature.comments = res.data.comments;
          this.signature.current_status = res.data.current_status;
          this.signature.payment_form = res.data.payment_form;
          this.signature.user_who_approved = res.data.user_who_approved;

          this.updateSignInList(this.signature);
        }
      );
    },
    cancel(reason) {
      this.signature.cancel_reason = reason;
      this.loading = true;
      this.$run("signed_transaction/cancel-sign", this.signature).then(res => {
        this.loading = false;
        this.signature.comments = res.data.comments;
        this.signature.current_status = res.data.current_status;
        this.signature.payment_form = res.data.payment_form;
        this.signature.user_who_reproved = res.data.user_who_reproved;
        this.signature.reprove_date = res.data.reprove_date;

        this.updateSignInList(this.signature);
      });
    },
    updatePaymentForm() {
      this.loading = true;
      this.$run("signed_transaction/update-payment-form", this.signature).then(
        res => {
          this.loading = false;
          this.signature.comments = res.data.comments;
          this.updateSignInList(this.signature);
        }
      );
    }
  }
};
</script>

<style>
.signed-transaction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
</style>
