<template>
  <v-flex v-if="false" class="pa-2" xs12>
    <div class="expande-horizontal column">
      <v-flex xs12>
        <div class="expande-horizontal">
          <v-flex xs12>
            <v-list nav dense class="px-0 mx-0" color="transparent">
              <v-list-item class="px-0 fonte mx-0">
                <v-avatar class="mr-2" size="31" color="#e5e5e5">
                  <v-icon size="20" :color="$theme.primary"
                    >mdi-fingerprint</v-icon
                  >
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Atividade
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Veja o andamento da consulta
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    color="grey"
                    @click="showMovimentation"
                    x-small
                    class="elevation-0"
                  >
                    <span class="fonte text-capitalize"
                      >{{
                        show_movimentation ? "ocultar" : "ver"
                      }}
                      detalhes</span
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-flex>
        </div>
      </v-flex>
      <v-flex
        :class="{ 'pl-7': $vuetify.breakpoint.lgAndUp }"
        class="pt-1"
        xs12
      >
        <div class="expande-horizontal fonte">
          <v-text-field
            append-icon="mdi-send"
            :color="$theme.primary"
            dense
            :disabled="loading"
            solo-inverted
            v-model="comment"
            @keyup.enter.prevent="sendComment"
            @click:append="sendComment"
            placeholder="Escreva um comentário..."
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex :class="{ 'pl-4': $vuetify.breakpoint.lgAndUp }" xs12>
        <v-list dense class="fonte" color="transparent">
          <v-list-item
            v-for="(historic, index) in get_gestordepedido.historico_de_status"
            :key="`${historic.status}-${index}`"
            v-show="show_movimentation"
          >
            <v-list-item-content>
              <span class="fonte fonteMini">{{
                historic.status || historic.description
              }}</span>
              <span class="mt-2 fonteMini grey--text">
                {{
                  historic.executor
                    ? historic.executor
                    : { nome: "..." } | firstName
                }}
                em
                {{
                  $moment(historic.created_at || historic.date).format("lll")
                }}
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(comment, index) in get_gestordepedido.comments"
            :key="`${comment.description}-${index}`"
          >
            <v-list-item-content>
              <v-list-item-title class="fonte">
                <span>
                  {{ comment.description }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="fonteMini grey--text">
                  {{ comment.executor | firstName }} em
                  {{ $moment(comment.created_at).format("lll") }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      tab: 0,
      comment: "",
      loading: false,
      show_movimentation: false
    };
  },
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  filters: {
    firstName(val) {
      if (!val.nome) return "";
      return val.nome.split(" ")[0] || "";
    }
  },
  methods: {
    ...mapActions([
      "createGlobalMessage",
      "listar_gestordepedidos",
      "abre_modal_view_gestordepedido",
      "fecha_modal_view_gestordepedido"
    ]),
    showMovimentation() {
      this.show_movimentation = !this.show_movimentation;
    },
    sendComment() {
      if (this.comment.length > 0) {
        this.loading = true;
        this.$run("transacoes/make-comment", {
          _id: this.get_gestordepedido._id,
          comment: this.comment
        })
          .then(res => {
            this.loading = false;
            this.comment = "";
            this.listar_gestordepedidos(true);
            let sale = res.data;
            sale.comment = sale.comments ? sale.comments.reverse() : [];
            this.$store.commit("set_gestordepedido", sale);
          })
          .catch(e => {
            this.loading = false;
            this.createGlobalMessage({
              type: "error",
              text: e.message
            });
          });
      }
    }
  }
};
</script>
