<template>
  <div
    v-show="false"
    class="expande-horizontal vitrify-content expande-vh-vertical centraliza"
  >
    <v-flex xs12 md6>
      <div class="expande-horizontal wrap">
        <v-flex class="animate__animated" :class="personalizedClass" xs12>
          <div class="expande-horizontal column centraliza">
            <span class="fonte-boas-vindas white--text"
              >Olá, {{ firstName }}!</span
            >
            <span class="fonte-boas-vindas-subtitle white--text">
              Escolha uma de suas empresas abaixo!
            </span>
          </div>
        </v-flex>
        <!-- LISTANDO EMPRESAS DO ADMIN -->
        <v-flex xs12 class="pa-3 pt-6 pb-6 animate__animated animate__fadeInUp">
          <div class="expande-horizontal centraliza wrap">
            <v-flex xs12 md8>
              <div class="expande-horizontal wrap">
                <v-flex xs12>
                  <v-list color="transparent" class="pa-3 fonte ma-0">
                    <template v-for="(empresa, index) in getLoggedUser.tenant">
                      <v-list-item
                        @click="selecionarEmpresa(empresa, index)"
                        :key="empresa._id"
                        class="pa-3 mb-2 ma-0 item-list animate__animated"
                        :class="
                          selectedIndex === index
                            ? personalizedClass2
                            : personalizedClass
                        "
                      >
                        <v-avatar
                          class="mr-3 elevation-1"
                          dark
                          :color="$theme.primary"
                        >
                          <v-icon :color="$theme.icon_drawer"
                            >mdi-storefront</v-icon
                          >
                        </v-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="fonte-default white--text font-weight-bold"
                          >
                            {{ empresa.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="white--text">
                            Clique para entrar
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            @click="selecionarEmpresa(empresa, index)"
                            color="#fff"
                            icon
                            dark
                          >
                            <v-icon size="18" class="ml-1"
                              >mdi-open-in-new</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </v-flex>
        <!-- BOTÃO PARA ACESSAR DE FATO -->
        <v-flex xs12 class="animate__animated" :class="personalizedClass">
          <div class="expande-horizontal centraliza">
            <v-btn @click="logout" color="red lighten-1" text dark>
              <span
                class="fonte-boas-vindas-subtitle font-weight-light text-capitalize fonte-italica"
              >
                Sair
              </span>
            </v-btn>
          </div>
        </v-flex>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      personalizedClass: "animate__fadeInUp",
      personalizedClass2: "animate__fadeInUp",
      selectedIndex: false,
      indexIsSelected: false,
      empresas: []
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    firstName() {
      return this.getLoggedUser.nome.split(" ")[0];
    },
    empresasComputed() {
      if (this.selectedIndex) return this.getLoggedUser.empresas;
      return this.empresas;
    }
  },
  methods: {
    ...mapActions(["logout", "listar_gestordepedidos"]),
    animeIn() {},
    animeOut() {
      this.personalizedClass = "animate__fadeOut";
      this.personalizedClass2 = "animate__pulse animate__infinite";
    },
    selecionarEmpresa(item, index) {
      this.empresas.push(item);
      this.animeOut();
      if (this.getLoggedUser.is_patient) {
        this.$router.push("/dashboard");
      } else {
        this.$run("funcionarios/set-active-tenant", { activeTenant: item })
          .then(res => {
            this.$store.commit("setLoggedUser", res.data.user);
            this.$store.commit("setToken", res.data.token);
            localStorage.user = JSON.stringify(res.data.user);
            localStorage.token = JSON.stringify(res.data.token);
            this.personalizedClass2 = "animate__pulse animate__zoomOut";
            this.listar_gestordepedidos();
            setTimeout(() => {
              this.$router.push(res.data.user.permissao.rota_inicial);
            }, 1000);
          })
          .catch(e => {
            console.log("erro l", e);
          });
      }
      this.selectedIndex = index;
      this.indexIsSelected = true;
    }
  },
  mounted() {
    // console.log("user", this.getLoggedUser);
    if (this.getLoggedUser.is_patient) {
      this.$router.push("/dashboard");
    } else {
      this.selecionarEmpresa(this.getLoggedUser.tenant[0], 0);
    }
    // if (this.getLoggedUser.permissao.type === "vendedor") {
    //   const rota_inicial = this.getLoggedUser.permissao.rota_inicial;
    //   this.$router.push(rota_inicial);
    // }
  }
};
</script>

<style scoped>
.item-list {
  border-radius: 6px;
  background: #333;
}
</style>
