<template>
  <div class="expande-horizontal px-3 wrap">
    <v-timeline align-top dense>
      <v-timeline-item
        small
        :color="$theme.primary"
        fill-dot
        v-for="(comment, index) in comments"
        :key="`comment-${index}`"
      >
        <template v-slot:icon>
          <v-icon size="19" color="#333">mdi-fingerprint</v-icon>
        </template>
        <span class="fonte fonteMini not-selectable">
          {{ comment.comment }}
          <span class="grey--text">
            {{ $moment(comment.date).format("MM/DD/YYYY [às] HH:mm") }}
          </span>
        </span>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
export default {
  props: ["comments"]
};
</script>
