<template>
  <layout
    style="background: url('bg.jpg'); background-size: cover;"
    :title="$route.name"
    :rotas="rotas"
    :options="options"
  >
    <div
      v-if="false"
      style="background: rgba(0,0,0,0.1); min-height: 100vh;"
      class="centraliza expande-horizontal wrap"
    >
      <v-flex class="px-4" xs12 md8>
        <div
          class="expande-horizontal centraliza fonte pa-3 column cool-shaddow"
          style="justify-content: center; min-height: 20vh; background: #f7f7f7; border-radius: 16px; border: 1px solid #e5e5e5"
        >
          <img width="200" src="login.png" alt="img" />
          <h2
            style="font-size: 16pt;"
            class="font-weight-bold mb-3 text-center grey--text"
          >
            {{ $moment().format("ll") }}
            <!-- Serviços -->
          </h2>
          <span
            style="font-size: 14pt;"
            class="font-weight-bold mb-0 text-center black--text"
            >Bem vindo, {{ getLoggedUser.nome.split(" ")[0] }},</span
          >
          <span class="mt-0 mb-6 px-6 text-center grey--text"
            >em que podemos ajudar?</span
          >
          <div id="container-menus" class="expande-horizontal centraliza wrap">
            <v-card
              v-for="(menu, index) in menus"
              v-show="menu.permission()"
              :key="`item-menu-${index}`"
              width="85"
              :color="$theme.primary"
              class="mr-2 mb-2 py-2"
              @click="menu.action"
              hover
              style="border-radius: 12px;"
            >
              <div
                style="min-height: 60px; max-height: 60px;"
                class="expande-horizontal column centraliza"
              >
                <v-icon color="white" size="30">{{ menu.icon }}</v-icon>
                <v-spacer></v-spacer>
                <span class="fonteMini white--text text-center">{{
                  menu.name
                }}</span>
              </div>
            </v-card>
          </div>
        </div>
      </v-flex>
    </div>
    <div
      class="expande-horizontal column"
      style="background: #fff; min-height: 94vh;"
    >
      <v-btn
        color="pink"
        dark
        absolute
        bottom
        right
        fab
        @click="new_consult_modal = true"
        style="position: fixed; border-radius: 12px;margin-bottom: 120px;"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-flex xs12>
        <Consults v-if="tabs == 0" />
        <Documents :tab="tabs" v-if="tabs > 0" />
      </v-flex>
      <v-dialog width="500" v-model="new_consult_modal">
        <div class="expande-horizontal" style="overflow: hidden;">
          <v-flex xs12>
            <v-card class="expande-horizontal" color="#f7f7f7">
              <div class="expande-horizontal centraliza wrap">
                <v-flex xs12>
                  <v-list color="transparent" nav dense class="fonte">
                    <v-list-item class="px-0 pl-2">
                      <v-list-item-content>
                        <v-list-item-title>Tipo de consulta</v-list-item-title>
                        <span class="fonte fonteMini grey--text"
                          >Veja os tipos de consulta disponíveis abaixo</span
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          icon
                          @click="new_consult_modal = false"
                          color="blue"
                          dark
                        >
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12>
                  <div class="windows-style-content expande-horizontal wrap">
                    <v-list dense nav>
                      <v-list-item
                        @click="
                          $router.push('/atendimento-imediato?type=imediata')
                        "
                      >
                        <v-list-item-content>
                          <span class="fonte mb-2" style="font-size: 12pt;">
                            Consulta Imediata
                          </span>
                          <span
                            class="fonte grey--text"
                            style="font-size: 9pt;"
                          >
                            Seu Pronto Atendimento 24 horas por dia, 7 dias por
                            semana.
                          </span>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        @click="
                          $router.push('/atendimento-agendado?type=agendada')
                        "
                      >
                        <v-list-item-content>
                          <span class="fonte mb-2" style="font-size: 12pt;">
                            Especialidades Médicas
                          </span>
                          <span
                            class="fonte grey--text"
                            style="font-size: 9pt;"
                          >
                            Escolha a especialidade médica de sua necessidade e
                            agende sua consulta.
                          </span>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        @click="
                          $router.push('/atendimento-agendado?type=retorno')
                        "
                      >
                        <v-list-item-content>
                          <span class="fonte mb-2" style="font-size: 12pt;">
                            Retorno
                          </span>
                          <span
                            class="fonte grey--text"
                            style="font-size: 9pt;"
                          >
                            Retorne com seu mesmo atendimento médico após fazer
                            seus exames.
                          </span>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-flex>
              </div>
            </v-card>
          </v-flex>
        </div>
      </v-dialog>
      <v-bottom-navigation
        height="75"
        dark
        background-color="blue darken-3"
        fixed
        bottom
      >
        <v-btn
          value="0"
          :class="{ 'white--text font-weight-bold': tabs == 0 }"
          active-class="white--text font-weight-bold"
          @click="tabs = 0"
          class="px-1"
        >
          <span class="fonte fonteMini">Consultas</span>

          <v-icon>mdi-card-account-details-outline</v-icon>
        </v-btn>
        <v-btn
          value="1"
          :class="{ 'white--text font-weight-bold': tabs == 1 }"
          active-class="white--text font-weight-bold"
          @click="tabs = 1"
          class="px-1"
        >
          <span v-if="$vuetify.breakpoint.smAndDown" class="fonte fonteMini"
            >mentos</span
          >
          <span v-if="$vuetify.breakpoint.smAndDown" class="fonte fonteMini"
            >Encaminha</span
          >
          <span v-else class="fonte fonteMini">Encaminhamentos</span>

          <v-icon>mdi-account-supervisor-circle-outline</v-icon>
        </v-btn>
        <v-btn
          value="2"
          :class="{ 'white--text font-weight-bold': tabs == 2 }"
          active-class="white--text font-weight-bold"
          @click="tabs = 2"
          class="px-1"
        >
          <span class="fonte fonteMini">Exames</span>

          <v-icon>mdi-medication-outline</v-icon>
        </v-btn>
        <v-btn
          value="3"
          :class="{ 'white--text font-weight-bold': tabs == 3 }"
          active-class="white--text font-weight-bold"
          @click="tabs = 3"
          class="px-1"
        >
          <span class="fonte fonteMini">Receitas</span>

          <v-icon>mdi-pill-multiple</v-icon>
        </v-btn>
        <v-btn
          value="4"
          :class="{ 'white--text font-weight-bold': tabs == 4 }"
          active-class="white--text font-weight-bold"
          @click="tabs = 4"
          class="px-1"
        >
          <span class="fonte fonteMini">Atestados</span>

          <v-icon>mdi-account-injury-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </div>
  </layout>
</template>

<script>
import { mapGetters } from "vuex";
import { driver } from "driver.js";
import Consults from "@/apps/GestorDePedido/views/MainPatientAvulse.vue";
import Documents from "@/apps/GestorDePedido/views/Documents.vue";

export default {
  components: {
    Consults,
    Documents
  },
  data() {
    return {
      tabs: 0,
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        }
      ],
      new_exam_modal: false,
      new_consult_modal: false,
      driver: null,
      options: [],
      menus: [
        {
          icon: "mdi-camera-front-variant",
          name: "Consultas",
          route: "/atendimento-imediato",
          action: () => (this.new_consult_modal = true),
          permission: () => true
        },
        {
          icon: "mdi-camera-front-variant",
          name: "Exames",
          route: "/solicitar-exame",
          action: () => (this.new_exam_modal = true),
          permission: () => true
        },
        {
          icon: "mdi-medical-bag",
          name: "Prontuário",
          action: () => this.$router.push("/prontuario"),
          route: "/prontuario",
          permission: () => true
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["get_dashboard", "getLoggedUser", "get_permission_user"])
  },
  methods: {
    mountDriver() {
      this.driver = driver({
        showProgress: true,
        steps: [
          {
            element: "#container-menus",
            popover: {
              title: "Bem vindo",
              description:
                "Na dashboard você visualiza os menus que você tem acesso.",
              doneBtnText: "Sair",
              closeBtnText: "Fechar",
              nextBtnText: "Próximo",
              prevBtnText: ""
            }
          },
          {
            element: "#options-container",
            popover: {
              title: "Botões de ação",
              description: "Ações da página sempre aparecerão aqui.",
              doneBtnText: "Sair",
              closeBtnText: "Fechar",
              nextBtnText: "Próximo",
              prevBtnText: "Anterior"
            }
          },
          {
            element: "#notifications-container",
            popover: {
              title: "Notificações",
              description:
                "Quando algo precisar da sua atenção, você será avisado com uma notificação.",
              doneBtnText: "Sair",
              closeBtnText: "Fechar",
              nextBtnText: "Próximo",
              prevBtnText: "Anterior"
            }
          }
          // { element: '.top-nav', popover: { title: 'Title', description: 'Description' } },
          // { element: '.sidebar', popover: { title: 'Title', description: 'Description' } },
          // { element: '.footer', popover: { title: 'Title', description: 'Description' } },
        ]
      });
    },
    driverStart() {
      this.driver.drive();
    }
  },
  created() {
    this.mountDriver();
  }
};
</script>

<style>
.fonte-color {
  color: #075059;
}
.icon-show-balance {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px dashed #000;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.divider-balance {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #000;
}
.fullheight {
  min-height: 100vh;
}
</style>
