<template>
  <v-flex xs12>
    <div class="wrap expande-horizontal pa-2">
      <v-flex xs12>
        <v-flex xs12>
          <div class="expande-horizontal">
            <v-flex xs12>
              <v-list nav dense class="px-0 mx-0" color="transparent">
                <v-list-item @click="openEvent" class="px-0 fonte mx-0">
                  <v-avatar class="mr-2" size="31" color="#333">
                    <v-icon size="20" :color="$theme.primary"
                      >mdi-map-marker-path</v-icon
                    >
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      Agendamento
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Agendado para o dia
                      {{ external_movimentation.visit_date }}
                      {{ external_movimentation.visit_hour }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="grey darken-3" size="21"
                      >mdi-calendar-edit</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
          </div>
        </v-flex>

        <div
          :class="{ 'pl-7': $vuetify.breakpoint.lgAndUp }"
          class="expande-horizontal wrap pt-2 mb-2"
        >
          <v-flex xs12>
            <v-list color="transparent" dense>
              <v-list-item
                class="fonte"
                v-if="external_movimentation.address_destiny"
                style="margin-bottom:6px; background: #EBEBEB; border-radius: 6px;"
              >
                <v-icon class="mr-2">mdi-map-marker-path</v-icon>
                <v-list-item-content>
                  <span>
                    {{ external_movimentation.address_destiny.street }}, bairro
                    {{ external_movimentation.address_destiny.bairro }},
                    {{ external_movimentation.address_destiny.number }},
                    {{ external_movimentation.address_destiny.city }} -
                    {{ external_movimentation.address_destiny.state }}
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="fonte"
                style="margin-bottom:6px; background: #EBEBEB; border-radius: 6px;"
              >
                <v-icon class="mr-2">mdi-truck-delivery-outline</v-icon>
                <v-list-item-content>
                  <span v-if="executor">
                    {{ executor.nome }}
                  </span>
                  <span class="fonteMini">
                    {{ executor.cpf }}
                  </span>
                  <span class="grey--text fonteMini">
                    responsável pela entrega
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="fonte"
                style="margin-bottom:6px; background: #EBEBEB; border-radius: 6px;"
              >
                <v-icon
                  :color="iconStatusColor(external_movimentation.status)"
                  class="mr-2"
                  >{{ iconStatus(external_movimentation.status) }}</v-icon
                >
                <v-list-item-content>
                  <span class="fonteMini">
                    {{ external_movimentation.status | filterStatus }}
                  </span>
                  <span class="fonteMini">
                    Tipo de Agendamento:
                    {{ external_movimentation.type | filterType }}
                  </span>
                  <span class="grey--text fonteMini">
                    Situação da entrega
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
export default {
  props: {
    external_movimentation: {
      type: Object,
      default: () => {}
    },
    openEvent: {
      type: Function,
      default: () => {}
    }
  },
  filters: {
    filterStatus(val) {
      switch (val) {
        case "waiting":
          return "Agendado";
        case "on_the_way":
          return "Saiu para entrega";
        case "done":
          return "Entregue";
        case "canceled":
          return "Cancelado";
        default:
          return "Pendente";
      }
    },
    filterType(val) {
      const types = [
        {
          name: "Visita",
          value: "visit"
        },
        {
          name: "Data limite de entrega",
          value: "limit_date"
        },
        {
          name: "Entrega",
          value: "delivery"
        },
        {
          name: "Ligação",
          value: "ligação"
        },
        {
          name: "E-mail",
          value: "email"
        },
        {
          name: "Whatsapp",
          value: "whatsapp"
        },
        {
          name: "Outros",
          value: "other"
        }
      ];
      return types.find(t => t.value == val).name;
    }
  },
  methods: {
    iconStatus(status) {
      switch (status) {
        case "waiting":
          return "mdi-clock-outline";
        case "on_the_way":
          return "mdi-truck-delivery-outline";
        case "done":
          return "mdi-check-circle-outline";
        case "canceled":
          return "mdi-close-circle-outline";
        default:
          return "mdi-clock-outline";
      }
    },
    iconStatusColor(status) {
      switch (status) {
        case "waiting":
          return "blue";
        case "on_the_way":
          return "orange";
        case "done":
          return "green";
        case "canceled":
          return "red";
        default:
          return "grey";
      }
    }
  },
  computed: {
    executor() {
      const funcionario = this.$store.getters["get_funcionarios"].docs.find(
        u => this.external_movimentation.executor == `${u._id}`
      );
      return funcionario || {};
    }
  }
};
</script>
