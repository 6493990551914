<template>
  <v-dialog persistent width="600" v-model="opened">
    <v-flex class="fonte" xs12>
      <v-card style="border-radius: 12px;">
        <v-flex xs12>
          <div
            class="windows-style"
            style="border-top-right-radius: 12px; border-top-right-radius: 12px;"
          >
            <div class="expande-horizontal">
              <v-flex xs12>
                <v-list color="transparent" dense nav>
                  <!-- Escolher especialidade -->
                  <v-list-item v-if="step == '0'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{
                          triage.escolher_especialidade.title
                        }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">{{
                        triage.escolher_especialidade.description
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="step == '1'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">
                          Tudo certo
                        </span>
                      </v-list-item-title>
                      <span class="fonte grey--text">próximos passos</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
          </div>
        </v-flex>
        <div class="pa-3 windows-style-content">
          <v-window v-model="step">
            <!-- escolher a especialidade -->
            <v-window-item value="0">
              <div class="expande-horizontal wrap">
                <v-flex v-if="!loading" class="pa-3" xs12>
                  <div
                    v-if="specialties.length > 0"
                    class="expande-horizontal fonte centraliza"
                  >
                    <v-chip
                      :key="specialty._id"
                      v-for="specialty in specialties"
                      @click="selectSpecialty(specialty)"
                      class="mr-2 mb-2"
                    >
                      <v-icon size="17" class="mr-2"
                        >mdi-checkbox-blank-circle-outline</v-icon
                      >
                      {{ specialty.nome }}
                    </v-chip>
                  </div>
                  <span v-else class="expande-horizontal fonte centraliza"
                    >Não há especialidades disponíveis</span
                  >
                </v-flex>
                <v-flex v-else class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    Carregando especialidades disponíveis...
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <v-window-item value="1">
              <div class="expande-horizontal wrap">
                <v-flex v-if="loading" class="pa-3" xs12>
                  <div class="expande-horizontal py-6 centraliza column">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <span class="text-center px-6 mt-3"
                      >Confirmando encaminhamento.</span
                    >
                  </div>
                </v-flex>
                <v-flex v-else class="pa-3" xs12>
                  <div class="expande-horizontal py-6 centraliza column">
                    <v-icon class="ma-12" size="40">
                      mdi-check-circle
                    </v-icon>
                    <span class="text-center px-6 mt-3"
                      >O encaminhamento está confirmado para
                      {{ specialty.nome }}.</span
                    >
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centraliza px-3">
                    <v-btn
                      rounded
                      class="mr-2"
                      color="green"
                      dark
                      @click="finalize"
                      >Sair
                    </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import TriagemFiles from "./TriagemFiles.vue";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  components: {
    LottieAnimation,
    TriagemFiles
  },
  methods: {
    searchSpecialties() {
      this.step = "0";
      this.loading = true;
      this.$run("produtos/list-all-specialty").then(res => {
        this.specialties = res.data.docs;
        this.loading = false;
      });
    },
    selectSpecialty(specialty) {
      this.step = "1";
      this.loading = true;
      this.specialty = specialty;
      this.$run("transacoes/request-forwarding", {
        transaction_id: this.get_gestordepedido._id,
        specialty
      })
        .then(res => {
          this.loading = false;
          this.$store.commit("set_gestordepedido", res.data);
        })
        .catch(err => {
          this.step = "0";
          this.loading = false;
          this.$store.dispatch("createGlobalMessage", {
            type: "error",
            timeout: 5000,
            message: "Erro ao solicitar encaminhamento, tente novamente!"
          });
        });
    },
    finalize() {
      this.opened = false;
      this.step = "0";
    },
    open() {
      this.opened = true;
      this.searchSpecialties();
    }
  },
  data() {
    return {
      anexo_encaminhamento: [],
      specialties: [],
      specialty: {},
      doctors: [],
      doctor: {},
      loading: false,
      disponibilities: [],
      disponibility: {},
      opened: false,
      step: "0",
      files: [],
      triage: {
        //1
        encaminhamento: {
          title: "Você tem encaminhamanto?",
          description: "",
          response: ""
        },
        //2
        anexar_encaminhamento: {
          title: "Anexe o encaminhamento",
          description: "Clique no botão abaixo e anexe o encaminhamento?",
          response: []
        },
        //3
        nao_tem_encaminhamento: {
          title: "Clínico Geral",
          description:
            "Como você ainda não possui encaminhamento, você precisa se consultar primeiro com um clínico geral",
          options: []
        },
        escolher_especialidade: {
          title: "Escolha a especialidade",
          description:
            "Escolha a especialidade que você deseja encaminhar o paciente",
          options: []
        },
        escolher_especialista: {
          title: "Escolha o médico disponível",
          description: "Veja os profissionais e horários disponíveis abaixo",
          options: []
        },
        escolher_horario: {
          title: "Escolha o dia da consulta",
          description: "Veja os profissionais e horários disponíveis abaixo",
          especialidade: {},
          horario: {}
        }
      }
    };
  }
};
</script>
