var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expande-horizontal windows-style-content",style:(`${
      _vm.$vuetify.breakpoint.smAndUp ? 'max-height: 72vh;' : 'max-height: 95vh'
    }; overflow: auto;`)},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"px-1 expande-horizontal wrap"},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal centraliza column"},[(_vm.showTimeRemaining)?_c('h1',[_vm._v(" "+_vm._s(_vm.timeRemainingAtExpire)+" ")]):_vm._e(),(_vm.showTimeAtConsult)?_c('h1',[_vm._v(" "+_vm._s(_vm.timeRemainingAtConsult)+" ")]):_vm._e(),(_vm.consultIsExpired())?_c('h1',{staticClass:"red--text"},[_vm._v(" 00:00:00 ")]):_vm._e(),(_vm.showTimeRemaining)?_c('span',{staticClass:"fonte grey--text fonteMini text-center"},[_vm._v("Tempo restante da consulta")]):_vm._e(),(_vm.showTimeAtConsult)?_c('span',{staticClass:"fonte grey--text fonteMini text-center"},[_vm._v("A consulta começa em")]):_vm._e(),(_vm.consultIsExpired())?_c('span',{staticClass:"fonte grey--text fonteMini text-center"},[_vm._v("Esta consulta acabou")]):_vm._e()])]),_c('v-flex',{staticClass:"mt-3",attrs:{"xs12":""}},[_c('v-divider')],1)],1),_c('h4',{staticClass:"fonte mx-3 font-weight-light mt-2"},[_vm._v("Dados da consulta")]),(
        !_vm.getLoggedUser.is_patient &&
          !_vm.get_gestordepedido.compra.allow_return &&
          !_vm.get_gestordepedido.compra.allow_forwarding
      )?_c('v-btn',{staticClass:"ma-1",attrs:{"dark":"","color":"blue darken-3","rounded":"","small":"","depressed":""},on:{"click":function($event){return _vm.$refs.solicitarRetorno.open()}}},[_vm._v("Disponibilizar Retorno")]):_vm._e(),(
        _vm.get_gestordepedido.compra.allow_return &&
          !_vm.get_gestordepedido.compra.allow_forwarding
      )?_c('v-btn',{staticClass:"ma-1",attrs:{"rounded":"","small":"","depressed":""}},[_vm._v("Retorno liberado "),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"21","color":"green"}},[_vm._v("mdi-check")])],1):_vm._e(),(
        _vm.get_gestordepedido.compra.status &&
          (_vm.get_gestordepedido.compra.status === 'agendada' ||
            _vm.get_gestordepedido.compra.status === 'attending') &&
          _vm.get_gestordepedido.compra.disponibility
      )?_c('div',{staticClass:"pa-2 pt-1 expande-horizontal column"},[_c('div',{staticClass:"expande-horizontal"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.$theme.primary}},[_vm._v(" mdi-phone-in-talk-outline ")]),_c('span',{staticClass:"fonte fonteMini"},[_vm._v("A consulta está agendada para o dia "+_vm._s(_vm.get_gestordepedido.compra.disponibility.visit_date)+", no horário de "+_vm._s(_vm.get_gestordepedido.compra.disponibility.visit_hour_start)+" às "+_vm._s(_vm.get_gestordepedido.compra.disponibility.visit_hour_end))])],1)]):_vm._e(),_c('v-divider'),(!_vm.getLoggedUser.is_patient)?_c('v-btn',{attrs:{"large":"","block":"","color":_vm.$theme.primary},on:{"click":_vm.openMevo}},[_vm._v("Abrir Receituário")]):_vm._e(),_c('v-list',{attrs:{"color":"transparent","dense":"","nav":""}},[(
          _vm.get_gestordepedido.compra.cliente &&
            _vm.get_gestordepedido.compra.cliente.nome
        )?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"fonte"},[_vm._v("Paciente")]),_c('v-list-item-subtitle',{staticClass:"fonte fonteMini"},[_vm._v(" "+_vm._s(_vm.get_gestordepedido.compra.cliente ? _vm.get_gestordepedido.compra.cliente.nome : "Não informado")+" ")]),(
              _vm.get_gestordepedido.compra && _vm.get_gestordepedido.compra.cliente
            )?_c('v-list-item-subtitle',[_vm._v("CPF: "+_vm._s(_vm.get_gestordepedido.compra.cliente.cpf))]):_vm._e()],1)],1):_vm._e(),_c('v-divider'),(_vm.get_gestordepedido.doctor && _vm.get_gestordepedido.doctor.nome)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"fonte"},[_vm._v("Médico")]),_c('v-list-item-subtitle',{staticClass:"fonte fonteMini"},[_vm._v(" "+_vm._s(_vm.get_gestordepedido.doctor.nome ? _vm.get_gestordepedido.doctor.nome : "Não informado")+" ")])],1)],1):_vm._e(),_c('v-divider'),(
          _vm.get_gestordepedido.compra.anexo_encaminhamento &&
            _vm.get_gestordepedido.compra.anexo_encaminhamento.length > 0
        )?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"fonte"},[_vm._v("Anexo do encaminhamento")]),_c('v-list-item-subtitle',_vm._l((_vm.get_gestordepedido.compra
                .anexo_encaminhamento),function(file,index){return _c('v-chip',{key:`${file.name}-${index}`,staticClass:"ma-1",staticStyle:{"border":"1px solid #d0cfcf","display":"flex","align-items":"center","justify-content":"center","height":"30px"},attrs:{"color":"#e5e5e5","small":""}},[_c('span',{staticClass:"mr-1 font-weight-bold fonteMini text-uppercase signed-gradient"},[_vm._v(_vm._s(file.format))]),_c('a',{staticClass:"text-lowercase",staticStyle:{"color":"#555","text-decoration":"none","cursor":"pointer"},attrs:{"target":"_blank","href":file.link}},[_vm._v(" "+_vm._s(file.name)+" ")])])}),1)],1)],1):_vm._e(),_c('v-divider'),(
          _vm.get_gestordepedido.compra.triagem &&
            _vm.get_gestordepedido.compra.triagem.o_que_voce_esta_sentindo
        )?_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"fonte mb-2",staticStyle:{"font-size":"11pt","font-weight":"500"}},[_vm._v("O que está sentindo")]),_c('div',{staticClass:"expande-horizontal wrap"},_vm._l((_vm.get_gestordepedido.compra.triagem
                .o_que_voce_esta_sentindo.options),function(opt){return _c('v-chip',{key:opt.text,staticClass:"mr-2 mb-2",attrs:{"small":"","dark":opt.value,"color":opt.value ? _vm.$theme.primary : ''}},[(!opt.value)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-blank-circle-outline")]):_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v(" "+_vm._s(opt.text)+" ")],1)}),1)])],1):_vm._e(),(
          _vm.get_gestordepedido.compra.triagem &&
            _vm.get_gestordepedido.compra.triagem.o_que_voce_esta_sentindo
        )?_c('v-divider'):_vm._e(),(
          _vm.get_gestordepedido.compra &&
            _vm.get_gestordepedido.compra.triagem.problema_de_saude
        )?_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"fonte mb-2",staticStyle:{"font-size":"11pt","font-weight":"500"}},[_vm._v("Tem algum problema de saúde ou toma medicamento todos os dias?")]),_c('div',{staticClass:"expande-horizontal wrap"},[_c('v-chip',{staticClass:"mr-2 mb-2",attrs:{"dark":_vm.get_gestordepedido.compra.triagem.problema_de_saude
                  .response == 'não',"color":_vm.get_gestordepedido.compra.triagem.problema_de_saude
                  .response === 'não'
                  ? _vm.$theme.primary
                  : '',"small":""}},[(
                  _vm.get_gestordepedido.compra.triagem.problema_de_saude
                    .response != 'não'
                )?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-blank-circle-outline")]):_vm._e(),(
                  _vm.get_gestordepedido.compra.triagem.problema_de_saude
                    .response == 'não'
                )?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e(),_vm._v(" Não ")],1),_c('v-chip',{staticClass:"mr-2 mb-2",attrs:{"dark":_vm.get_gestordepedido.compra.triagem.problema_de_saude
                  .response == 'sim',"color":_vm.get_gestordepedido.compra.triagem.problema_de_saude
                  .response === 'sim'
                  ? _vm.$theme.primary
                  : '',"small":""}},[(
                  _vm.get_gestordepedido.compra.triagem.problema_de_saude
                    .response != 'sim'
                )?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-blank-circle-outline")]):_vm._e(),(
                  _vm.get_gestordepedido.compra.triagem.problema_de_saude
                    .response == 'sim'
                )?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e(),_vm._v(" Sim ")],1)],1)])],1):_vm._e(),(
          _vm.get_gestordepedido.compra &&
            _vm.get_gestordepedido.compra.triagem.problema_de_saude
        )?_c('v-divider'):_vm._e(),(_vm.get_gestordepedido.compra.triagem.alergia)?_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"fonte mb-2",staticStyle:{"font-size":"11pt","font-weight":"500"}},[_vm._v("Alergias")])])],1):_vm._e(),(_vm.get_gestordepedido.compra.triagem.alergia)?_vm._l((_vm.get_gestordepedido.compra.triagem.alergia.options),function(opt){return _c('v-list-item',{key:opt.text},[_c('v-list-item-content',[_c('span',{staticClass:"fonte mb-2",staticStyle:{"font-size":"10pt","font-weight":"500"}},[_vm._v(_vm._s(opt.text))]),_c('div',{staticClass:"expande-horizontal wrap"},[_c('v-chip',{staticClass:"mr-1 mb-1",attrs:{"small":"","dark":opt.response == 'não',"color":opt.response === 'não' ? _vm.$theme.primary : ''}},[(opt.response != 'não')?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-blank-circle-outline")]):_vm._e(),(opt.response == 'não')?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e(),_vm._v(" Não ")],1),_c('v-chip',{staticClass:"mr-1 mb-1",attrs:{"dark":opt.response == 'sim',"color":opt.response === 'sim' ? _vm.$theme.primary : '',"small":""}},[(opt.response != 'sim')?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-blank-circle-outline")]):_vm._e(),(opt.response == 'sim')?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"17"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e(),_vm._v(" Sim ")],1)],1)])],1)}):_vm._e(),(_vm.get_gestordepedido.compra.triagem.alergia)?_c('v-divider'):_vm._e()],2),_c('span',{staticClass:"fonte px-3"},[_vm._v("Prescrições")]),(
        _vm.get_gestordepedido.documents_mevo &&
          _vm.get_gestordepedido.documents_mevo.length > 0
      )?_c('v-list',{attrs:{"three-line":"","color":"transparent","dense":"","nav":""}},[_vm._l((_vm.get_gestordepedido.documents_mevo),function(prescricao,i){return [_c('v-list-item',{key:prescricao.payload.CodigoValidacao},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Código: "+_vm._s(prescricao.payload.CodigoValidacao)+" ")]),(prescricao.response)?_c('v-list-item-title',[_vm._v(" Tipo: "+_vm._s(prescricao.response.Documentos[0].Categoria)+" ")]):_vm._e(),_c('v-list-item-subtitle',[(prescricao.status == 'Prescrição iniciada')?_c('v-icon',{attrs:{"color":"orange","size":"21"}},[_vm._v("mdi-receipt-text-clock-outline")]):_c('v-icon',{attrs:{"color":"green","size":"21"}},[_vm._v("mdi-receipt-text-check-outline")]),_vm._v(" Status: "+_vm._s(prescricao.status)+" ")],1),_c('v-list-item-subtitle',[_vm._v(" Emitida em "+_vm._s(_vm.$moment(prescricao.data).format("DD/MM/YY [às] HH:mm"))+" ")])],1),(prescricao.response)?_c('v-list-item-action',[_c('a',{attrs:{"target":"_blank","href":prescricao.response.PDFUrl}},[_vm._v("ver")])]):_vm._e()],1),_c('v-divider',{key:`divider-${i}`})]})],2):_c('div',{staticClass:"expande-horizontal centraliza",staticStyle:{"height":"200px"}},[_c('span',{staticClass:"fonte"},[_vm._v("Não há prescrições")])]),_c('h3',{staticClass:"fonte mx-3"},[_vm._v("Arquivos")]),_c('span',{staticClass:"mx-3 grey--text"},[_vm._v("clique no ícone abaixo para anexar arquivos")]),_c('files',{attrs:{"v-model":_vm.get_gestordepedido.files,"value":_vm.get_gestordepedido.files || [],"sale":_vm.get_gestordepedido},on:{"input":function($event){return _vm.updateFiles($event)}}})],1),_c('EventDialog',{ref:"eventDialog",attrs:{"origin":"sale-body"}}),_c('SolicitarRetorno',{ref:"solicitarRetorno"}),_c('SolicitarEncaminhamento',{ref:"solicitarEncaminhamento"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }