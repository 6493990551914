<template>
  <layout
    :title="$vuetify.breakpoint.smAndUp ? $route.name : 'Gestor'"
    :rotas="rotas"
    :options="options"
    style="min-height: 100vh; background: #f7f7f7;"
  >
    <template v-slot:toolbar-search>
      <Filters :filter="filter" />
    </template>
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="expande-horizontal px-3 mt-3 mb-3"
    >
      <Filters :filter="filter" />
    </div>
    <div
      v-if="!loading"
      style="min-height: 92vh; max-height: 92vh; overflow: auto;"
      class="expande-horizontal vitrify-content column centaliza"
    >
      <v-list dense nav>
        <v-list-item>
          <v-avatar class="mr-2" :size="31" :color="$theme.third">
            <v-icon color="#f7f7f7">mdi-information-outline</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte" style="font-size: 12pt;font-weight: 500;"
                >Consultas para agora</span
              >
            </v-list-item-title>
            <span class="fonte grey--text">
              Veja abaixo as consultas que estão agendadas para hoje e estão
              dentro do horário agendado.
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <h4 class="fonte px-2">Consultas em atendimento/aguardando</h4> -->
      <div class="expande-horizontal pa-2 wrap">
        <template v-for="(sale, index) in consultsForNow">
          <v-flex class="px-1" xs12 md3 :key="`searched--${sale._id}`">
            <SaleItem :kanban="sale" />
          </v-flex>
        </template>
        <div
          v-if="consultsForNow.length === 0"
          class="expande-horizontal centraliza column"
          style="min-height: 40vh; background: #f7f7f7; border-radius: 6px;"
        >
          <v-icon size="60" color="grey">
            mdi-magnify
          </v-icon>
          <span class="text-center mt-3 fonte grey--text">
            Nenhuma consulta para agora
          </span>
        </div>
      </div>
      <h4 v-if="false" class="fonte px-2">Consultas de Hoje</h4>
      <div v-if="false" class="expande-horizontal pa-2 wrap">
        <template v-for="(sale, index) in consultsForToday">
          <v-flex class="px-1" xs12 md3 :key="`searched---${sale._id}`">
            <SaleItem :kanban="sale" />
          </v-flex>
        </template>
        <div
          v-if="consultsForToday.length === 0"
          class="expande-horizontal centraliza column"
          style="min-height: 40vh; background: #f7f7f7; border-radius: 6px;"
        >
          <v-icon size="60" color="grey">
            mdi-magnify
          </v-icon>
          <span class="text-center mt-3 fonte grey--text">
            Nenhuma consulta para hoje
          </span>
        </div>
      </div>
      <v-list dense nav>
        <v-list-item>
          <v-avatar class="mr-2" :size="31" color="orange">
            <v-icon color="#f7f7f7">mdi-information-outline</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="fonte" style="font-size: 12pt;font-weight: 500;"
                >Todas as Consultas</span
              >
            </v-list-item-title>
            <span class="fonte grey--text">
              Veja abaixo as consultas as quais deu atendimento.
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="expande-horizontal pa-2 wrap">
        <template v-for="(sale, index) in filteredSales">
          <v-flex class="px-1" xs12 md3 :key="`searched-${sale._id}`">
            <SaleItem :kanban="sale" />
          </v-flex>
        </template>
        <div
          v-if="filteredSales.length === 0"
          class="expande-horizontal centraliza column"
          style="min-height: 40vh; background: #f7f7f7; border-radius: 6px;"
        >
          <v-icon size="60" color="grey">
            mdi-magnify
          </v-icon>
          <span class="text-center mt-3 fonte grey--text">
            Nenhuma consulta por aqui
          </span>
        </div>
      </div>
      <!-- <div
        v-if="!filter.search && $vuetify.breakpoint.smAndDown"
        class="expande-horizontal fonte wrap"
      > -->
      <div v-if="false" class="expande-horizontal fonte wrap">
        <v-slide-group multiple :show-arrows="false">
          <v-slide-item v-for="kanban in kanbans" :key="kanban._id">
            <v-btn
              :input-value="active"
              :color="colorGroup(kanban)"
              rounded
              class="white--text mx-2 mb-1"
              small
              :class="{
                'font-weight-bold': selectedKanban._id === kanban._id
              }"
              @click="selectSalesByKanban(kanban)"
            >
              {{ kanban.kanbans.length }} {{ kanban.name }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        <div
          style="max-height: 88vh; overflow: auto;"
          v-if="selectedKanban.kanbans"
          class="expande-horizontal wrap"
        >
          <v-flex
            class="px-5 pt-2"
            v-for="(sale, index) in selectedKanban.kanbans"
            xs12
            md3
            :key="`searched-${sale._id}`"
          >
            <SaleItem :kanban="sale" />
          </v-flex>
        </div>
        <div class="pa-3 expande-horizontal  centraliza" v-else>
          <span class="text-center">
            Nenhum pedido encontrado
          </span>
        </div>
      </div>
      <!-- <div
        v-else
        class="horizontal-scroll"
        @mousedown="startDrag"
        @touchstart="startDrag"
        @mousemove="drag"
        @touchmove="drag"
        @mouseup="endDrag"
        @touchend="endDrag"
        ref="scrollhorizontal"
      > -->
      <!-- <div
        v-if="!filter.search && $vuetify.breakpoint.lgAndUp"
        class="horizontal-scroll"
        ref="scrollhorizontal"
      > -->
      <div v-if="false" class="horizontal-scroll" ref="scrollhorizontal">
        <div class="item-group-kanban-container">
          <button
            icon
            @click="scrollLeft"
            class="scroll-button left"
            ref="leftButton"
          >
            <v-icon color="white">mdi-chevron-left</v-icon>
          </button>
          <draggable
            ref="content"
            ghost-class="kanban-ghost-"
            handle=".kanban-selector"
            class="mr-1 expande-horizontal"
            style="justify-content: flex-start; align-items: flex-start;"
            @change="changeKanbanFather"
            @touchmove="handleScroll"
            v-bind="dragOptions"
            @start="dragActive = true"
            @end="dragActive = false"
            v-model="kanbanComputed"
            :group="{ name: 'kanban' }"
          >
            <div
              class="mr-3"
              style="width: 220px;"
              v-for="kanban in kanbans"
              :key="kanban._id"
            >
              <KanbanColumn
                :group="kanban"
                :list="kanban.kanbans"
                :value="kanban.kanbans"
                :deleteKanban="deleteKanban"
                :itemKanbanChange="itemKanbanChange"
              />
            </div>
            <div id="new_kanbar" class="item-group-kanban ml-0 vitrify-content">
              <v-list v-if="!new_kanban_form" color="transparent" dense>
                <v-list-item @click="openNewKanBanForm">
                  <v-icon color="white" size="18" class="ml-1 mr-3"
                    >mdi-plus</v-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title class="fonte white--text"
                      >Novo</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-form v-if="new_kanban_form" ref="formNewKanban">
                <v-flex xs12>
                  <div class="expande-horizontal column pa-3">
                    <v-text-field
                      v-model="new_kanban.name"
                      placeholder="Informe o nome"
                      dense
                      solo
                      autofocus
                      :rules="[v => !!v || 'Campo obrigatório']"
                    ></v-text-field>
                    <div class="expande-horizontal centraliza">
                      <v-btn
                        @click="new_kanban_form = false"
                        text
                        small
                        class="fonte mr-3 fonteMini"
                        dark
                        >Cancelar</v-btn
                      >
                      <v-btn
                        @click="createKanban"
                        outlined
                        class="fonte font-weight-bold"
                        dark
                        >Criar</v-btn
                      >
                    </div>
                  </div>
                </v-flex>
              </v-form>
            </div>
          </draggable>
          <button
            @click="scrollRight"
            class="scroll-button right"
            ref="rightButton"
          >
            <v-icon color="white">mdi-chevron-right</v-icon>
          </button>
        </div>
      </div>
    </div>
    <ViewSale :receipt="receipt" :payment_sys_config="payment_sys_config" />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewSale from "../components/ViewSale";
import KanbanColumn from "../components/SalesList";
import SaleItem from "../components/SaleItem";
import Filters from "../components/Filters";
import { EventBus } from "@/main.js";
import draggable from "vuedraggable";
export default {
  data() {
    return {
      options: [
        {
          icon: "mdi-sync",
          action: () => this.listar_gestordepedidos()
        }
      ],
      loading: false,
      dragActive: false,
      dragOptions: {
        animation: 100,
        disabled: false,
        ghostClass: "kanban-ghost"
      },
      checking: false,
      showButtons: false,
      isDragging: false,
      startDragX: 0,
      startScrollLeft: 0,
      payment_sys_config: {},
      receipt: {
        show_my_address: { active: true },
        show_my_logo: { active: true },
        show_my_qrcode: { active: true },
        show_client_details: { active: true },
        show_header_text: { active: true, text: "" },
        show_footer_text: { active: true, text: "" }
      },
      new_kanban_form: false,
      new_kanban: {
        name: "",
        items: []
      },
      filter: {
        search: ""
      },
      kanbans: [],
      rotas: [
        {
          name: "Início",
          path: "/medico"
        },
        {
          name: "Consultas",
          path: "/consultas-medico"
        }
      ],
      logoBase64: "",
      selectedKanban: {
        name: "",
        kanbans: []
      },
      consults: []
    };
  },
  components: {
    ViewSale,
    Filters,
    SaleItem,
    draggable,
    KanbanColumn
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_gestordepedidos"]),
    consultsForNow() {
      return this.consults.filter(consult => {
        if (consult.compra.status !== "agendada") return false;
        const now = this.$moment().format();
        const startService = this.$moment(
          consult.compra.disponibility.visit_date +
            consult.compra.disponibility.visit_hour_start,
          "DD/MM/YYYYHH:mm"
        ).format();
        const endService = this.$moment(
          consult.compra.disponibility.visit_date +
            consult.compra.disponibility.visit_hour_end,
          "DD/MM/YYYYHH:mm"
        ).format();
        const inConsult = this.$moment(now).isBetween(startService, endService);
        return inConsult;
      });
    },
    consultsForToday() {
      const consults = this.consults.filter(consult => {
        try {
          if (
            !consult.compra.status !== "agendada" &&
            consult.compra.disponibility
          )
            return false;
          const today = this.$moment().format("DD/MM/YYYY");
          console.log("today", consult.compra.disponibility);
          const consultDate = this.$moment(
            consult.compra.disponibility.visit_date,
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY");
          return today === consultDate;
        } catch (e) {
          console.log("eee", e);
        }
      });
      console.log("consults", consults);
      return consults;
    },
    consultsForTomorrow() {
      return this.consults.filter(consult => {
        if (!consult.compra.status !== "agendada") return false;
        const tomorrow = this.$moment()
          .add(1, "days")
          .format("DD/MM/YYYY");
        const consultDate = consult.compra.disponibility.visit_date;
        return tomorrow == consultDate;
      });
    },
    filteredSales() {
      let filteredSales = this.consults;
      if (this.filter.search) {
        const searchObjects = (arr, searchValue) => {
          return arr.filter(obj => {
            if (obj.compra.cliente) {
              return Object.keys(obj.compra.cliente).some(key => {
                const value = obj.compra.cliente[key];
                if (
                  typeof value === "string" &&
                  value.toLowerCase().includes(searchValue.toLowerCase())
                ) {
                  return true;
                } else if (obj.id_transacao == searchValue) {
                  return true;
                } else {
                  return false;
                }
              });
            } else {
              return false;
            }
          });
        };
        const res = searchObjects(filteredSales, this.filter.search);
        return res;
      } else {
        return filteredSales;
      }
    },
    kanbanComputed: {
      get() {
        const orderByIndex = (arr, key) => {
          return arr.sort((a, b) => {
            return a[key] - b[key];
          });
        };
        const kanbans = orderByIndex(this.kanbans, "index");
        return kanbans;
      },
      set(val) {
        console.log("setted", val);
        const orderByIndex = (arr, key) => {
          return arr.sort((a, b) => {
            return a[key] - b[key];
          });
        };
        const kanbans = orderByIndex(val, "index");
        return kanbans;
      }
    }
  },
  methods: {
    ...mapActions([
      "listar_gestordepedidos",
      "listar_funcionarios",
      "listar_clientes",
      "abre_modal_view_gestordepedido"
    ]),
    colorGroup(group) {
      if (group.color) {
        return group.color.hex;
      } else {
        return "#333";
      }
    },
    selectSalesByKanban(kanban) {
      //trabalha apenas para o mobile 28/10 23:18
      this.selectedKanban = kanban || {
        kanbans: [],
        name: ""
      };
    },
    orderByKey(arr, key) {
      return arr.sort((a, b) => {
        return a[key] - b[key];
      });
    },
    // updateGroupsDinamic(groupsToUpdate) {
    //   if (groupsToUpdate) {
    //     groupsToUpdate.map(group => {
    //       EventBus.$emit(group.groupName, group.data);
    //     });
    //   }
    // },
    async itemKanbanChange_old(event, sale) {
      const newStatus = event.to.__vue__.$attrs["group-name"];
      const oldStatus = event.from.__vue__.$attrs["group-name"];
      const group = document.getElementsByClassName(`group-sale-${newStatus}`);
      const old_group = document.getElementsByClassName(
        `group-sale-${oldStatus}`
      );
      const getElementsFromGroup = group => {
        let newElementsOrder = [];
        for (var i = 0; i < group.length; i++) {
          var elemento = group[i];
          for (var y = 0; y < elemento.childNodes.length; y++) {
            const child = elemento.childNodes[y];
            const id = child.getAttribute("data-id");
            newElementsOrder.push(id);
          }
        }
        return newElementsOrder;
      };

      const getElement = group => {
        let res = {
          element: null,
          index: null
        };
        for (var i = 0; i < group.length; i++) {
          var elemento = group[i];
          if (elemento.childNodes.length === 2) {
            res.element = elemento;
            res.dataIndex = parseInt(i);
          }
        }
        return res;
      };
      const getNewIndex = groupRes => {
        let res = {
          newIndex: null,
          oldIndex: null,
          newGroup: newStatus,
          oldGroup: oldStatus
        };
        const groupFinded = groupRes.element;

        if (groupFinded && groupFinded.childNodes) {
          for (var i = 0; i < groupFinded.childNodes.length; i++) {
            var elemento = groupFinded.childNodes[i];
            if (elemento.classList.contains(`sale-id-${sale._id}`)) {
              res.oldIndex = parseInt(elemento.getAttribute("data-index"));
              if (i === 0) {
                res.newIndex = groupRes.dataIndex;
              } else if (i === 1) {
                res.newIndex = groupRes.dataIndex + 1;
              }
            }
          }
        } else {
          res.newIndex = 0;
        }
        return res;
      };

      const resIndex = getNewIndex(getElement(group), sale);

      const isSameGroup = newStatus === oldStatus;

      const updateOldGroup = async (group, sale) => {
        let newItens = group.kanbans.filter(item => item._id !== sale._id);
        const orderByIndex = (arr, key) => {
          return arr.sort((a, b) => {
            return a[key] - b[key];
          });
        };
        let normalizedItems = orderByIndex(newItens, "kanban_order");
        let itensToUpdate = normalizedItems.map((item, index) => {
          return {
            updateMany: {
              filter: { _id: item._id },
              update: {
                $set: {
                  kanban_order: index,
                  status_do_pedido: item.status_do_pedido
                }
              }
            }
          };
        });
        return itensToUpdate;
      };

      const updateNewGroup = async (group, sale) => {
        let localSaleToBeReplaced = group.kanbans.filter(
          item => item.kanban_order === resIndex.newIndex
        )[0];

        let newItens = group.kanbans.filter(item => item._id !== sale._id);
        let itensToUpdate = [];
        const orderByIndex = (arr, key) => {
          return arr.sort((a, b) => {
            return a[key] - b[key];
          });
        };

        let normalizedItems = orderByIndex(newItens, "kanban_order");
        let fired = false;
        normalizedItems.map((item, index) => {
          if (index === resIndex.newIndex && !fired) {
            itensToUpdate.push({
              updateMany: {
                filter: { _id: sale._id },
                update: {
                  $set: {
                    kanban_order: index,
                    status_do_pedido: item.status_do_pedido
                  }
                }
              }
            });
            itensToUpdate.push({
              updateMany: {
                filter: { _id: localSaleToBeReplaced._id },
                update: {
                  $set: {
                    kanban_order: index + 1,
                    status_do_pedido: item.status_do_pedido
                  }
                }
              }
            });
            fired = true;
          } else if (fired) {
            itensToUpdate.push({
              updateMany: {
                filter: { _id: item._id },
                update: {
                  $set: {
                    kanban_order: index + 1,
                    status_do_pedido: item.status_do_pedido
                  }
                }
              }
            });
          } else {
            itensToUpdate.push({
              updateMany: {
                filter: { _id: item._id },
                update: {
                  $set: {
                    kanban_order: index,
                    status_do_pedido: item.status_do_pedido
                  }
                }
              }
            });
          }
        });
        return itensToUpdate;
      };

      const updateSameGroup = async group => {
        const groupName = newStatus;
        const newOrderElements = getElementsFromGroup(group);
        const res = newOrderElements.map((id, index) => {
          return {
            updateMany: {
              filter: { _id: id },
              update: {
                $set: {
                  kanban_order: index,
                  status_do_pedido: groupName
                }
              }
            }
          };
        });
        return {
          itemsToBeReplacelocal: [], //verificar se é necessário
          itemsToBeUpdated: res
        };
      };

      const updateLocalItems = async itemsToUpdate => {
        console.log("iii", itemsToUpdate);
        const localItems = JSON.parse(localStorage.gestordepedidos)
          ? JSON.parse(localStorage.gestordepedidos).docs
          : [];
        const newLocalItems = await localItems.map(item => {
          const itemToUpdate = itemsToUpdate.filter(
            i => i.updateMany.filter._id == item._id
          )[0];
          if (itemToUpdate) {
            return {
              ...item,
              kanban_order: itemToUpdate.updateMany.update.$set.kanban_order,
              status_do_pedido:
                itemToUpdate.updateMany.update.$set.status_do_pedido
            };
          } else {
            return item;
          }
        });
        localStorage.setItem(
          "gestordepedidos",
          JSON.stringify({ docs: newLocalItems })
        );
        return newLocalItems;
      };

      const oldGroup = this.kanbans.filter(
        group => group.name === resIndex.oldGroup
      )[0];
      const newGroup = this.kanbans.filter(
        group => group.name === resIndex.newGroup
      )[0];

      let itensToUpdate = [];
      let newItens = [];

      if (isSameGroup) {
        itensToUpdate = await updateSameGroup(group);
        newItens = await updateLocalItems(itensToUpdate.itemsToBeUpdated);
      } else {
        itensToUpdate = [
          ...(await updateOldGroup(oldGroup, sale)),
          ...(await updateNewGroup(newGroup, sale))
        ];
        newItens = await updateLocalItems(itensToUpdate);
      }
      this.loading = true;
      this.$forceUpdate();
      this.kanbans = this.processKanbanSales(newItens);
      this.loading = false;
      const payload = {
        ...sale,
        itensToUpdate: isSameGroup
          ? itensToUpdate.itemsToBeUpdated
          : itensToUpdate,
        updateIndexData: resIndex
      };
      this.$forceUpdate();
      this.$run("transacoes/update-status-compra", payload).then(() => {
        // this.listar_gestordepedidos();
        this.$forceUpdate();
        this.$forceUpdate();
      });
    },
    async getElementsFromGroup(group) {
      let newElementsOrder = [];
      for (var i = 0; i < group.length; i++) {
        var elemento = group[i];
        for (var y = 0; y < elemento.childNodes.length; y++) {
          const child = elemento.childNodes[y];
          const id = child.getAttribute("data-id");
          newElementsOrder.push(id);
        }
      }
      return newElementsOrder;
    },
    async processGroupItems(group, group_name) {
      const newOrderElements = await this.getElementsFromGroup(group);
      return newOrderElements.map((id, index) => {
        return {
          updateMany: {
            filter: { _id: id },
            update: {
              $set: {
                kanban_order: index,
                status_do_pedido: group_name
              }
            }
          }
        };
      });
    },
    async updateLocalItems(itemsToUpdate) {
      const localItems = JSON.parse(localStorage.gestordepedidos)
        ? JSON.parse(localStorage.gestordepedidos).docs
        : [];
      const newLocalItems = await localItems.map(item => {
        const itemToUpdate = itemsToUpdate.filter(
          i => i.updateMany.filter._id == item._id
        )[0];
        if (itemToUpdate) {
          return {
            ...item,
            kanban_order: itemToUpdate.updateMany.update.$set.kanban_order,
            status_do_pedido:
              itemToUpdate.updateMany.update.$set.status_do_pedido
          };
        } else {
          return item;
        }
      });
      localStorage.setItem(
        "gestordepedidos",
        JSON.stringify({ docs: newLocalItems })
      );
      return newLocalItems;
    },
    async itemKanbanChange(event, sale) {
      const new_group_name = event.to.__vue__.$attrs["group-name"];
      const old_group_name = event.from.__vue__.$attrs["group-name"];
      const new_group_items = document.getElementsByClassName(
        `group-sale-${new_group_name}`
      );
      const old_group_items = document.getElementsByClassName(
        `group-sale-${old_group_name}`
      );
      const isSameGroup = new_group_name === old_group_name;

      let itensToUpdate = [];
      let newItens = [];

      if (isSameGroup) {
        itensToUpdate = await this.processGroupItems(
          new_group_items,
          new_group_name
        );
      } else {
        itensToUpdate = [
          ...(await this.processGroupItems(new_group_items, new_group_name)),
          ...(await this.processGroupItems(old_group_items, old_group_name))
        ];
      }

      newItens = await this.updateLocalItems(itensToUpdate);
      this.loading = true;
      this.kanbans = this.processKanbanSales(newItens);
      this.loading = false;
      const payload = {
        _id: sale._id,
        itensToUpdate: itensToUpdate,
        new_group_name: new_group_name,
        old_group_name: old_group_name,
        same_group: isSameGroup
      };
      this.$forceUpdate();
      this.$run("transacoes/update-status-compra", payload).then(() => {
        // this.listar_gestordepedidos();
        this.$forceUpdate();
      });
    },
    openNewKanBanForm() {
      this.new_kanban_form = true;
    },
    getConfigPaymentSys() {
      if (localStorage.payment_sys_config) {
        this.payment_sys_config = JSON.parse(
          localStorage.getItem("payment_sys_config")
        );
      } else {
        this.$store.dispatch("enableLoading");
      }
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          localStorage.setItem(
            "payment_sys_config",
            JSON.stringify(res.data.payment_sys_config)
          );
          this.$store.dispatch("disableLoading");
          this.payment_sys_config = res.data.payment_sys_config;
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      if (localStorage.receipt) {
        this.receipt = JSON.parse(localStorage.getItem("receipt"));
      } else {
        this.$store.dispatch("enableLoading");
      }
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          localStorage.setItem("receipt", JSON.stringify(res.data.receipt));
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    },
    createKanban() {
      this.$store.dispatch("enableLoading");
      this.$run("kanban/create", {
        ...this.new_kanban,
        index: this.kanbans.length
      })
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.$store.dispatch("createGlobalMessage", {
            message: "Kanban criado com sucesso",
            type: "success"
          });
          this.new_kanban = {};
          this.new_kanban_form = false;
          this.listKanban();
        })
        .catch(e => {});
    },
    deleteKanban(kanban) {
      this.$store.dispatch("enableLoading");
      this.$run("kanban/delete", kanban)
        .then(() => {
          this.$store.dispatch("disableLoading");
          this.$store.dispatch("createGlobalMessage", {
            message: "Kanban apagado com sucesso",
            type: "success"
          });
          const OldKanbans = JSON.parse(localStorage.kanbans);
          const newKanban = OldKanbans.filter(
            kanban => kanban._id !== kanban._id
          );
          this.kanbans = newKanban;
          localStorage.setItem("kanbans", JSON.stringify(newKanban));
          this.new_kanban = {};
        })
        .catch(e => {});
    },
    salesByKanban(kanban) {
      return this.get_gestordepedidos.docs.filter(
        sale =>
          sale.status_do_pedido.toLowerCase() === kanban.name.toLowerCase()
      );
    },
    processKanbanSales() {
      const kanbans = JSON.parse(localStorage.kanbans);
      const sales = this.get_gestordepedidos.docs;
      const kanbanSales = kanbans.map(kanban => {
        const salesByKanban = sales.filter(
          sale =>
            sale.status_do_pedido.toLowerCase() === kanban.name.toLowerCase()
        );
        return {
          ...kanban,
          kanbans: this.orderByKey(salesByKanban, "kanban_order")
        };
      });
      return kanbanSales;
    },
    listKanban() {
      if (localStorage.kanbans) {
        this.kanbans = this.processKanbanSales(
          JSON.parse(localStorage.getItem("kanbans"))
        );
      } else {
        this.$store.dispatch("enableLoading");
      }
      this.$run("kanban/list")
        .then(res => {
          localStorage.setItem("kanbans", JSON.stringify(res.data.docs));
          this.$store.dispatch("disableLoading");
          this.kanbans = this.processKanbanSales(res.data.docs);
          this.endDrag();
        })
        .catch(e => {});
    },
    startDrag(event) {
      this.mouseIsDown = true;
      if (this.dragActive) return;
      const content = document.getElementsByClassName(
        "item-group-kanban-container"
      )[0];
      this.isDragging = true;
      this.startDragX = event.clientX || event.touches[0].clientX;
      this.startScrollLeft = content.scrollLeft;
    },
    drag(event) {
      // if (this.dragActive || !this.ctrlPressed || !this.mouseIsDown) return;
      // if (this.dragActive || !this.mouseIsDown) return;
      // const content = document.getElementsByClassName(
      //   "item-group-kanban-container"
      // )[0];
      // if (this.isDragging) {
      //   const scrollAmount =
      //     this.startScrollLeft +
      //     (this.startDragX - (event.clientX || event.touches[0].clientX));
      //   content.scrollLeft = scrollAmount;
      // }
    },
    endDrag() {
      this.mouseIsDown = false;
      if (this.dragActive) return;
      setTimeout(() => {
        this.isDragging = false;
        this.checking = false;
      }, 2000);
    },
    handleScroll() {
      // if (!this.checking) {
      //   this.scrolled();
      // }
    },
    checkShowButtons() {
      const contentWidth = this.$refs.content.scrollWidth;
      const containerWidth = this.$refs.content.clientWidth;
      const slots = this.$slots.default ? this.$slots.default.length : false;
      const showButtons =
        contentWidth > containerWidth && slots > this.showButtonsAt;
      if (this.showButtons !== showButtons) {
        this.showButtons = showButtons;
      }
    },
    changeKanbanFather(value) {
      const payload = value.moved;
      const orderByIndex = (arr, key) => {
        return arr.sort((a, b) => {
          return a[key] - b[key];
        });
      };
      const OldKanbans = orderByIndex(
        JSON.parse(localStorage.kanbans),
        "index"
      );
      let newKanban = [...OldKanbans];

      newKanban[payload.newIndex] = {
        ...OldKanbans[payload.oldIndex],
        index: payload.newIndex
      };
      newKanban[payload.oldIndex] = {
        ...OldKanbans[payload.newIndex],
        index: payload.oldIndex
      };
      localStorage.setItem("kanbans", JSON.stringify(newKanban));
      this.$run("kanban/update-index", {
        kanbansToUpdate: [
          {
            _id: payload.element._id,
            index: payload.newIndex
          },
          {
            _id: OldKanbans[payload.newIndex]._id,
            index: payload.oldIndex
          }
        ]
      }).then(res => {
        this.listKanban();
      });
    },
    scrollLeft() {
      const element = document.getElementsByClassName(
        "item-group-kanban-container"
      )[0];
      element.scrollBy({ left: -140, behavior: "smooth" });
    },
    scrollRight() {
      const element = document.getElementsByClassName(
        "item-group-kanban-container"
      )[0];
      element.scrollBy({ left: 140, behavior: "smooth" });
    }
  },
  mounted() {
    EventBus.$on("updateKanban", () => {
      this.listKanban();
    });
  },
  created() {
    // this.listar_clientes();
    // this.listar_gestordepedidos();
    // this.getConfigPaymentSys();
    // this.getConfigReceipt();
    // this.listar_funcionarios();
    // this.listKanban();
    this.$run("transacoes/list-my-consults", {
      limit: 1000,
      skip: 0,
      sort: {
        createdAt: -1
      }
    }).then(res => {
      this.loading = false;
      this.consults = res.data;
    });
    if (this.$route.query.t_id) {
      this.$run("transacoes/view-transaction", {
        _id: this.$route.query.t_id
      }).then(res => {
        this.abre_modal_view_gestordepedido({
          ...res.data,
          call_client: true,
          call_doctor: false
        });
        this.$router.push("/consultas-medico");
      });
    }
  }
};
</script>

<style>
.kanban-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  min-width: 100%;
  overflow: hidden;
  right: 0;
  z-index: 1;
}
.item-group-kanban {
  min-width: 220px;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  position: relative;
  /* background: #b83d3d; */
  border-radius: 10px !important;
  padding-right: 6px;
}
.item-group-kanban-container {
  padding-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  right: 0;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  touch-action: pan-y;
  min-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  right: 0;
  z-index: 0;
  padding-bottom: 10px;
}
/* .sortable-chosen {
  background: #f2f2f2;
  background-color: #f2f2f2 !important;
  opacity: 1;
} */
.max-height-item-kanban {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.max-height-item-kanban::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.max-height-item-kanban::-webkit-scrollbar-track {
  background: rgb(235, 233, 233); /* color of the tracking area */
  border-radius: 6px;
}

.max-height-item-kanban::-webkit-scrollbar-thumb {
  background-color: #b3b3b4; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.dragging {
  z-index: 9999;
  position: absolute;
  border-radius: 10px;
  width: 280px;
  rotate: 0deg;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dropzone {
  transition: background-color 0.3s;
}

.drop-active {
  background-color: #ffaf15;
}

.drop-target {
  background-color: #1173ff;
}

.drag-drop {
  background-color: #f00;
}

.drag-drop.can-drop {
  color: #000;
  background-color: #4e4;
}

.horizontal-scroll {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  padding-left: 17px;
}

.content-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  touch-action: pan-y;
  min-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  right: 0;
  z-index: 0;
  padding-bottom: 10px;
}

.content-scroll::-webkit-scrollbar {
  width: 1px; /* width of the entire scrollbar */
  height: 6px; /* width of the entire scrollbar */
}

.content-scroll::-webkit-scrollbar-track {
  background: rgba(59, 59, 59, 0); /* color of the tracking area */
  border-radius: 6px;
}

.content-scroll::-webkit-scrollbar-thumb {
  background-color: #999797; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.item-group-kanban-container::-webkit-scrollbar {
  width: 1px; /* width of the entire scrollbar */
  height: 14px; /* width of the entire scrollbar */
}

.item-group-kanban-container::-webkit-scrollbar-track {
  background: rgba(75, 74, 74, 0); /* color of the tracking area */
  border-radius: 3px;
}

.item-group-kanban-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* color of the scroll thumb */
  border-radius: 6px; /* roundness of the scroll thumb */
}

.scroll-button {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  font-size: 1rem;
  z-index: 100;
  border: 1px solid #acabab;
  background: rgba(15, 15, 15, 0.5);
}

.left {
  left: 0;
  max-height: 50px;
  min-height: 50px;
  max-width: 50px;
  min-width: 50px;
  border-radius: 50%;
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  margin-top: 260px;
}

.right {
  right: 0;
  max-height: 50px;
  min-height: 50px;
  max-width: 50px;
  min-width: 50px;
  border-radius: 50%;
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.3);
  margin-top: 260px;
}
</style>
