<template>
  <div
    :style="
      `${
        $vuetify.breakpoint.smAndUp ? 'max-height: 72vh;' : 'max-height: 95vh'
      }; overflow: auto;`
    "
    class="expande-horizontal windows-style-content"
  >
    <v-flex xs12>
      <div class="px-1 expande-horizontal wrap">
        <!-- <v-flex xs12>
          <div class="expande-horizontal">
            <v-spacer></v-spacer>
            <v-chip
              @click="configureDeliveryNote"
              small
              class="mr-1"
              >Nota de entrega
            </v-chip>
            <v-chip
              @click="openReceiptEmitter"
              small
              class="mr-1"
              >Recibo
            </v-chip>
            <v-chip
              @click="sendToPdv(get_gestordepedido._id)"
              small
              class="mr-1"
              >pdv
            </v-chip>
            <v-chip
              v-if="!get_gestordepedido.external_movimentation"
              @click="prepareDelivery(get_gestordepedido)"
              small
              class="mr-1"
              >Agendamento
            </v-chip>
            <v-chip
              @click="initPayment"
              small
              class="mr-1"
              v-if="get_gestordepedido.status_do_pagamento === 'pendente'"
              >pagamento
            </v-chip>
          </div>
        </v-flex> -->

        <!-- <TagPlugin
          :dataOrigin="get_gestordepedido.compra.tags || []"
          :dataSale="get_gestordepedido"
        /> -->
        <v-flex xs12>
          <div class="expande-horizontal centraliza column">
            <h1 v-if="showTimeRemaining">
              {{ timeRemainingAtExpire }}
            </h1>
            <h1 v-if="showTimeAtConsult">
              {{ timeRemainingAtConsult }}
            </h1>
            <h1 class="red--text" v-if="consultIsExpired()">
              00:00:00
            </h1>
            <span
              v-if="showTimeRemaining"
              class="fonte  grey--text fonteMini text-center"
              >Tempo restante da consulta</span
            >
            <span
              v-if="showTimeAtConsult"
              class="fonte  grey--text fonteMini text-center"
              >A consulta começa em</span
            >
            <span
              v-if="consultIsExpired()"
              class="fonte grey--text  fonteMini text-center"
              >Esta consulta acabou</span
            >
          </div>
        </v-flex>
        <v-flex class="mt-3" xs12>
          <v-divider></v-divider>
        </v-flex>
      </div>
      <h4 class="fonte mx-3 font-weight-light mt-2">Dados da consulta</h4>
      <!-- <v-btn v-if="!getLoggedUser.is_patient && !get_gestordepedido.compra.allow_forwarding && !get_gestordepedido.compra.allow_return" dark color="blue darken-3" class="ma-1" @click="$refs.solicitarEncaminhamento.open()" rounded small depressed>Solicitar Encaminhamento</v-btn>
      <v-btn v-if="get_gestordepedido.compra.allow_forwarding" class="ma-1" rounded small depressed>Paciente Encaminhado ({{ get_gestordepedido.compra.forwarding_specialty.nome }}) <v-icon class="ml-1" size="21" color="green">mdi-check</v-icon></v-btn> -->
      <v-btn
        v-if="
          !getLoggedUser.is_patient &&
            !get_gestordepedido.compra.allow_return &&
            !get_gestordepedido.compra.allow_forwarding
        "
        dark
        color="blue darken-3"
        class="ma-1"
        rounded
        small
        depressed
        @click="$refs.solicitarRetorno.open()"
        >Disponibilizar Retorno</v-btn
      >
      <v-btn
        v-if="
          get_gestordepedido.compra.allow_return &&
            !get_gestordepedido.compra.allow_forwarding
        "
        class="ma-1"
        rounded
        small
        depressed
        >Retorno liberado
        <v-icon size="21" class="ml-1" color="green">mdi-check</v-icon></v-btn
      >
      <div
        class="pa-2 pt-1 expande-horizontal column"
        v-if="
          get_gestordepedido.compra.status &&
            (get_gestordepedido.compra.status === 'agendada' ||
              get_gestordepedido.compra.status === 'attending') &&
            get_gestordepedido.compra.disponibility
        "
      >
        <div class="expande-horizontal">
          <v-icon class="mr-2" :color="$theme.primary">
            mdi-phone-in-talk-outline
          </v-icon>
          <span class="fonte fonteMini"
            >A consulta está agendada para o dia
            {{ get_gestordepedido.compra.disponibility.visit_date }}, no horário
            de {{ get_gestordepedido.compra.disponibility.visit_hour_start }} às
            {{ get_gestordepedido.compra.disponibility.visit_hour_end }}</span
          >
        </div>
      </div>
      <v-divider></v-divider>
      <v-btn
        v-if="!getLoggedUser.is_patient"
        @click="openMevo"
        large
        block
        :color="$theme.primary"
        >Abrir Receituário</v-btn
      >
      <v-list color="transparent" dense nav>
        <v-list-item
          v-if="
            get_gestordepedido.compra.cliente &&
              get_gestordepedido.compra.cliente.nome
          "
        >
          <v-list-item-content>
            <v-list-item-title class="fonte">Paciente</v-list-item-title>
            <v-list-item-subtitle class="fonte fonteMini">
              {{
                get_gestordepedido.compra.cliente
                  ? get_gestordepedido.compra.cliente.nome
                  : "Não informado"
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="
                get_gestordepedido.compra && get_gestordepedido.compra.cliente
              "
              >CPF:
              {{ get_gestordepedido.compra.cliente.cpf }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-if="get_gestordepedido.doctor && get_gestordepedido.doctor.nome"
        >
          <v-list-item-content>
            <v-list-item-title class="fonte">Médico</v-list-item-title>
            <v-list-item-subtitle class="fonte fonteMini">
              {{
                get_gestordepedido.doctor.nome
                  ? get_gestordepedido.doctor.nome
                  : "Não informado"
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-if="
            get_gestordepedido.compra.anexo_encaminhamento &&
              get_gestordepedido.compra.anexo_encaminhamento.length > 0
          "
        >
          <v-list-item-content>
            <v-list-item-title class="fonte"
              >Anexo do encaminhamento</v-list-item-title
            >
            <v-list-item-subtitle>
              <v-chip
                style="border: 1px solid #d0cfcf;display: flex; align-items: center; justify-content: center; height: 30px;"
                color="#e5e5e5"
                small
                class="ma-1"
                v-for="(file, index) in get_gestordepedido.compra
                  .anexo_encaminhamento"
                :key="`${file.name}-${index}`"
              >
                <span
                  class="mr-1 font-weight-bold fonteMini text-uppercase signed-gradient"
                  >{{ file.format }}</span
                >
                <a
                  class="text-lowercase"
                  style="color: #555; text-decoration: none; cursor: pointer;"
                  target="_blank"
                  :href="file.link"
                >
                  {{ file.name }}
                </a>
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-if="
            get_gestordepedido.compra.triagem &&
              get_gestordepedido.compra.triagem.o_que_voce_esta_sentindo
          "
        >
          <v-list-item-content>
            <span class="fonte mb-2" style="font-size: 11pt; font-weight: 500;"
              >O que está sentindo</span
            >
            <div class="expande-horizontal wrap">
              <v-chip
                small
                :dark="opt.value"
                :color="opt.value ? $theme.primary : ''"
                v-for="opt in get_gestordepedido.compra.triagem
                  .o_que_voce_esta_sentindo.options"
                :key="opt.text"
                class="mr-2 mb-2"
              >
                <v-icon size="17" class="mr-2" v-if="!opt.value"
                  >mdi-checkbox-blank-circle-outline</v-icon
                >
                <v-icon size="17" class="mr-2" v-else
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                {{ opt.text }}
              </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="
            get_gestordepedido.compra.triagem &&
              get_gestordepedido.compra.triagem.o_que_voce_esta_sentindo
          "
        ></v-divider>
        <v-list-item
          v-if="
            get_gestordepedido.compra &&
              get_gestordepedido.compra.triagem.problema_de_saude
          "
        >
          <v-list-item-content>
            <span class="fonte mb-2" style="font-size: 11pt; font-weight: 500;"
              >Tem algum problema de saúde ou toma medicamento todos os
              dias?</span
            >
            <div class="expande-horizontal wrap">
              <v-chip
                :dark="
                  get_gestordepedido.compra.triagem.problema_de_saude
                    .response == 'não'
                "
                :color="
                  get_gestordepedido.compra.triagem.problema_de_saude
                    .response === 'não'
                    ? $theme.primary
                    : ''
                "
                class="mr-2 mb-2"
                small
              >
                <v-icon
                  size="17"
                  class="mr-2"
                  v-if="
                    get_gestordepedido.compra.triagem.problema_de_saude
                      .response != 'não'
                  "
                  >mdi-checkbox-blank-circle-outline</v-icon
                >
                <v-icon
                  size="17"
                  class="mr-2"
                  v-if="
                    get_gestordepedido.compra.triagem.problema_de_saude
                      .response == 'não'
                  "
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Não
              </v-chip>
              <v-chip
                :dark="
                  get_gestordepedido.compra.triagem.problema_de_saude
                    .response == 'sim'
                "
                :color="
                  get_gestordepedido.compra.triagem.problema_de_saude
                    .response === 'sim'
                    ? $theme.primary
                    : ''
                "
                class="mr-2 mb-2"
                small
              >
                <v-icon
                  size="17"
                  class="mr-2"
                  v-if="
                    get_gestordepedido.compra.triagem.problema_de_saude
                      .response != 'sim'
                  "
                  >mdi-checkbox-blank-circle-outline</v-icon
                >
                <v-icon
                  size="17"
                  class="mr-2"
                  v-if="
                    get_gestordepedido.compra.triagem.problema_de_saude
                      .response == 'sim'
                  "
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Sim
              </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="
            get_gestordepedido.compra &&
              get_gestordepedido.compra.triagem.problema_de_saude
          "
        ></v-divider>
        <v-list-item v-if="get_gestordepedido.compra.triagem.alergia">
          <v-list-item-content>
            <span class="fonte mb-2" style="font-size: 11pt; font-weight: 500;"
              >Alergias</span
            >
          </v-list-item-content>
        </v-list-item>
        <template v-if="get_gestordepedido.compra.triagem.alergia">
          <v-list-item
            v-for="opt in get_gestordepedido.compra.triagem.alergia.options"
            :key="opt.text"
          >
            <v-list-item-content>
              <span
                class="fonte mb-2"
                style="font-size: 10pt; font-weight: 500;"
                >{{ opt.text }}</span
              >
              <div class="expande-horizontal wrap">
                <v-chip
                  small
                  :dark="opt.response == 'não'"
                  :color="opt.response === 'não' ? $theme.primary : ''"
                  class="mr-1 mb-1"
                >
                  <v-icon size="17" class="mr-2" v-if="opt.response != 'não'"
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                  <v-icon size="17" class="mr-2" v-if="opt.response == 'não'"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  Não
                </v-chip>
                <v-chip
                  :dark="opt.response == 'sim'"
                  :color="opt.response === 'sim' ? $theme.primary : ''"
                  class="mr-1 mb-1"
                  small
                >
                  <v-icon size="17" class="mr-2" v-if="opt.response != 'sim'"
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                  <v-icon size="17" class="mr-2" v-if="opt.response == 'sim'"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  Sim
                </v-chip>
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider v-if="get_gestordepedido.compra.triagem.alergia"></v-divider>
      </v-list>
      <span class="fonte px-3">Prescrições</span>
      <v-list
        three-line
        color="transparent"
        dense
        nav
        v-if="
          get_gestordepedido.documents_mevo &&
            get_gestordepedido.documents_mevo.length > 0
        "
      >
        <template v-for="(prescricao, i) in get_gestordepedido.documents_mevo">
          <v-list-item :key="prescricao.payload.CodigoValidacao">
            <v-list-item-content>
              <v-list-item-title>
                Código: {{ prescricao.payload.CodigoValidacao }}
              </v-list-item-title>
              <v-list-item-title v-if="prescricao.response">
                Tipo: {{ prescricao.response.Documentos[0].Categoria }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon
                  v-if="prescricao.status == 'Prescrição iniciada'"
                  color="orange"
                  size="21"
                  >mdi-receipt-text-clock-outline</v-icon
                >
                <v-icon v-else color="green" size="21"
                  >mdi-receipt-text-check-outline</v-icon
                >
                Status: {{ prescricao.status }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Emitida em
                {{ $moment(prescricao.data).format("DD/MM/YY [às] HH:mm") }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="prescricao.response">
              <a target="_blank" :href="prescricao.response.PDFUrl">ver</a>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`divider-${i}`"></v-divider>
        </template>
      </v-list>
      <div v-else style="height: 200px;" class="expande-horizontal centraliza">
        <span class="fonte">Não há prescrições</span>
      </div>

      <h3 class="fonte mx-3">Arquivos</h3>
      <span class="mx-3 grey--text"
        >clique no ícone abaixo para anexar arquivos</span
      >

      <files
        :v-model="get_gestordepedido.files"
        :value="get_gestordepedido.files || []"
        :sale="get_gestordepedido"
        @input="updateFiles($event)"
      />
      <!-- <sale-log /> -->
    </v-flex>
    <EventDialog ref="eventDialog" origin="sale-body" />
    <SolicitarRetorno ref="solicitarRetorno" />
    <SolicitarEncaminhamento ref="solicitarEncaminhamento" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CosmosPluginStore from "@/apps/CosmosPluginStore/index.vue";
import MembersPlugin from "@/apps/CosmosPluginStore/plugins/members/index.vue";
import TagPlugin from "@/apps/CosmosPluginStore/plugins/tag/index.vue";
import InPreparationStage from "../../components/SaleStages/in_preparation/index.vue";
import ViewExternalMovimentation from "../../components/ViewExternalMovimentation/index.vue";
import PaymentLogs from "../../components/paymentLogs/index.vue";
import SaleLog from "./saleLog.vue";
import Files from "./saleFiles.vue";
import EventDialog from "@/apps/ExternalMovimentation/components/EventDialog.vue";
import SolicitarRetorno from "@/apps/27#Caixas/components/SolicitarRetorno.vue";
import SolicitarEncaminhamento from "@/apps/27#Caixas/components/SolicitarEncaminhamento.vue";
import { set } from "vue";

export default {
  props: [
    "openReceiptEmitter",
    "printReceipt",
    "configureDeliveryNote",
    "confirmCancel",
    "sendToPdv",
    "initPayment"
  ],
  components: {
    CosmosPluginStore,
    PaymentLogs,
    MembersPlugin,
    TagPlugin,
    InPreparationStage,
    SaleLog,
    EventDialog,
    ViewExternalMovimentation,
    Files,
    SolicitarRetorno,
    SolicitarEncaminhamento
  },
  computed: {
    ...mapGetters(["get_gestordepedido", "getLoggedUser"])
  },
  data() {
    return {
      kanbans: JSON.parse(localStorage.getItem("kanbans")) || [],
      timeRemainingAtConsult: "",
      timeRemainingAtExpire: "",
      showTimeAtConsult: false,
      showTimeRemainingOfConsult: false,
      fiveMinutesNotification: false,
      threeMinutesNotification: false,
      oneMinutesNotification: false,
      showTimeRemaining: false
    };
  },
  created() {
    setInterval(() => {
      this.calcTimeRemaining();
      this.calcTimingAtConsult();
    }, 1000);
  },
  methods: {
    ...mapActions([
      "fecha_modal_view_gestordepedido",
      "update_transaction_files",
      "enableLoading",
      "disableLoading"
    ]),
    calcTimeRemaining() {
      const disponibility = this.get_gestordepedido.compra.disponibility;
      if (disponibility) {
        const date = disponibility.visit_date.split("/");
        const hour = disponibility.visit_hour_end.split(":");
        const dateVisit = new Date(
          date[2],
          date[1] - 1,
          date[0],
          hour[0],
          hour[1]
        );
        const now = new Date();
        const diff = dateVisit.getTime() - now.getTime();
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        this.timeRemainingAtExpire = `${days}:${hours}:${minutes}:${seconds}`;
        if (days >= 0 && hours >= 0 && minutes >= 15) {
          this.showTimeRemaining = false;
          this.showTimeAtConsult = true;
        } else {
          this.showTimeRemaining = true;
          this.showTimeAtConsult = false;
        }

        if (days >= 0 && hours >= 0 && minutes == 5) {
          this.fiveMinutesNotification = true;
          this.createGlobalMessage({
            type: "success",
            message: "Faltam 5 minutos para a consulta acabar",
            timeout: 5000
          });
        }

        if (days >= 0 && hours >= 0 && minutes == 3) {
          this.threeMinutesNotification = true;
          this.createGlobalMessage({
            type: "warning",
            message: "Faltam 3 minutos para a consulta acabar",
            timeout: 5000
          });
        }

        if (days >= 0 && hours >= 0 && minutes == 1) {
          this.oneMinutesNotification = true;
          this.createGlobalMessage({
            type: "warning",
            message: "A Consulta será encerrada em 1 minuto",
            timeout: 10000
          });
        }

        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
          this.showTimeRemaining = false;
          this.showTimeAtConsult = false;
        }
      }
    },
    calcTimingAtConsult() {
      const disponibility = this.get_gestordepedido.compra.disponibility;
      if (disponibility) {
        const date = disponibility.visit_date.split("/");
        const hour = disponibility.visit_hour_start.split(":");
        const dateVisit = new Date(
          date[2],
          date[1] - 1,
          date[0],
          hour[0],
          hour[1]
        );
        const now = new Date();
        const diff = dateVisit.getTime() - now.getTime();
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        this.timeRemainingAtConsult = `${days}:${hours}:${minutes}:${seconds}`;
      }
    },
    consultIsExpired() {
      const disponibility = this.get_gestordepedido.compra.disponibility;
      if (disponibility) {
        const date = disponibility.visit_date.split("/");
        const hour = disponibility.visit_hour_end.split(":");
        const dateVisit = new Date(
          date[2],
          date[1] - 1,
          date[0],
          hour[0],
          hour[1]
        );
        const now = new Date();
        const diff = dateVisit.getTime() - now.getTime();
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
          return true;
          this.calcTimingAtConsult();
        } else {
          return false;
        }
      }
    },
    openMevo() {
      this.enableLoading();
      this.$run("transacoes/start-recipe", { id: this.get_gestordepedido._id })
        .then(res => {
          this.disableLoading();
          const modalURL = res.ModalURL || res.data.ModalURL;
          var janelaMEvo = window.open(modalURL, "", "width=900,height=600");
          janelaMEvo.focus();
        })
        .catch(err => {
          this.disableLoading();
          this.$store.dispatch("createGlobalMessage", {
            type: "error",
            message: "Rede instável, teste novamente",
            timeout: 5000
          });
        });
    },
    addTagOnSale(tag) {
      console.log("bateu", tag);
      // this.$store.dispatch("addTagOnSale", tag);
    },
    updateFiles(files) {
      this.update_transaction_files(files).then(res => {
        console.log(res);
        this.$forceUpdate();
      });
    },
    removeTagFromSale(tag) {
      console.log("bateuw", tag);
      // this.$store.dispatch("removeTagFromSale");
    },
    prepareDelivery(sale) {
      this.$refs.eventDialog.open(sale, "sale");
    },
    editDelivery() {
      this.$refs.eventDialog.open(
        this.get_gestordepedido.external_movimentation,
        "open-event"
      );
    }
  }
};
</script>
