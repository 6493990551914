<template>
  <div
    class="expande-horizontal column"
    style="justify-content: flex-start; min-height: 87vh;"
  >
    <div class="expande-horizontal column">
      <v-flex xs12>
        <v-list dense class="fonte">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <span class="mr-3">Saldo disponível:</span>
                <span class="font-weight-bold green--text">{{
                  $helper.formataSaldo(balance || 0)
                }}</span>
              </v-list-item-title>
              <span class="fonteMini grey--text">
                O Saldo é atualizado automaticamente a cada 1 hora.
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                rounded
                depressed
                @click="$refs.modalWithdraw.open()"
                color="green"
                dark
              >
                Solicitar saque
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-flex>
      <span class="grey--text fonte fonteMini mx-3">solicitações:</span>
      <v-flex xs12 v-if="my_withdraws.length > 0">
        <v-list style="width: 100%;" class="fonte" dense>
          <v-divider></v-divider>
          <template v-for="(withdraw, index) in my_withdraws">
            <v-list-item :key="withdraw._id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ withdraw.bank_account.bank }} -
                  {{ withdraw.bank_account.agency }} -
                  {{ withdraw.bank_account.account }}
                </v-list-item-title>
                <v-list-item-subtitle class="green--text">
                  {{ $helper.formataSaldo(withdraw.total_without_taxs) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ $moment(withdraw.created_at).format("DD/MM/YYYY") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ withdraw.current_status | status }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-flex>
      <v-flex xs12 v-else>
        <div class="expande-horizontal centraliza" style="min-height: 40vh">
          <span class="grey--text text-center"
            >Você ainda não realizou/solicitou nenhum saque.</span
          >
        </div>
      </v-flex>
      <v-spacer></v-spacer>
    </div>
    <ModalRequestWithdraw
      ref="modalWithdraw"
      @update-request="getMyWithdraws"
    />
  </div>
</template>

<script>
import ModalRequestWithdraw from "../components/ModalRequestWithdraw.vue";
export default {
  components: {
    ModalRequestWithdraw
  },
  filters: {
    status(value) {
      switch (value) {
        case "waiting":
          return "Pendente";
        case "approved":
          return "Aprovado";
        case "canceled":
          return "Cancelado";
        case "reproved":
          return "Reprovado";
        default:
          return "Pendente";
      }
    }
  },
  data() {
    return {
      my_withdraws: [],
      balance: 0
    };
  },
  methods: {
    getMyWithdraws() {
      this.$run("funcionarios/list-my-withdraw-requests").then(res => {
        this.my_withdraws = res.data;
      });
    }
  },
  created() {
    this.getMyWithdraws();
  }
};
</script>
