import http from "@/apps/shared/baseService/config";
import { EventBus } from "@/main.js";

const base = {
  rota: "gestordepedidos",
  nome: "Gestor de Vendas"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/transacoes/list-sales`, data),
  atualizar: data => http.post(`/admin/transacoes/update`, data),
  update_files: data => http.post(`/admin/transacoes/update-files`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_gestordepedidos: ({ commit, dispatch, getters }) => {
      if (localStorage.gestordepedidos) {
        commit("set_gestordepedidos", JSON.parse(localStorage.gestordepedidos));
        EventBus.$emit("updateKanban");
      } else {
        dispatch("enableLoading");
      }
      service
        .listar({
          ...getters.get_gestordepedidos_filtros,
          filters: { tipo_de_transacao: "compra" }
        })
        .then(res => {
          localStorage.gestordepedidos = JSON.stringify(res.data);
          commit("set_gestordepedidos", res.data);
          dispatch("disableLoading");
        })
        .catch(e => {
          console.log("eeee", e);
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_gestordepedido: ({ commit, dispatch, getters }, quiet) => {
      if (!quiet) {
        dispatch("enableLoading");
      }
      service
        .atualizar({
          ...getters.get_gestordepedido,
          imagens: getters.getLinks,
          arquivos: getters.getFiles
        })
        .then(res => {
          dispatch("disableLoading");
          commit("set_gestordepedido", res.data);
          if (!quiet) {
            dispatch("createGlobalMessage", {
              type: "success",
              message: "Atualização Concluída!",
              timeout: 6000
            });
          }
          const updateLocalSale = sale => {
            let sales = JSON.parse(localStorage.gestordepedidos);
            let index = sales.docs.findIndex(item => item._id === sale._id);
            sales.docs[index] = sale;
            localStorage.gestordepedidos = JSON.stringify(sales);
          };
          updateLocalSale(res.data);
          dispatch("listar_gestordepedidos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_gestordepedido: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_gestordepedido, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_gestordepedido", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_gestordepedidos");
          commit("set_modal_view_gestordepedido", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_gestordepedido: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_gestordepedido)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_gestordepedidos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_gestordepedidos: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_gestordepedidos_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_gestordepedidos");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_gestordepedido: ({ commit }, payload) => {
      let sale = payload;
      sale.comments = sale.comments ? sale.comments.reverse() : [];
      commit("set_gestordepedido", sale);
      commit("setFiles", payload.arquivos);
      commit("setLinks", payload.imagens);
      commit("set_modal_view_gestordepedido", true);
    },
    fecha_modal_view_gestordepedido: ({ commit }) => {
      commit("set_modal_view_gestordepedido", false);
      setTimeout(() => {
        commit("set_gestordepedido", {
          compra: {
            preco_total: 0,
            produtos: []
          },
          caixa: {
            funcionario: {}
          }
        });
      }, 300);
    },
    update_transaction_files: ({ commit, dispatch, getters }, payload) => {
      return new Promise((resolve, reject) => {
        service
          .update_files({
            _id: getters.get_gestordepedido._id,
            files: payload
          })
          .then(res => {
            commit("set_gestordepedido", {
              ...getters.get_gestordepedido,
              files: res.data.files,
              comments: res.data.comments
            });
            dispatch("createGlobalMessage", {
              type: "success",
              message: "Atualização Concluída!",
              timeout: 2000
            });
            const updateLocalSale = sale => {
              let sales = JSON.parse(localStorage.gestordepedidos);
              let index = sales.docs.findIndex(item => item._id === sale._id);
              sales.docs[index] = sale;
              localStorage.gestordepedidos = JSON.stringify(sales);
            };
            resolve(res.data);
          })
          .catch(e => {
            console.log(e);
            dispatch("createGlobalMessage", {
              type: "error",
              message: e,
              timeout: 6000
            });
            reject();
          });
      });
    }
  },
  state: {
    gestordepedido: {
      compra: {
        preco_total: 0,
        produtos: []
      },
      caixa: {
        funcionario: {}
      }
    },
    gestordepedidos: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    gestordepedidos_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_gestordepedido: false
  },
  mutations: {
    set_gestordepedido: (state, payload) => (state.gestordepedido = payload),
    set_gestordepedidos: (state, payload) => (state.gestordepedidos = payload),
    set_modal_view_gestordepedido: (state, payload) =>
      (state.modal_view_gestordepedido = payload)
  },
  getters: {
    get_gestordepedido: state => state.gestordepedido,
    get_gestordepedidos: state => state.gestordepedidos,
    get_modal_view_gestordepedido: state => state.modal_view_gestordepedido,
    get_gestordepedidos_filtros: state => state.gestordepedidos_filtros
  }
};

const routes = [
  // {
  //   path: `/${base.rota}`,
  //   component: () => import("./views/Main.vue"),
  //   name: base.nome
  // },
  {
    path: `/consultas-medico`,
    component: () => import("./views/MainDoctor.vue"),
    name: "Consultas Médico"
  },
  {
    path: `/prontuario`,
    component: () => import("./views/MainPatient.vue"),
    name: base.nome
  },
  {
    path: `/acompanhar/:id`,
    props: true,
    component: () => import("./views/AcompanhamentoDoCliente.vue"),
    name: "Acompanhamento do Cliente"
  }
];

export default {
  service,
  module,
  routes
};
