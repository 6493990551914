import http from "@/apps/shared/baseService/config";

const base = {
  rota: "signed_transactions",
  nome: "Assinaturas"
};

export const service = {
  criar_public: data => http.post(`/admin/${base.rota}/create`, data)
};

const module = {
  actions: {
    open_signed_transaction: ({ commit }, payload) => {
      if (payload) {
        commit("set_signed_transaction", payload);
        commit("setFiles", payload.arquivos);
      }
      commit("set_modal_view_signed_transaction", true);
    },
    close_signed_transaction: ({ commit }) => {
      commit("set_signed_transaction", {});
      commit("setLinks", []);
      commit("set_modal_view_signed_transaction", false);
    }
  },
  state: {
    signature: {},
    modal_view_signed_transaction: false
  },
  mutations: {
    set_signed_transaction: (state, payload) =>
      (state.signed_transaction = payload),
    set_modal_view_signed_transaction: (state, payload) =>
      (state.modal_view_signed_transaction = payload)
  },
  getters: {
    get_signed_transaction: state => state.signed_transaction,
    get_modal_view_signed_transaction: state =>
      state.modal_view_signed_transaction
  }
};

const routes = [];

export default {
  service,
  module,
  routes
};
