export const getters = {
  getLoggedUser: state => state.loggedUser,
  get_permission_user: state => {
    if (state.loggedUser.is_patient) {
      return ["inicio-paciente"];
    } else {
      return state.loggedUser.permissao.permission;
    }
  },
  getToken: state => state.token,
  getSponsor: state => state.sponsor,
  getModalForgotPassword: state => state.modalForgotPassword
};

export default {};
