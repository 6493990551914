<template>
  <div class="expande-horizontal fonte pt-3 column">
    <div
      class="ml-3"
      style="display: flex; min-height: 40px; align-items: center;"
    >
      <label style="cursor: pointer; z-index: 10;" for="input-file2">
        <v-avatar icon>
          <v-icon size="17" v-if="initialized" color="#333"
            >mdi-cloud-upload-outline</v-icon
          >
          <v-icon v-else color="#333">mdi-attachment-plus</v-icon>
        </v-avatar>
        <span class="fonte white--text mr-1"></span>
      </label>
      <input
        style="cursor: pointer;"
        v-if="!initialized"
        id="input-file2"
        type="file"
        ref="input"
        name="importxml"
        @change="upload"
      />
      <span id="file-name2"></span>
      <v-progress-circular
        v-if="initialized"
        style="margin-left: -43px;"
        :value="loading"
        :indeterminate="initialized && !loading"
        :color="$theme.primary"
      ></v-progress-circular>
      <div
        class="expande-horizontal wrap ml-0 fonte"
        v-if="value && value.length"
      >
        <v-chip
          style="border: 1px solid #d0cfcf;display: flex; align-items: center; justify-content: center; height: 30px;"
          color="#e5e5e5"
          small
          class="ma-1"
          v-for="(file, index) in value"
          :key="`${file.name}-${index}`"
        >
          <span
            class="mr-1 font-weight-bold fonteMini text-uppercase signed-gradient"
            >{{ file.format }}</span
          >
          <a
            v-if="!file.edit"
            class="text-lowercase"
            style="color: #555; text-decoration: none; cursor: pointer;"
            target="_blank"
            :href="file.link"
          >
            {{ file.name }}
          </a>
          <v-text-field
            v-else
            @keyup.enter.prevent="saveFileEdit(file)"
            autofocus
            hide-details
            dense
            class="fonte fonteMini ma-0 pa-0"
            style="decoration: none; outline: none; border: none; height: 25px;"
            v-model="newFileName"
          ></v-text-field>
          <v-icon
            @click="saveFileEdit(file)"
            v-if="file.edit"
            color="#999"
            small
            class="ml-1"
          >
            {{ "mdi-check" }}
          </v-icon>
          <v-icon
            @click="activeFileEdit(file)"
            v-else
            color="#999"
            small
            class="ml-1"
          >
            {{ "mdi-circle-edit-outline" }}
          </v-icon>
          <v-icon
            @click="
              createConfirmAction({
                icon: 'mdi-warning',
                color: 'yellow darken-3',
                message: 'Remover este arquivo?',
                isScoped: true,
                action: () => removeFile(file),
                action_value: file
              })
            "
            color="#999"
            small
            class="ml-1"
          >
            mdi-delete-outline
          </v-icon>
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject
} from "firebase/storage";
export default {
  props: ["value", "signature"],
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  data() {
    return {
      loading: 0,
      initialized: false,
      newFileName: ""
    };
  },
  methods: {
    ...mapActions(["createConfirmAction"]),
    upload(evt) {
      if (this.initialized) {
        this.resetInput();
        return;
      }
      this.initialized = true;
      evt.stopPropagation();
      evt.preventDefault();
      var file = evt.target.files[0];
      if (!file) return;
      var metadata = {
        contentType: file.type
      };

      const self = this;

      const storage = getStorage();
      const storageRef = ref(storage);
      const imagesRef = ref(
        storageRef,
        `files/${self.getLoggedUser.tenant[0]._id}/sign/${self.signature._id}/${
          self.value ? self.value.length : 0
        }-${file.name}`
      );
      const taskLoad = uploadBytesResumable(imagesRef, file, metadata);
      taskLoad.on(
        "state_changed",
        //get progress
        snapshot => {
          self.loading =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {},
        //success
        () => {
          getDownloadURL(taskLoad.snapshot.ref).then(link => {
            self.$emit("input", [
              ...(self.value || []),
              {
                name: file.name,
                link,
                format: file.name.split(".").pop(),
                size: file.size
              }
            ]);
            self.loading = 0;
            setTimeout(() => {
              self.initialized = false;
            }, 1000);
          });
        }
      );
    },
    resetInput() {
      this.initialized = false;
      this.loading = 0;
      this.$refs.input.value = "";
    },
    removeFile(file) {
      const self = this;
      const storage = getStorage();
      const httpsReference = ref(storage, file.link);
      deleteObject(httpsReference).then(function() {
        let newFiles = self.value.filter(f => f.link !== file.link);
        self.$emit("input", newFiles);
        self.loading = 0;
        self.initialized = false;
      });
      // .catch(function(error) {
      // });
    },
    activeFileEdit(file) {
      const files = this.value || [];
      let newFiles = files.map(f => {
        if (f.link === file.link) {
          f.edit = true;
          this.newFileName = f.name;
        }
        return f;
      });
      this.$emit("input", newFiles);
    },
    saveFileEdit(file) {
      const files = this.value || [];
      let newFiles = files.map(f => {
        if (f.link === file.link) {
          f.name = this.newFileName;
          delete f.edit;
        }
        return f;
      });
      this.$emit("input", newFiles);
    }
  }
};
</script>

<style>
input[type="file"] {
  display: none;
}

.input-wrapper2 label:hover {
  background-color: #e1673c;
}

.img {
  border-radius: 6px;
  border: 2px solid rgb(71, 73, 71);
}
</style>
