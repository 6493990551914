import http from "@/apps/shared/baseService/config";

const base = {
  rota: "withdraws",
  nome: "Saques"
};

export const service = {
  criar_public: data => http.post(`/admin/${base.rota}/create`, data)
};

const module = {
  actions: {
    open_withdraw: ({ commit }, payload) => {
      if (payload) {
        commit("set_withdraw", payload);
        commit("setFiles", payload.arquivos);
      }
      commit("set_modal_view_withdraw", true);
    },
    close_withdraw: ({ commit }) => {
      commit("set_withdraw", {});
      commit("setLinks", []);
      commit("set_modal_view_withdraw", false);
    }
  },
  state: {
    signature: {},
    modal_view_withdraw: false
  },
  mutations: {
    set_withdraw: (state, payload) => (state.withdraw = payload),
    set_modal_view_withdraw: (state, payload) =>
      (state.modal_view_withdraw = payload)
  },
  getters: {
    get_withdraw: state => state.withdraw,
    get_modal_view_withdraw: state => state.modal_view_withdraw
  }
};

const routes = [];

export default {
  service,
  module,
  routes
};
