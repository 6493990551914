import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
// import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

export const app = initializeApp(config);

export const storage = getStorage(
  app,
  process.env.VUE_APP_FIREBASE_STORAGE_BUCKET
);
export const db = getDatabase(app);
export const analytics = getAnalytics(app);
// export const messaging = getMessaging(app);
// removi o messaging pois já está sendo registrado no template, toda vez que o getMessaging é invocado, ele registra um sw.js do firebase
// m.usePublicVapidKey(process.env.VUE_APP_FIREBASE_VAPID_KEY);
