:<template>
  <v-container
    :style="`background: ${$theme.background}; flex-direction: column;`"
    fluid
    class="molde pa-0 ma-0 "
  >
    <ModalSubscription ref="modalSub" />

    <v-layout :style="`background: ${$theme.background};`" column>
      <div class="expande-horizontal centraliza">
        <v-flex v-if="isAndroid" xs12>
          <div class="expande-horizontal">
            <v-app-bar
              :app="$vuetify.breakpoint.smAndDown"
              color="#fff"
              :fixed="$vuetify.breakpoint.smAndDown"
              class="elevation-0"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-bottom: 0px; border-radius: 0px'
                  : 'border-radius: 0px;margin-bottom: 0px'
              "
            >
              <v-btn @click="drawer = !drawer" icon small class="mr-1">
                <v-icon color="blue">mdi-menu</v-icon>
              </v-btn>
              <img @click="goToDashboard" width="50" contain src="logo.svg" />
              <v-btn
                dark
                rounded
                text
                color="green accent-3"
                class="fonte ml-2 elevation-0"
                @click="$refs.onboarding.open('dashboard')"
                small
                v-if="false"
              >
                <span class="fonteMini">guia</span>
                <v-icon class="ml-1" size="16">mdi-help-circle-outline</v-icon>
              </v-btn>
              <v-spacer></v-spacer>

              <div v-if="false" id="notifications-container">
                <v-menu offset-y left bottom :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      rounded
                      class="animate__animated animate__swing  elevation-0"
                      :color="
                        notificationsUnreadeds.length ? $theme.primary : 'grey'
                      "
                      v-on="on"
                    >
                      <span class="font-weight-bold white--text">
                        {{ notificationsUnreadeds.length }}
                      </span>
                      <v-icon size="21" color="#f7f7f7"
                        >mdi-bell-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-card class="pa-3">
                    <v-list-item class="pa-0">
                      <v-avatar class="mr-3 elevation-1" color="#333">
                        <v-icon :color="$theme.primary">
                          mdi-bell-outline
                        </v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Notificações
                        </v-list-item-title>
                        <span class="fonte fonteMini grey--text">
                          {{ notificationsUnreadeds.length }} Notificações
                          novas</span
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="column centraliza">
                          <v-switch
                            small
                            hide-details
                            class="fonte fonteMini"
                            :color="$theme.primary"
                            v-model="onlyUnreadeds"
                          >
                          </v-switch>
                          <span
                            style="cursor: pointer;"
                            @click="onlyUnreadeds = !onlyUnreadeds"
                            class="fonteMini fonte grey--text"
                          >
                            não lidas
                          </span>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list
                      class="fonte"
                      v-if="notifications.length > 0"
                      style="max-height: 300px; overflow: auto;"
                    >
                      <template
                        v-for="(notification, i) in onlyUnreadeds
                          ? notificationsUnreadeds
                          : notifications"
                      >
                        <v-list-item
                          class="item-list-notification"
                          :key="notification._id"
                          v-if="onlyUnreadeds ? !notification.readed : true"
                          @click="abre_modal_view_notification(notification)"
                        >
                          <v-avatar size="31" class="mr-3" color="#333">
                            <v-icon :color="$theme.primary" size="21">
                              {{ getIconNotification(notification.type) }}
                            </v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span
                                class=""
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                              >
                                {{
                                  getNotificationDescription(notification.type)
                                }}
                              </span>
                            </v-list-item-title>
                            <span
                              class="fonte grey--text fonteMini mt-1"
                              :class="{
                                'font-weight-bold': !notification.readed
                              }"
                            >
                              {{ notification.description }}
                            </span>
                            <div class="expande-horizontal">
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                {{ $moment(notification.created_at).fromNow() }}
                              </span>
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                v-if="notification.readed"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , foi lida
                                {{
                                  $moment(notification.readDate).format(
                                    "DD/MM/YY [às] HH:mm"
                                  )
                                }}
                              </span>
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                v-else
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , não lida ainda.
                              </span>
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="!notification.readed"
                                >mdi-check</v-icon
                              >
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="notification.readed"
                                >mdi-check-all</v-icon
                              >
                            </div>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            
                          </v-list-item-action> -->
                        </v-list-item>
                        <!-- <v-divider :key="`divider-${i}`"></v-divider> -->
                      </template>
                    </v-list>
                    <div
                      v-if="notifications.length === 0"
                      class="notifications-empty-container fonte expande-horizontal centraliza column"
                    >
                      <v-avatar class="mb-3" :color="$theme.primary">
                        <v-icon color="#f2f2f2">mdi-bell-outline</v-icon>
                      </v-avatar>
                      <span :style="`color: ${$theme.primary}`" class="fonte"
                        >Nada por aqui</span
                      >
                      <span class="text-center grey--text fonteMini fonte">
                        Novas notificações aparecerão aqui
                      </span>
                    </div>
                  </v-card>
                </v-menu>
              </div>

              <div id="options-container">
                <v-menu offset-y v-show="options" left bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-show="options && $vuetify.breakpoint.smAndDown"
                      icon
                      :color="$theme.secondary"
                      v-on="on"
                    >
                      <v-icon :color="$theme.primary">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="item in options"
                      :key="item.nome"
                      @click="() => item.action()"
                    >
                      <v-list-item-title class="fonte">{{
                        item.nome
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-avatar
                @click="$router.push('/logged-user')"
                size="27"
                class="ml-2"
                v-if="getLoggedUser.profile_image"
              >
                <v-img :src="getLoggedUser.profile_image"></v-img>
              </v-avatar>
              <v-icon
                @click="$router.push('/logged-user')"
                size="21"
                class="ml-3"
                :color="$theme.primary"
                v-else
                >mdi-account-circle</v-icon
              >
            </v-app-bar>
          </div>
        </v-flex>
        <v-flex v-else xs12 md10>
          <div class="expande-horizontal">
            <v-app-bar
              dense
              app
              height="39"
              :fixed="$vuetify.breakpoint.smAndDown"
              class="elevation-0"
            >
              <v-btn @click="drawer = !drawer" icon>
                <v-icon size="19" color="blue">mdi-menu</v-icon>
              </v-btn>

              <span
                v-for="(rota, index) in rotas"
                @click="$router.push(rota.path)"
                :key="rota.path"
                :style="
                  `font-size: ${
                    rotas.length > 1 && index === 0 ? '12pt' : '12pt'
                  }; cursor: pointer; color: #333;`
                "
                class="fonte not-selectable text-no-wrap text-lowercase fonteMini"
              >
                {{ rota.name }}
                <v-icon
                  class="pl-0 pr-1"
                  :color="$theme.primary"
                  v-if="index < rotas.length - 1"
                  size="13"
                >
                  mdi-chevron-right
                </v-icon>
              </span>
              <v-spacer></v-spacer>
              <slot name="toolbar-search"></slot>
              <v-spacer></v-spacer>
              <span class="fonte text-capitalize grey--text fonteMini">
                {{ $moment().format("LLLL") }}
                <v-btn
                  rounded
                  color="green accent-3"
                  class="fonte ml-2 elevation-0"
                  @click="$refs.onboarding.open('dashboard')"
                  x-small
                  v-if="false"
                >
                  <span class="fonteMini">guia</span>
                  <v-icon class="ml-1" size="16"
                    >mdi-help-circle-outline</v-icon
                  >
                </v-btn>
              </span>
              <v-spacer></v-spacer>
              <div id="options-container">
                <v-btn
                  small
                  v-for="item in options"
                  :color="$theme.primary"
                  dark
                  rounded
                  class="elevation-0 animated flipInX delay-1s mr-1"
                  @click="item.action()"
                  :key="item.nome"
                >
                  <v-icon size="13" class="mr-1" color="#fff">
                    {{ item.icon }}
                  </v-icon>
                  <span
                    :style="`font-size: 9pt; color: ${$theme.primary}`"
                    class="fonte fonteMini white--text text-capitalize"
                    >{{ item.nome }}</span
                  >
                </v-btn>
              </div>
              <div v-if="false" id="notifications-container">
                <v-menu offset-y left bottom :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      rounded
                      class="animate__animated animate__swing  elevation-0"
                      :color="
                        notificationsUnreadeds.length ? $theme.primary : 'grey'
                      "
                      v-on="on"
                    >
                      <span class="font-weight-bold white--text">
                        {{ notificationsUnreadeds.length }}
                      </span>
                      <v-icon size="21" color="#fff">mdi-bell-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-3">
                    <v-list-item class="pa-0">
                      <v-avatar class="mr-3 elevation-1" color="#333">
                        <v-icon :color="$theme.primary">
                          mdi-bell-outline
                        </v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          Notificações
                        </v-list-item-title>
                        <span class="fonte fonteMini grey--text">
                          {{ notificationsUnreadeds.length }} Notificações
                          novas</span
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="column centraliza">
                          <v-switch
                            small
                            hide-details
                            class="fonte fonteMini"
                            :color="$theme.primary"
                            v-model="onlyUnreadeds"
                          >
                          </v-switch>
                          <span
                            style="cursor: pointer;"
                            @click="onlyUnreadeds = !onlyUnreadeds"
                            class="fonteMini fonte grey--text"
                          >
                            não lidas
                          </span>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list
                      class="fonte"
                      v-if="notifications.length > 0"
                      width="350"
                      style="max-height: 300px; overflow: auto;"
                    >
                      <template
                        v-for="(notification, i) in onlyUnreadeds
                          ? notificationsUnreadeds
                          : notifications"
                      >
                        <v-list-item
                          class="item-list-notification"
                          :key="notification._id"
                          v-if="onlyUnreadeds ? !notification.readed : true"
                          @click="abre_modal_view_notification(notification)"
                        >
                          <v-avatar size="31" class="mr-3" color="#333">
                            <v-icon :color="$theme.primary" size="21">
                              {{ getIconNotification(notification.type) }}
                            </v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span
                                class=""
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                              >
                                {{
                                  getNotificationDescription(notification.type)
                                }}
                              </span>
                            </v-list-item-title>
                            <span
                              class="fonte grey--text fonteMini mt-1"
                              :class="{
                                'font-weight-bold': !notification.readed
                              }"
                            >
                              {{ notification.description }}
                            </span>
                            <div class="expande-horizontal">
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                {{
                                  $moment(notification.created_at).fromNow()
                                }}</span
                              >
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                v-if="notification.readed"
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , foi lida
                                {{
                                  $moment(notification.readDate).format(
                                    "DD/MM/YY [às] HH:mm"
                                  )
                                }}
                              </span>
                              <span
                                class="orange--text fonte fonteMini mt-1"
                                v-else
                                :class="{
                                  'font-weight-bold': !notification.readed
                                }"
                                style="font-size: 6pt;"
                              >
                                , não lida ainda.
                              </span>
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="!notification.readed"
                                >mdi-check</v-icon
                              >
                              <v-icon
                                class="ml-2"
                                color="green"
                                size="15"
                                v-if="notification.readed"
                                >mdi-check-all</v-icon
                              >
                            </div>
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            
                          </v-list-item-action> -->
                        </v-list-item>
                        <!-- <v-divider :key="`divider-${i}`"></v-divider> -->
                      </template>
                    </v-list>
                    <div
                      v-if="notifications.length === 0"
                      class="notifications-empty-container fonte expande-horizontal centraliza column"
                    >
                      <v-avatar class="mb-3" :color="$theme.primary">
                        <v-icon color="#f2f2f2">mdi-bell-outline</v-icon>
                      </v-avatar>
                      <span :style="`color: ${$theme.primary}`" class="fonte"
                        >Nada por aqui</span
                      >
                      <span class="text-center grey--text fonteMini fonte">
                        Novas notificações aparecerão aqui
                      </span>
                    </div>
                  </v-card>
                </v-menu>
              </div>

              <v-menu offset-y left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    rounded
                    class="animate__animated ml-1 elevation-0"
                    color="red"
                    v-if="!getLoggedUser.is_patient"
                    v-on="on"
                  >
                    <span class="font-weight-bold white--text">
                      {{ getLoggedUser.tenant[0].nome }}
                    </span>
                    <v-avatar
                      v-if="getLoggedUser.tenant[0].profileImage"
                      size="21"
                      v-on="on"
                      class="ml-3"
                    >
                      <img
                        class="imageElement-logo"
                        :src="getLoggedUser.tenant[0].profileImage"
                      />
                    </v-avatar>
                    <v-icon color="white" v-else>mdi-storefront</v-icon>
                  </v-btn>
                </template>

                <v-list nav dense>
                  <v-list-item to="/logged-user">
                    <v-list-item-title>{{
                      getLoggedUser.nome
                    }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="getLoggedUser.is_patient"
                    class="white--text font-weight-bold"
                    @click="openSub"
                  >
                    <v-list-item-title>Ver Assinatura</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="logout">
                    <v-list-item-title>sair</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu offset-y v-show="options" left bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-show="options && $vuetify.breakpoint.smAndDown"
                    icon
                    :color="$theme.secondary"
                    v-on="on"
                  >
                    <v-icon :color="$theme.secondary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="fonte">
                  <v-list-item
                    v-for="item in options"
                    :key="item.nome"
                    @click="() => item.action()"
                  >
                    <v-list-item-title class="fonte">{{
                      item.nome
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar>
          </div>
        </v-flex>
      </div>

      <v-navigation-drawer
        :mini-variant="!drawer"
        class="drawer-roller-style"
        color="#e5e5e5"
        v-if="$vuetify.breakpoint.lgAndUp && !getLoggedUser.is_patient"
        app
        width="210"
        :v-model="drawer"
      >
        <v-list
          style="height: 74vh;"
          dense
          nav
          :light="$theme.light"
          :dark="$theme.dark"
        >
          <v-list-item class="fonte black--text ml-0 pl-0" to="/logged-user">
            <v-avatar
              rounded
              size="27"
              class="mr-1"
              v-if="getLoggedUser.profile_image"
            >
              <v-img :src="getLoggedUser.profile_image"></v-img>
            </v-avatar>
            <v-avatar size="38" color="#fff" rounded class="mr-2" v-else>
              <v-icon color="red lighten-1">
                mdi-medical-bag
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="getLoggedUser.nome" class="fonte">
                {{ getLoggedUser.nome.split(" ")[0] }}
              </v-list-item-title>
              <span
                v-if="!getLoggedUser.is_patient"
                class="fonteMini grey--text"
              >
                {{ getLoggedUser.permissao.nome }}
              </span>
            </v-list-item-content>
          </v-list-item>

          <template
            v-if="
              !getLoggedUser.is_patient &&
                getLoggedUser.permissao.nome !== 'Médico'
            "
          >
            <template v-for="(menu, index) in menus">
              <v-divider
                v-if="menu.name === 'divider' && menu.permission()"
                :key="`${menu.name}--${index}`"
              ></v-divider>
              <v-list-item
                v-if="menu.name !== 'divider' && menu.permission()"
                :to="menu.route"
                :key="menu.name"
                :class="
                  `${
                    menu.route === $route.path ? 'white--text' : 'black--text'
                  }`
                "
                :active-class="
                  menu.route === $route.path ? 'menuAtivoHome' : ''
                "
              >
                <v-icon
                  size="15"
                  class="ml-1 mr-1"
                  :color="
                    menu.route === $route.path ? 'white' : 'grey darken-3'
                  "
                >
                  {{ menu.icon }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title class="fonte">
                    <span class="fonteMini fonte">
                      {{ menu.name }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>

          <v-list-item
            :class="{
              'grey--text': $route.path === '/gestordepedidos' ? true : false
            }"
            class="fonte grey--text fonteMini"
            v-if="false"
            to="/escolher-empresa"
          >
            <v-icon size="18" class="mr-1" color="orange">
              mdi-swap-horizontal
            </v-icon>
            <v-list-item-title>
              Trocar de empresa
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="getLoggedUser.is_patient"
            class="white--text font-weight-bold"
            @click="openSub"
          >
            <v-list-item-title>Ver Assinatura</v-list-item-title>
          </v-list-item>

          <v-list-item
            :class="{
              'grey--text': $route.path === '/gestordepedidos' ? true : false
            }"
            class="fonte grey--text fonteMini"
            @click="logout"
          >
            <v-icon size="16" class="mr-2" color="red">
              mdi-exit-run
            </v-icon>
            <v-list-item-title>sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer
        v-else
        app
        v-model="drawer"
        class="drawer-roller-style"
        dark
      >
        <v-list
          style="height: 74vh;"
          dense
          :light="
            $theme.light || $route.path === '/gestordepedidos' ? true : false
          "
          :dark="$route.path === '/gestordepedidos' ? false : $theme.dark"
        >
          <v-list-item class="fonte white--text" to="/logged-user">
            <v-avatar size="27" class="mr-2" v-if="getLoggedUser.photo.length">
              <v-img
                :src="getLoggedUser.photo.length ? getLoggedUser.photo[0] : ''"
              ></v-img>
            </v-avatar>
            <v-icon size="21" class="mr-3" :color="$theme.primary" v-else
              >mdi-account-circle</v-icon
            >
            <v-list-item-content class="ml-2">
              <v-list-item-title class="fonte font-weight-bold">
                {{ getLoggedUser.nome }}
              </v-list-item-title>
              <span
                v-if="!getLoggedUser.is_patient"
                class="fonteMini grey--text"
              >
                {{ getLoggedUser.permissao.nome }}
              </span>
            </v-list-item-content>
          </v-list-item>
          <template
            v-if="
              !getLoggedUser.is_patient &&
                getLoggedUser.permissao.nome !== 'Médico'
            "
          >
            <template v-for="(menu, index) in menus">
              <v-divider
                v-if="menu.name === 'divider' && menu.permission()"
                :key="`${menu.name}--${index}`"
              ></v-divider>
              <v-list-item
                v-if="menu.name !== 'divider' && menu.permission()"
                :to="menu.route"
                :key="menu.name"
                class="white--text"
                :active-class="
                  menu.route === $route.path ? 'menuAtivoHome' : ''
                "
              >
                <v-icon size="21" class="mr-3" color="#fff">
                  {{ menu.icon }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title class="fonte fonteMini">
                    {{ menu.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>

          <v-list-item
            :class="{
              'white--text': $route.path === '/gestordepedidos' ? true : false
            }"
            v-if="false"
            to="/escolher-empresa"
          >
            <v-icon
              size="21"
              class="mr-3"
              :color="
                $route.path === '/gestordepedidos'
                  ? 'white'
                  : $theme.icon_drawer
              "
            >
              mdi-swap-horizontal
            </v-icon>
            <v-list-item-title>
              Trocar de empresa
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="getLoggedUser.is_patient"
            class="white--text font-weight-bold"
            @click="openSub"
          >
            <v-list-item-title>Ver Assinatura</v-list-item-title>
          </v-list-item>

          <v-list-item
            :class="{
              'white--text': $route.path === '/gestordepedidos' ? true : false
            }"
            @click="logout"
          >
            <v-icon size="21" class="mr-2" color="red">
              mdi-exit-run
            </v-icon>
            <v-list-item-title class="grey--text">sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main
        class="molde"
        :style="
          `border-radius: 20px !important; background: ${$theme.background};`
        "
      >
        <div
          class="expande-horizontal centraliza"
          style="max-height: 94vh; min-height: 94vh; border-radius: 6px;"
        >
          <v-flex xs12 md12>
            <div style="border-radius: 6px;" class="expande-horizontal wrap">
              <slot></slot>
            </div>
          </v-flex>
        </div>
      </v-main>
    </v-layout>
    <ModalOnboarding ref="onboarding" />
    <v-dialog persistent width="300" v-model="modal_new_consult">
      <v-card width="300">
        <div class="pa-3 expande-horizontal centraliza column">
          <v-icon
            size="31"
            class="mb-3 animate__animated animate__swing animate__infinite"
            color="blue accent-3"
            >mdi-bell</v-icon
          >
          <span class="fonte">Você tem uma nova consulta</span>
          <v-btn
            rounded
            color="blue accent-3"
            class="fonte mt-3 white--text"
            v-if="getLoggedUser.is_patient"
          >
            <span
              @click="
                $router.push(
                  `/prontuario?t_id=${modal_new_consult_content._id}&call_doctor=yes`
                )
              "
              class="fonteMini"
              >Entrar na consulta</span
            >
          </v-btn>
          <v-btn
            rounded
            v-else
            color="blue accent-3"
            class="fonte mt-3 white--text"
          >
            <span
              @click="
                $router.push(
                  `/consultas-medico?t_id=${modal_new_consult_content._id}`
                )
              "
              class="fonteMini"
              >Entrar na consulta</span
            >
          </v-btn>
          <v-btn
            text
            color="red accent-3"
            class="fonte mt-3 elevation-0"
            @click="modal_new_consult = false"
            small
          >
            <span
              @click="
                $router(
                  `/consultas-medico?t_id=${modal_new_consult_content._id}`
                )
              "
              class="fonteMini"
              >recusar</span
            >
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent width="300" v-model="modal_last_consult">
      <v-card width="300">
        <div class="pa-3 expande-horizontal centraliza column">
          <!-- <v-icon size="31" class="mb-3 animate__animated animate__swing animate__infinite" color="blue accent-3">mdi-bell</v-icon> -->
          <span class="fonte"
            >Você têm uma consulta em andamento, deseja acessar?</span
          >
          <v-btn
            rounded
            color="blue accent-3"
            class="fonte mt-3 white--text"
            v-if="getLoggedUser.is_patient"
          >
            <span
              @click="
                $router.push(
                  `/prontuario?t_id=${modal_last_content._id}&call_doctor=yes`
                )
              "
              class="fonteMini"
              >Entrar na consulta</span
            >
          </v-btn>
          <v-btn
            rounded
            v-else
            color="blue accent-3"
            class="fonte mt-3 white--text"
          >
            <span
              @click="
                $router.push(`/consultas-medico?t_id=${modal_last_content._id}`)
              "
              class="fonteMini"
              >Entrar na consulta</span
            >
          </v-btn>
          <v-btn
            text
            color="red accent-3"
            class="fonte mt-3 elevation-0"
            @click="modal_last_consult = false"
            small
          >
            <span
              @click="
                $router(`/consultas-medico?t_id=${modal_last_content._id}`)
              "
              class="fonteMini"
              >Não</span
            >
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalOnboarding from "./ModalOnboarding";
import ModalSubscription from "@/apps/shared/components/ModalSubscription.vue";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { onBackgroundMessage } from "firebase/messaging/sw";
export default {
  props: ["title", "options", "icon", "action", "origem", "rotas"],
  data() {
    return {
      my_status: "",
      drawer: false,
      modal_new_consult: false,
      modal_new_consult_content: {
        compra: {}
      },
      modal_last_consult: false,
      modal_last_content: {
        compra: {}
      },
      onlyUnreadeds: false,
      transparency_routes: ["/dashboard", "/atendimento", "/gestordepedidos"],
      menus: [
        {
          icon: "mdi-view-dashboard-outline",
          name: "Início",
          route: "/dashboard",
          permission: () => this.get_permission_user["inicio-paciente"]
        },
        {
          icon: "mdi-account-group",
          name: "Vendedores",
          route: "/vendedores",
          permission: () => this.get_permission_user["vendedor"]
        },
        {
          //paciente
          icon: "mdi-clipboard-plus-outline",
          name: "Consulta Imediata",
          route: "/atendimento-imediato",
          permission: () => this.get_permission_user["inicio-paciente"]
        },
        {
          //paciente
          icon: "mdi-clipboard-plus-outline",
          name: "Consulta Agendada",
          route: "/atendimento-agendado",
          permission: () => this.get_permission_user["inicio-paciente"]
        },
        {
          //paciente
          icon: "mdi-clipboard-flow-outline",
          name: "Prontuário",
          route: "/prontuario",
          permission: () => this.get_permission_user["inicio-paciente"]
        },
        {
          //médico
          icon: "mdi-view-dashboard-outline",
          name: "Início",
          route: "/medico",
          permission: () => this.get_permission_user["inicio-medico"]
        },
        {
          //médico
          icon: "mdi-clipboard-flow-outline",
          name: "Minhas consultas",
          route: "/gestordepedidos",
          permission: () => this.get_permission_user["minhas-consultas"]
        },
        {
          name: "divider",
          permission: () => false
        },
        {
          //médico
          icon: "mdi-calendar-badge-outline",
          name: "Minha disponibilidade",
          route: "/minha-agenda",
          permission: () => this.get_permission_user["minha-agenda"]
        },
        {
          //médico
          icon: "mdi-cash",
          name: "Saques",
          route: "/meus-saques",
          permission: () => this.get_permission_user["meus-saques"]
        },
        {
          //sistema
          icon: "mdi-calendar-badge-outline",
          name: "Solicitações de saque",
          route: "/solicitacoes-de-saque",
          permission: () => this.get_permission_user["solicitacoes-saque"]
        },

        // {
        //   icon: "mdi-calendar-edit-outline",
        //   name: "Agenda",
        //   route: "/agenda",
        //   permission: () => this.get_permission_user["agenda"]
        // },
        {
          name: "divider",
          permission: () => false
        },
        // {
        //   icon: "mdi-clipboard-list-outline",
        //   name: "Lista de compras",
        //   route: "/lista-de-compras",
        //   permission: () => this.get_permission_user["lista-de-compras"]
        // },
        {
          name: "divider",
          permission: () => false
        },
        {
          //adm
          icon: "mdi-clipboard-text-search-outline",
          name: "Especialidades",
          route: "/insumos",
          permission: () => this.get_permission_user["especialidades"]
        },
        {
          //adm
          icon: "mdi-clipboard-account",
          name: "Pacientes",
          route: "/clientes",
          permission: () => this.get_permission_user["clientes"]
        },
        {
          //adm
          icon: "mdi-account-group-outline",
          name: "Colaboradores",
          route: "/funcionarios",
          permission: () => this.get_permission_user["colaboradores"]
        },
        {
          name: "divider",
          permission: () => false
        },
        // {
        //   icon: "mdi-ballot-outline",
        //   name: "Contas",
        //   route: "/invoices",
        //   permission: () => this.get_permission_user["contas"]
        // },
        // {
        //   icon: "mdi-receipt",
        //   name: "Relatório de contas",
        //   route: "/caixas",
        //   permission: () => this.get_permission_user["relatorios"]
        // },
        {
          name: "divider",
          permission: () => false
        },
        // {
        //   icon: "mdi-receipt-outline",
        //   name: "Histórico de vendas",
        //   route: "/transacoes",
        //   permission: () => this.get_permission_user["relatorios"]
        // },
        // {
        //   icon: "mdi-receipt-text-check-outline",
        //   name: "Contabilidade dos Caixas",
        //   route: "/acompanhamento-de-caixa",
        //   permission: () => this.get_permission_user["contabilidade-de-caixas"]
        // },
        // {
        //   icon: "mdi-receipt-text-check-outline",
        //   name: "Relatório",
        //   route: "/caixas",
        //   permission: () => this.get_permission_user["relatorio"]
        // },
        {
          name: "divider",
          permission: () => false
        },
        {
          //adm
          icon: "mdi-lock-outline",
          name: "Permissões",
          route: "/permissoes",
          permission: () => this.get_permission_user["permissoes"]
        },
        // {
        //   icon: "mdi-storefront-outline",
        //   name: "Gerenciar Empresas",
        //   route: "/marketplaces",
        //   permission: () => this.getLoggedUser.admin
        // },
        {
          //adm
          icon: "mdi-cog-outline",
          name: "Configurações",
          route: "/configurar-loja",
          permission: () => this.get_permission_user["configuracoes"]
        }
      ],
      notifications: [],
      isAndroid: false,
      miniVariant: true
    };
  },
  watch: {
    drawer(val) {
      localStorage.setItem("drawer", val);
    }
  },
  components: {
    ModalOnboarding,
    ModalSubscription
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_permission_user"]),
    notificationsUnreadeds() {
      return this.notifications.filter(n => !n.readed);
    }
  },
  methods: {
    ...mapActions(["logout"]),
    openSub() {
      if (this.getLoggedUser.is_patient) {
        this.$refs.modalSub.open();
      }
    },
    goToDashboard() {
      if (this.getLoggedUser.is_patient) {
        this.$router.push("/dashboard");
      } else {
        this.$router.push("/medico");
      }
    },
    notification_sound() {
      const audio = new Audio("call_in.mp3");
      audio.play();
    },
    abre_modal_view_notification(notify) {
      if (!notify.readed) {
        this.markNotificationAsReaded(notify);
      }
      if (notify.type === "sale_updated") {
        this.$router.push(`/gestordepedidos?t_id=${notify.payload._id}`);
      }
      if (notify.type === "new_comment") {
        this.$router.push(`/gestordepedidos?t_id=${notify.payload._id}`);
      }
    },
    getIconNotification(type) {
      const types = {
        new_comment: "mdi-comment-text-outline",
        new_sale: "mdi-cash-register",
        sale_updated: "mdi-satellite-variant",
        stock_low: "mdi-trending-down",
        stock_down: "mdi-basket-minus-outline",
        stock_up: "mdi-basket-plus-outline"
      };
      return types[type];
    },
    getNotificationDescription(type) {
      const types = {
        new_comment: "Novo comentário",
        new_sale: "Nova venda",
        sale_updated: "Venda atualizada",
        stock_low: "Estoque baixo",
        stock_up: "Entrada de estoque",
        stock_down: "Saída de estoque"
      };
      return types[type];
    },
    getMyLastConsult() {
      this.$run("transacoes/get-my-last-consult").then(res => {
        if (res.data && res.data.isActive) {
          this.modal_last_consult = true;
          this.modal_last_content = res.data;
        }
      });
    },
    listNotifications() {
      this.$run("notifications/list-my-notifications").then(res => {
        this.notifications = res.data || [];
      });
    },
    markNotificationAsReaded(notification) {
      this.$run("notifications/mark-as-readed", { _id: notification._id }).then(
        res => {
          this.listNotifications();
        }
      );
    },
    goBack() {
      if (this.action !== undefined) {
        this.action();
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.listNotifications();
    this.isAndroid =
      navigator.userAgent.match(/Android/i) ||
      this.$vuetify.breakpoint.smAndDown;
    this.drawer = !this.isAndroid;

    let cordova = window.cordova || false;
    const self = this;
    if (cordova) {
      cordova.plugins.firebase.messaging
        .requestPermission({ forceShow: false })
        .then(function() {
          console.log("Push messaging is allowed");
          cordova.plugins.firebase.messaging
            .getToken()
            .then(function(token) {
              self.$db.ref(`pn/${self.getLoggedUser._id}`).set({
                token: token,
                user: self.getLoggedUser._id
              });
            })
            .catch(function(err) {
              alert(err);
            });
        });
      // StatusBar.backgroundColorByHexString(this.$theme.secondary);
    }
    if (this.$vuetify.breakpoint.smAndDown) {
      this.drawer = false;
    } else {
      this.drawer = localStorage.getItem("drawer") === "true";
    }
  },
  mounted() {
    if (this.getLoggedUser.is_patient) {
      this.$refs.modalSub.verifySub();
    }

    if (Notification.permission === "granted") {
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
      }).then(currentToken => {
        if (currentToken) {
          this.$run("notifications/register-token", {
            notification_token: currentToken
          }).catch(res => {
            console.log("erro no token", res);
          });
        } else {
          alert(
            "você recusou o envio de notificações, para receber nossos fluxos de notificação, acesse as configurações do seu navegador e permita o envio de notificações."
          );
        }
        onMessage(messaging, payload => {
          const { data } = payload;
          this.modal_new_consult = true;
          this.modal_new_consult_content = data;
          this.notification_sound();
        });
      });
    } else {
      Notification.requestPermission()
        .then(async permission => {
          if (permission === "granted") {
            const messaging = getMessaging();
            getToken(messaging, {
              vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
            }).then(currentToken => {
              if (currentToken) {
                this.$run("notifications/register-token", {
                  notification_token: currentToken
                }).catch(res => {
                  console.log("erro no token", res);
                });
              } else {
                alert(
                  "você recusou o envio de notificações, para receber nossos fluxos de notificação, acesse as configurações do seu navegador e permita o envio de notificações."
                );
              }
              onMessage(messaging, payload => {
                this.modal_new_consult = true;
                this.modal_new_consult_content = payload.data;
                this.notification_sound();
              });
            });
          }
        })
        .catch(err => {
          console.log("wtf", err);
        });
    }
  }
};
</script>

<style>
.centraliza-tudo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molde {
  display: flex;
  width: 100%;
  border-radius: 6px;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 1px; /* width of the entire scrollbar */
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  background: rgb(122, 122, 122); /* color of the tracking area */
  border-radius: 6px;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #e5e7e8; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.notifications-empty-container {
  display: flex;
  width: 300px;
  border-radius: 6px;
  min-height: 300px;
  background: #fff;
}
.item-list-notification {
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  margin-bottom: 10px;
  padding-right: 15px;
  padding: 6px;
}
#medico {
  width: 30px;
  height: 30px;
}
</style>
