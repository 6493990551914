<template>
  <layout :title="$route.name" :options="options" :rotas="rotas">
    <!-- <Printerr /> -->
    <div
      v-if="false"
      id="status_caixa"
      class="expande-horizontal column"
      style="min-height: 94vh; max-height: 94vh;"
    >
      <v-flex xs12>
        <div
          v-if="get_caixa.status_atual === 'fechado'"
          class="expande-horizontal centraliza"
          style="height: 100%;"
        >
          <v-list color="transparent" nav>
            <v-list-item
              @click="abre_modal_abertura_caixa"
              style="border: 1px solid #fff; background: #fff;"
              class="item-list"
            >
              <v-avatar class="mr-2" size="31" color="#333">
                <v-icon size="18" :color="$theme.primary"
                  >mdi-cash-register</v-icon
                >
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte">
                  Caixa {{ get_caixa.status_atual }}
                </v-list-item-title>
                <v-list-item-subtitle class="fonte">
                  Clique aqui para abrir
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  rounded
                  class="black--text text-capitalized fonte fonte-bold"
                  :color="$theme.primary"
                  @click.prevent="abre_modal_abertura_caixa"
                  v-if="get_caixa.status_atual === 'fechado'"
                >
                  Abrir
                </v-btn>

                <v-btn
                  small
                  color="red"
                  text
                  @click.prevent="abre_modal_fechamento_caixa"
                  v-if="get_caixa.status_atual === 'aberto'"
                >
                  Fechar
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <v-card
          v-else
          outlined
          class="expande-horizontal wrap"
          :class="{
            'pa-3 pr-1 pl-0 elevation-0': $vuetify.breakpoint.lgAndUp,
            'elevation-0': $vuetify.breakpoint.smAndDown
          }"
          :style="
            `min-height: ${
              $vuetify.breakpoint.smAndDown ? '90vh' : '78vh'
            }; border-radius: 0px;`
          "
        >
          <v-flex :class="{ 'pa-0': $vuetify.breakpoint.lgAndUp }" xs12 md6>
            <div class="expande-horizontal column">
              <v-flex class="mb-3" xs12>
                <div class="expande-horizontal"></div>
              </v-flex>
              <!-- Pesquisa -->
              <v-flex xs12>
                <div class="expande-horizontal">
                  <v-flex xs10>
                    <v-text-field
                      @keyup.enter.prevent="attachOpenModalAddProduct"
                      v-model="inputSearch"
                      label="Faça uma busca"
                      filled
                      single-line
                      prefix="[ESC]"
                      ref="searchField"
                      hide-details
                      class="mx-3 fonte"
                      dense
                      :color="$theme.primary"
                      append-icon="mdi-magnify"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      single-line
                      v-model="payment_sys_config.finishing.tax_porcentage"
                      hide-details
                      type="number"
                      class="mx-3 fonte"
                      :color="$theme.primary"
                      filled
                      dense
                    ></v-text-field>
                  </v-flex>
                </div>
              </v-flex>

              <v-flex v-if="$vuetify.breakpoint.smAndDown" xs12>
                <div class="expande-horizontal wrap">
                  <v-flex class="px-3 pt-2" xs12>
                    <v-autocomplete
                      class="fonte"
                      dense
                      prepend-inner-icon="mdi-account-circle-outline"
                      label="Cliente"
                      :items="get_clientes.docs"
                      item-text="nome"
                      return-object
                      hide-details
                      filled
                      @change="setTypeBuy('delivery')"
                      @click:append-outer="abre_modal_view_cliente"
                      append-outer-icon="mdi-account-plus-outline"
                      v-model="get_compra.cliente"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <div
                      @click="setTypeBuy('delivery')"
                      class="px-3 fonte"
                      style="cursor: pointer;"
                    >
                      <div
                        v-if="get_compra.address && get_compra.address.number"
                      >
                        <v-icon :color="$theme.primary">
                          mdi-map-marker
                        </v-icon>
                        {{ get_compra.address.street }},
                        {{ get_compra.address.bairro }} Nº
                        {{ get_compra.address.number }}
                      </div>
                      <div v-else>
                        <v-icon size="15" :color="$theme.secondary">
                          mdi-map-marker
                        </v-icon>
                        <span
                          :style="`color: ${$theme.secondary};`"
                          class="fonte fonteMini"
                        >
                          clique aqui para informar o endereço
                        </span>
                      </div>
                    </div>
                  </v-flex>
                </div>
              </v-flex>

              <v-flex class="pt-3" xs12>
                <div class="expande-horizontal wrap">
                  <v-flex xs12 class="pa-3 pt-0">
                    <span
                      v-if="inputSearch !== ''"
                      class="fonte fonte-bold grey--text mt-3 mb-3"
                      >Encontrados -
                      {{ productSearchList.length }} encontrados</span
                    >
                  </v-flex>
                  <v-flex
                    class="pa-3"
                    :key="`product-search-${produto.nome}`"
                    v-show="inputSearch !== ''"
                    v-for="produto in productSearchList"
                    xs6
                  >
                    <v-card
                      @click.prevent="openModalAddProduct(produto)"
                      color="#F0F0F0"
                      min-height="200"
                      class="mx-auto animate__animated animate__flipInY"
                      max-width="344"
                      flat
                    >
                      <v-img
                        height="200"
                        :style="
                          `border-radius: 12px; background: ${$theme.secondary};`
                        "
                        :src="produto.imagens.length ? produto.imagens[0] : ''"
                      >
                        <div
                          class="expande-horizontal vitrify-mini column centraliza"
                          style="min-height: 40px;"
                        >
                          <h6 class="text-center white--text fonte fonte-bold">
                            {{ produto.nome }}
                          </h6>
                          <h4
                            class="text-center fonte fonte-bold"
                            style="color: #fff"
                          >
                            {{
                              $helper.formataSaldo(
                                produto.ultimo_preco_de_compra
                              )
                            }}
                          </h4>
                        </div>
                      </v-img>
                    </v-card>
                  </v-flex>
                  <div
                    :class="{
                      'max-height-list-products': $vuetify.breakpoint.lgAndUp
                    }"
                    class="expande-horizontal wrap"
                    style="overflow: auto;"
                  >
                    <v-flex xs12 class="px-3">
                      <v-list dense class="pa-0 ma-0">
                        <v-list-item
                          @click.prevent="openModalAddProduct(produto)"
                          v-show="
                            produto.disponivel && produto.type === 'Placa'
                          "
                          style="height: 30px;"
                          class="fonte px-0 mx-0 pl-3 pr-0 item-list-product animate__animated animate__fadeInUp"
                          v-for="(produto, i) in get_insumos.docs"
                          :key="`product-multiple-${produto.nome}-${i}`"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              style="font-size: 11pt;"
                              class=" text-uppercase"
                            >
                              {{ produto.nome }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <!-- <v-avatar rounded class="ml-2 mr-3">
                              <v-img
                                style="border-radius: 6px;"
                                height="60"
                                v-if="produto.imagens && produto.imagens.length"
                                :src="produto.imagens[0]"
                              ></v-img>
                              <img
                                contain
                                height="40"
                                v-else
                                src="img/logo_mini.png"
                              />
                            </v-avatar> -->
                            <!-- <v-icon size="18" color="#1173ff">
                              mdi-plus-circle-outline
                            </v-icon> -->
                            <v-btn text color="green">
                              {{
                                $helper.formataSaldo(
                                  produto.ultimo_preco_de_compra
                                )
                              }}
                              M²
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                  </div>
                </div>
              </v-flex>
            </div>
          </v-flex>
          <v-flex
            class="pa-6 pr-1 pt-0 pb-0"
            v-if="$vuetify.breakpoint.smAndUp"
            xs12
            md6
          >
            <v-card
              outlined
              :class="{ 'elevation-0': $vuetify.breakpoint.smAndDown }"
            >
              <v-flex xs12>
                <div
                  class="expande-horizontal pt-3 wrap"
                  style="background: #F0F0F0; min-height: 91vh;"
                >
                  <div class="expande-horizontal wrap">
                    <v-flex class="px-3 pt-0" xs12>
                      <v-autocomplete
                        class="fonte"
                        dense
                        prepend-inner-icon="mdi-account-circle-outline"
                        label="Cliente"
                        :items="get_clientes.docs"
                        item-text="nome"
                        return-object
                        hide-details
                        filled
                        @change="setTypeBuy('delivery')"
                        @click:append-outer="abre_modal_view_cliente"
                        append-outer-icon="mdi-account-plus-outline"
                        v-model="get_compra.cliente"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <div
                        @click="setTypeBuy('delivery')"
                        class="px-3 fonte"
                        style="cursor: pointer;"
                      >
                        <div
                          v-if="get_compra.address && get_compra.address.number"
                        >
                          <v-icon :color="$theme.primary">
                            mdi-map-marker
                          </v-icon>
                          {{ get_compra.address.street }},
                          {{ get_compra.address.bairro }} Nº
                          {{ get_compra.address.number }}
                        </div>
                        <div v-else>
                          <v-icon size="15" :color="$theme.secondary">
                            mdi-map-marker
                          </v-icon>
                          <span
                            :style="`color: ${$theme.secondary};`"
                            class="fonte fonteMini"
                          >
                            clique aqui para informar o endereço
                          </span>
                        </div>
                      </div>
                    </v-flex>
                  </div>
                  <!-- produtos adicionados -->
                  <v-flex xs12>
                    <div class="expande-horizontal pb-0">
                      <v-flex
                        v-if="get_compra && get_compra.produtos.length"
                        xs12
                        style="height: 430px; overflow: auto;"
                      >
                        <v-timeline dense align-top>
                          <v-timeline-item
                            fill-dot
                            icon="mdi-basket"
                            :icon-color="$theme.primary"
                            color="#333"
                            v-for="(produto, index) in get_compra.produtos"
                            :key="`product-list-${index}`"
                          >
                            <v-card color="#e5e5e5" class="elevation-1">
                              <v-list
                                class="pa-0 ma-0"
                                color="transparent"
                                dense
                              >
                                <v-list-item class="pa-0 ma-0 px-3  ">
                                  <v-list-item-content>
                                    <v-list-item-title class="fonte fonte-bold">
                                      {{
                                        produto.descricao_personalizada
                                          ? produto.descricao_personalizada
                                          : produto.nome
                                      }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                      class="fonte fonte-bold"
                                    >
                                      {{
                                        $helper.formataSaldo(
                                          produto.seller_price || 0
                                        )
                                      }}
                                      no total, feito com {{ produto.nome }}.
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                              <v-card-text style="background: #f2f2f2;">
                                <v-list
                                  class="fonte px-0 mx-0"
                                  dense
                                  nav
                                  color="transparent"
                                >
                                  <template
                                    v-for="(item, i) in produto.ficha_tecnica"
                                  >
                                    <v-list-item
                                      :key="`i-ficha-tecnica-${i}`"
                                      @click="
                                        openModalAddProduct(produto, `${i}`)
                                      "
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <span class="font-weight-bold"
                                            >{{ item.quantity }}
                                          </span>
                                          <span>
                                            {{ item.descricao }}
                                          </span>
                                          <span
                                            v-if="
                                              item.type !== 'Acabamento' &&
                                                $vuetify.breakpoint.smAndUp
                                            "
                                          >
                                            <v-chip
                                              :color="$theme.primary"
                                              x-small
                                              class="black--text"
                                            >
                                              {{
                                                parseFloat(
                                                  item.comprimento
                                                ).toFixed(2)
                                              }}
                                              x
                                              {{
                                                parseFloat(
                                                  item.largura
                                                ).toFixed(2)
                                              }}
                                              {{ verifyUnit(item) }}</v-chip
                                            >
                                          </span>
                                          <v-chip
                                            v-if="$vuetify.breakpoint.smAndUp"
                                            color="green"
                                            x-small
                                            rounded
                                            class="mx-1 white--text"
                                            >{{
                                              $helper.formataSaldo(
                                                item.total || 0
                                              )
                                            }}</v-chip
                                          >
                                          <v-chip
                                            :color="$theme.secondary"
                                            x-small
                                            class="white--text"
                                            >{{ item.type }}</v-chip
                                          >
                                        </v-list-item-title>
                                        <v-list-item-subtitle
                                          v-if="$vuetify.breakpoint.smAndDown"
                                        >
                                          <span
                                            v-if="
                                              item.type !== 'Acabamento' &&
                                                $vuetify.breakpoint.smAndDown
                                            "
                                          >
                                            <v-chip
                                              :color="$theme.primary"
                                              x-small
                                              class="black--text"
                                            >
                                              {{
                                                parseFloat(
                                                  item.comprimento
                                                ).toFixed(2)
                                              }}
                                              x
                                              {{
                                                parseFloat(
                                                  item.largura
                                                ).toFixed(2)
                                              }}
                                              {{ verifyUnit(item) }}</v-chip
                                            >
                                            <v-chip
                                              color="green"
                                              x-small
                                              rounded
                                              class="mx-1 white--text"
                                              >{{
                                                $helper.formataSaldo(
                                                  item.total || 0
                                                )
                                              }}</v-chip
                                            >
                                          </span>
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <div class="expande-horizontal column">
                                          <v-btn
                                            @click="removeItem(item, i)"
                                            x-small
                                            text
                                            color="grey"
                                            v-if="$vuetify.breakpoint.smAndUp"
                                            >remover</v-btn
                                          >
                                          <v-btn
                                            @click="removeItem(item, i)"
                                            x-small
                                            text
                                            color="grey"
                                            v-else
                                          >
                                            <v-icon>mdi-delete</v-icon>
                                          </v-btn>
                                        </div>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </template>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-timeline-item>
                        </v-timeline>
                        <v-simple-table
                          dark
                          v-if="false"
                          style="witdh: 100%; border-bottom: 1px dashed #1173ff; height: 440px; overflow: auto; background: #f0f0f0;"
                          dense
                        >
                          <template v-slot:default>
                            <thead style="background: #EBEBEB; height: 40px;">
                              <tr
                                style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                              >
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Quant
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Item
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Medida
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686;"
                                >
                                  Total
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                v-for="(item, _i) in get_compra.produtos"
                              >
                                <template
                                  v-for="(ficha, i) in item.ficha_tecnica"
                                >
                                  <tr
                                    :class="{
                                      'product-done': ficha.is_done
                                    }"
                                    @click="openModalAddProduct(item, `${_i}`)"
                                    style="background: #f0f0f0; border: none; cursor: pointer;"
                                    :key="`item-ficha-prod-${_i}-${i}`"
                                    v-show="
                                      ficha.quantity && ficha.quantity >= 1
                                    "
                                  >
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ ficha.quantity }}
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686;"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{
                                        item.descricao_personalizada
                                          ? item.descricao_personalizada
                                          : ficha.descricao
                                      }}
                                      - {{ item.nome }}
                                    </td>
                                    <td
                                      class="fonte fonte-bold"
                                      style="color: #333;"
                                      v-if="ficha.comprimento || ficha.largura"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ ficha.comprimento }} x
                                      {{ ficha.largura }}
                                      {{ verifyUnit(ficha) }}
                                    </td>
                                    <td
                                      class="fonte fonte-bold"
                                      style="color: #494949;"
                                      v-else
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ ficha.quantity }}
                                      {{ verifyUnit(ficha) }}
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{
                                        $helper.formataSaldo(
                                          parseFloat(ficha.total)
                                        )
                                      }}
                                    </td>
                                  </tr>
                                </template>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                      <div
                        v-else
                        style="height: 430px;"
                        class="expande-horizontal centraliza pb-6 column"
                      >
                        <img
                          width="160"
                          v-if="!getLoggedUser.is_patient"
                          style="opacity: 0.6; border-radius: 6px;"
                          :src="getLoggedUser.tenant[0].profileImage"
                          class="mb-3"
                        />

                        <!-- <v-icon color="grey" size="64">mdi-cart-check</v-icon> -->
                        <span class="fonte grey--text">
                          Seu caixa está livre no momento
                        </span>
                      </div>
                    </div>
                  </v-flex>

                  <div class="expande-horizontal wrap">
                    <v-flex class="py-3 pb-0" xs12>
                      <div class="pr-3 pb-0 expande-horizontal">
                        <v-spacer></v-spacer>
                        <v-btn
                          outlined
                          @click.prevent=""
                          :color="$theme.primary"
                          v-if="get_compra.desconto"
                          class="fonte text-capitalize"
                          text
                        >
                          <v-icon size="18" :color="$theme.primary"
                            >mdi-plus-circle-outline</v-icon
                          >
                          <span class="fonte fonte-bold">{{
                            $helper.formataSaldo(get_compra.subtotal || 0)
                          }}</span>
                          <span
                            v-if="false"
                            class="fonte ml-1 fonte-micro orange--text"
                            >{{ get_compra.produtos.length }} itens</span
                          >
                        </v-btn>
                        <v-btn
                          outlined
                          @click.prevent="$refs.modalDesconto.open()"
                          :color="$theme.primary"
                          class="fonte mr-1 ml-1 text-capitalize"
                          text
                        >
                          <v-icon size="18" :color="$theme.primary"
                            >mdi-minus-circle-outline</v-icon
                          >
                          <h4
                            v-if="get_compra.desconto"
                            class="fonte fonte-bold"
                          >
                            {{ $helper.formataSaldo(get_compra.desconto || 0) }}
                            <span
                              v-if="
                                get_compra.desconto &&
                                  get_compra.desconto_type === 'percent'
                              "
                              class="green--text"
                            >
                              ( {{ get_compra.desconto_applyed }}% )
                            </span>
                          </h4>
                        </v-btn>
                        <v-btn
                          outlined
                          @click.prevent="$refs.modalDesconto.open()"
                          :color="$theme.primary"
                          class="fonte text-capitalize"
                          text
                        >
                          <v-icon :color="$theme.third">mdi-equal</v-icon>
                          <!-- <span class="fonte fonte-bold grey--text">Total:</span> -->
                          <span class="fonte fonte-bold">{{
                            $helper.formataSaldo(get_compra.preco_total || 0)
                          }}</span>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="expande-horizontal">
                        <v-flex class="pa-3 pb-0 pt-0" xs3>
                          <v-card
                            v-if="!paymentIsOk"
                            height="40"
                            hover
                            :color="$theme.primary"
                            @click.prevent="
                              limparComanda({ confirmation: false })
                            "
                          >
                            <v-flex xs12>
                              <div
                                class="fonte column black--text expande-horizontal centraliza"
                                style="height: 40px;"
                              >
                                Limpar
                                <!-- <span
                                  class="fonte white--text fonte-micro"
                                  style="font-weight: 300"
                                >
                                  {{
                                    $helper.formataSaldo(
                                      get_compra.preco_total || 0
                                    )
                                  }}
                                </span> -->
                              </div>
                            </v-flex>
                          </v-card>
                          <v-card v-else height="60" :color="'grey'" dark>
                            <v-flex xs12>
                              <div
                                class="fonte column fonte-bold expande-horizontal centraliza"
                                style="height: 60px;"
                              >
                                Compra Finalizada
                              </div>
                            </v-flex>
                          </v-card>
                        </v-flex>
                        <v-flex class="pa-3 pt-0 pb-0" xs9>
                          <v-card
                            height="40"
                            hover
                            :color="$theme.secondary"
                            @click.prevent="initSendToManager"
                          >
                            <v-flex xs12>
                              <div
                                class="fonte black--text expande-horizontal centraliza"
                                style="height: 40px;"
                              >
                                Gerar orçamento
                              </div>
                            </v-flex>
                          </v-card>
                        </v-flex>
                      </div>
                    </v-flex>
                  </div>
                </div>
              </v-flex>
            </v-card>
          </v-flex>
        </v-card>
      </v-flex>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="$vuetify.breakpoint.lgAndUp"
      filename="receipt"
      :manual-pagination="true"
      pdf-format="a4"
      :pdf-quality="2"
      pdf-content-width="100"
      pdf-orientation="portrait"
      @progress="() => {}"
      @hasStartedGeneration="() => {}"
      @hasDownloaded="() => {}"
      ref="html2Pdf"
    >
      <div slot="pdf-content">
        <section class="pdf-item fonte">
          <div
            id="print-html"
            style="padding: 18px; display: flex; flex-direction: column; flex-wrap: wrap;"
          >
            <div
              style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
            >
              <div
                style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
              >
                <!-- <img
                  :src="getLoggedUser.tenant[0].profileImage"
                  style="height: 50px"
                  alt=""
                /> -->
              </div>
              <div
                style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
              >
                <strong style="text-align: center; font-size: 9pt;">
                  D'STONES MARMORES E GRANITOS
                </strong>
                <span
                  style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
                >
                  CNPJ: 38.182.792/0001-35 IE: 03.023.731-9
                </span>
                <span
                  style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
                >
                  ROD.JK 6431 VALE VERDE, MACAPÁ CEP:68.903-419
                </span>
                <span
                  style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                >
                  FONE: 9681342091
                </span>
              </div>
              <div
                style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
              >
                <qrcode-vue
                  :value="
                    `https://dstones.web.app/#/acompanhar/${lastSell._id}`
                  "
                  :size="100"
                  level="H"
                />
              </div>
            </div>
            <div
              class="mt-3"
              style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
            >
              <h3>PROPOSTA ORÇAMENTARIA</h3>
              <!-- <span>Número: #{{ lastSell.id_transacao }}</span> -->
            </div>
            <div
              style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
            ></div>
            <div
              style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
            >
              <div v-if="lastSell.compra.cliente">
                <strong style="margin-right: 6px; margin-bottom: 6px;">
                  Cliente:
                </strong>
                {{ lastSell.compra.cliente.nome }}
              </div>
              <div v-if="lastSell.compra && lastSell.compra.address">
                <strong style="margin-right: 6px;">
                  Endereço:
                </strong>
                {{ lastSell.compra.address.street }},
                {{ lastSell.compra.address.bairro }} Nº
                {{ lastSell.compra.address.number }}
              </div>
            </div>
            <div style="margin-top: 6px; margin-bottom: 6px; padding: 12px;">
              <table dark style="min-witdh: 100%; background: #f0f0f0;" dense>
                <template>
                  <thead>
                    <tr style="background: #EBEBEB; border: 1px solid #f0f0f0;">
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686; border-right: 1px solid #D2D4CF;"
                      >
                        Quant
                      </td>
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686; border-right: 1px solid #D2D4CF;"
                      >
                        Item
                      </td>
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686; border-right: 1px solid #D2D4CF;"
                      >
                        Medida
                      </td>
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686;"
                      >
                        Total
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, _i) in lastSell.compra.produtos">
                      <template v-for="(ficha, i) in item.ficha_tecnica">
                        <tr
                          @click="openModalAddProduct(item, `${_i}`)"
                          :class="{
                            'product-done': ficha.is_done
                          }"
                          style="background: #f0f0f0; border: none;"
                          :key="`item-ficha-prod-${_i}-${i}`"
                          v-show="ficha.quantity && ficha.quantity >= 1"
                        >
                          <td
                            class="fonte fonte-micro"
                            style="color: #878686"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ ficha.quantity }}
                          </td>
                          <td
                            class="fonte fonte-micro"
                            style="color: #878686;"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ ficha.descricao ? ficha.descricao : "" }} -
                            {{ item.nome }}
                          </td>
                          <td
                            class="fonte fonte-bold"
                            style="color: #494949;"
                            v-if="ficha.comprimento || ficha.largura"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ ficha.comprimento }} x {{ ficha.largura }}
                            {{ verifyUnit(ficha) }}
                          </td>
                          <td
                            class="fonte fonte-bold"
                            style="color: #494949;"
                            v-else
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ verifyUnit(ficha) }}
                          </td>
                          <td
                            class="fonte fonte-micro"
                            style="color: #878686"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ $helper.formataSaldo(parseFloat(ficha.total)) }}
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </template>
              </table>
            </div>
            <div
              style="padding: 3px;padding-right: 6px; padding-left: 6px;display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
            >
              <span>(+) SUBTOTAL</span>
              <span
                style="background: #1173ff; color: #fff; border-radius: 3px; padding: 3px; padding-right: 6px; padding-left: 6px;`"
              >
                {{ $helper.formataSaldo(lastSell.compra.subtotal) }}
              </span>
            </div>

            <div
              style="padding: 3px;padding-right: 6px; padding-left: 6px;display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
            >
              <span>(+) ACABAMENTO</span>
              <span>
                {{ $helper.formataSaldo(lastSell.compra.acabamento || 0) }}
                <!-- {{ payment_sys_config.finishing.tax_porcentage }}% -->
              </span>
            </div>

            <div
              style="padding: 3px;padding-right: 6px; padding-left: 6px;display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
            >
              <span>(-) DESCONTO</span>
              <v-spacer></v-spacer>
              <span>
                {{ $helper.formataSaldo(lastSell.compra.desconto || 0) }}
                (
                {{
                  lastSell.compra.desconto_type &&
                  lastSell.compra.desconto_type === "percent"
                    ? `${lastSell.compra.desconto_applyed} %`
                    : ""
                }}
                )
              </span>
            </div>

            <div
              style="padding: 3px;padding-right: 6px; padding-left: 6px;font-weight: bold; justify-content: space-between; margin-top: 24px; width: 100%; display: flex;"
            >
              <span>(=) TOTAL DA COMPRA: </span>
              <span
                style="background: #1173ff; color: #fff; border-radius: 3px; padding: 3px; padding-right: 6px; padding-left: 6px;"
              >
                {{ $helper.formataSaldo(lastSell.compra.preco_total) }}
              </span>
            </div>
            <div
              style="margin-top: 36px; display: flex; width: 100%; justify-content: center;"
            >
              _________________________________________________________________
            </div>
            <div
              style="margin-bottom: 36px; display: flex; width: 100%; justify-content: center;"
            >
              {{ lastSell.compra.cliente.nome }}
            </div>
            <div
              style="margin-bottom: 12px; justify-content: center; align-items: center; display: flex; width: 100%;"
            >
              <v-list two-line dense class="pa-0 ma-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Quem vendeu
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ lastSell.caixa.funcionario.nome }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Documento #{{ lastSell.id_transacao }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Emissão em
                      {{
                        moment(lastSell.createdAt).format("DD/MM/YYYY HH:mm")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Aviso
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      * Mármores e granitos por sua própria natureza são
                      sujeitos a variações de cores e tonalidades.
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      * Não fazemos instalação
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </section>
      </div>
    </vue-html2pdf>
    <ModalViewAbertura />
    <ModalViewFechamento ref="modalViewFechaento" />
    <ModalAcrescimo ref="modalAcrescimo" />
    <ModalDesconto ref="modalDesconto" />
    <ModalFechamento
      :payment_sys_config="payment_sys_config"
      :sendToPreparing="finishSell"
      ref="modalFechamento"
    />
    <ModalSendToManager
      :payment_sys_config="payment_sys_config"
      :initSale="finishSell"
      ref="modalSendToManager"
    />
    <ModalAddProduct
      :addProductToCart="addProductToCart"
      :removeProductOfCart="removeProductOfCart"
      :products="get_insumos.docs"
      :taxFinishing="parseInt(payment_sys_config.finishing.tax_porcentage)"
      ref="modalAddProduct"
    />
    <ModalChangeTable
      :mesas_disponiveis="mesas_disponiveis"
      :selecionarMesa="selecionarMesa"
      ref="modalChangeTable"
    />
    <div
      v-if="
        $vuetify.breakpoint.smAndDown && get_caixa.status_atual === 'aberto'
      "
      style="position: fixed; bottom: 0px; left: 0; right: 0; z-index: 5;"
      class="expande-horizontal centraliza"
    >
      <v-flex class="px-0" xs12 md8>
        <v-card
          :color="$theme.primary"
          class="fonte  expande-horizontal elevation-0"
        >
          <v-expansion-panels
            class="elevation-0"
            flat
            style="padding: 0px; background: red;"
            :color="$theme.primary"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                class="fonte"
                expand-icon="mdi-menu-down"
                ref="expansionPanelHeader"
              >
                <v-list color="transparent" class="pa-0 ma-0" dense>
                  <v-list-item
                    v-if="saleStage === 0"
                    :style="`background-color: #333;`"
                    class="pa-0 ma-0 item-list-product"
                  >
                    <v-icon class="mx-2" :color="$theme.primary"
                      >mdi-basket-outline</v-icon
                    >
                    <v-list-item-content :style="`color: ${$theme.primary}`">
                      <v-list-item-title class="font-weight-bold ">
                        {{ get_compra.produtos.length }} produtos, total de
                        {{ $helper.formataSaldo(get_compra.preco_total || 0) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro grey--text">
                        Visualize os produtos
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="saleStage === 1" class="pa-0 ma-0">
                    <v-avatar class="pa-0 ma-0 mr-2" color="red">
                      <v-icon color="#f2f2f2">mdi-map-marker-outline</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: red;"
                        class="font-weight-bold"
                      >
                        Endereço de entrega
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro">
                        Escolha ou cadastre um abaixo
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="saleStage === 2" class="pa-0 ma-0">
                    <v-avatar class="pa-0 ma-0 mr-2" color="red">
                      <v-icon size="36" color="#f2f2f2"
                        >mdi-information-variant</v-icon
                      >
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: red;"
                        class="font-weight-bold"
                      >
                        CONFIRME OS DETALHES
                      </v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro">
                        confira seu pedido e finalize
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex class="pt-2" xs12>
                  <v-window :touchless="true" v-model="saleStage">
                    <v-window-item :key="0">
                      <div
                        v-if="get_compra.produtos.length"
                        style="height: 75vh; "
                        class="expande-horizontal pa-3 pt-0 pr-0 pl-0 column"
                      >
                        <v-list
                          v-for="(produto, index) in get_compra.produtos"
                          :key="`product-list-${index}`"
                          class="fonte px-0 mx-0"
                          dense
                          nav
                          color="transparent"
                        >
                          <v-list-item>
                            <v-avatar size="31" class="mr-3" color="#333">
                              <v-icon size="19" :color="$theme.primary"
                                >mdi-basket</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span>
                                  {{
                                    produto.descricao_personalizada
                                      ? produto.descricao_personalizada
                                      : produto.nome
                                  }}
                                </span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <template v-for="(item, i) in produto.ficha_tecnica">
                            <v-list-item
                              :key="`i-ficha-tecnica-${i}`"
                              @click="openModalAddProduct(produto, `${i}`)"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span class="font-weight-bold"
                                    >{{ item.quantity }}
                                  </span>
                                  <span>
                                    {{ item.descricao }}
                                  </span>
                                  <span
                                    v-if="
                                      item.type !== 'Acabamento' &&
                                        $vuetify.breakpoint.smAndUp
                                    "
                                  >
                                    <v-chip
                                      :color="$theme.primary"
                                      x-small
                                      class="black--text"
                                    >
                                      {{
                                        parseFloat(item.comprimento).toFixed(2)
                                      }}
                                      x
                                      {{ parseFloat(item.largura).toFixed(2) }}
                                      {{ verifyUnit(item) }}</v-chip
                                    >
                                  </span>
                                  <v-chip
                                    v-if="$vuetify.breakpoint.smAndUp"
                                    color="green"
                                    x-small
                                    rounded
                                    class="mx-1 white--text"
                                    >{{
                                      $helper.formataSaldo(item.total || 0)
                                    }}</v-chip
                                  >
                                  <v-chip
                                    :color="$theme.secondary"
                                    x-small
                                    class="white--text"
                                    >{{ item.type }}</v-chip
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  v-if="$vuetify.breakpoint.smAndDown"
                                >
                                  <span
                                    v-if="
                                      item.type !== 'Acabamento' &&
                                        $vuetify.breakpoint.smAndDown
                                    "
                                  >
                                    <v-chip
                                      :color="$theme.primary"
                                      x-small
                                      class="black--text"
                                    >
                                      {{
                                        parseFloat(item.comprimento).toFixed(2)
                                      }}
                                      x
                                      {{ parseFloat(item.largura).toFixed(2) }}
                                      {{ verifyUnit(item) }}</v-chip
                                    >
                                    <v-chip
                                      color="green"
                                      x-small
                                      rounded
                                      class="mx-1 white--text"
                                      >{{
                                        $helper.formataSaldo(item.total || 0)
                                      }}</v-chip
                                    >
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <div class="expande-horizontal column">
                                  <v-btn
                                    @click="removeItem(item, i)"
                                    x-small
                                    text
                                    color="grey"
                                    v-if="$vuetify.breakpoint.smAndUp"
                                    >remover</v-btn
                                  >
                                  <v-btn
                                    @click="removeItem(item, i)"
                                    x-small
                                    text
                                    color="grey"
                                    v-else
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </div>
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                        <v-spacer></v-spacer>
                        <div class="pt-3 expande-horizontal column">
                          <h3 class="fonteMini pl-0 pa-3 grey--text">
                            opções:
                          </h3>
                          <v-btn
                            v-show="
                              get_compra.status_do_pagamento !== 'pago' &&
                                !get_compra._id
                            "
                            v-if="get_compra.produtos.length > 0"
                            class="black--text text-capitalize font-weight-bold elevation-0"
                            @click.prevent="initSendToManager"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Gerar orçamento
                            <v-spacer></v-spacer>
                            <v-icon class="ml-3">mdi-check</v-icon>
                          </v-btn>
                          <v-btn
                            v-show="get_compra._id"
                            v-if="get_compra.produtos.length > 0"
                            class="black--text text-capitalize mt-2 font-weight-bold elevation-0"
                            @click.prevent="initSendToManager"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Atualizar somente
                            <v-spacer></v-spacer>
                            <v-icon class="ml-3">mdi-check</v-icon>
                          </v-btn>
                          <!-- <v-btn
                            v-if="
                              get_compra.status_do_pagamento !== 'pago' &&
                                get_compra._id
                            "
                            class="black--text text-capitalize mt-2 font-weight-bold elevation-0"
                            @click.prevent="initPaymentId"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Pagar
                            <v-spacer></v-spacer>
                            <v-icon class="ml-10">mdi-check-all</v-icon>
                          </v-btn> -->
                          <v-btn
                            v-if="get_compra.status_do_pagamento === 'pago'"
                            class="black--text text-capitalize mt-2 font-weight-bold elevation-0"
                            @click.prevent="updateStatusToFinish"
                            :color="$theme.primary"
                            dark
                            block
                            large
                          >
                            Finalizar pedido
                            <v-spacer></v-spacer>
                            <v-icon class="ml-10">mdi-check-all</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div
                        v-else
                        style="height: 62vh;"
                        class="expande-horizontal centraliza pa-3 pr-0 pl-0 column"
                      >
                        <v-flex xs10 md4>
                          <LottieAnimation
                            class="pa-6"
                            ref="anim2"
                            :animationData="
                              require('@/apps/shared/assets/empty_sales.json')
                            "
                          />
                        </v-flex>
                        <v-flex xs10 md10>
                          <div class="expande-horizontal fonte grey--text">
                            A cesta está vazia
                          </div>
                        </v-flex>
                        <v-flex xs10 md10>
                          <div
                            class="expande-horizontal centraliza fonte grey--text"
                          >
                            <v-btn
                              @click="closeExpansionPanelCheckout"
                              class="font-weight-bold black--text elevation-0"
                              :color="$theme.primary"
                              rounded
                              dark
                              >Adicione itens
                              <v-icon class="ml-2"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-flex>
                      </div>
                    </v-window-item>
                    <v-window-item :key="1">
                      <div class="expande-horizontal pa-0 ma-0">
                        <ChangeDelivery
                          :ok="address => setAddress(address)"
                          :cancel="() => (saleStage = 0)"
                          :stepBack="() => (saleStage = 0)"
                        />
                      </div>
                    </v-window-item>
                    <v-window-item :key="2">
                      <div
                        class="fonte expande-horizontal column"
                        style="height: 61vh;"
                      >
                        <v-list three-line class="pa-0 ma-0" dense>
                          <v-list-item
                            @click="saleStage = 0"
                            v-if="get_compra.address"
                            class="mb-1 pl-0"
                          >
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-basket-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Revisar</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Clique aqui caso queira remover ou adicionar
                                produtos</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            @click="saleStage = 1"
                            v-if="get_compra.address"
                            class="mb-1 pl-0"
                          >
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-map-marker-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Endereço</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                get_compra.address.formatted_address
                              }}</v-list-item-subtitle>
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-moped-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Taxa de entrega</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                R$ 0,00</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-clock-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Tempo de entrega</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                40 - 60 minutos</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mb-1 pl-0">
                            <v-avatar class="mr-3 mb-10" color="white">
                              <v-icon :color="$theme.primary"
                                >mdi-clock-outline</v-icon
                              >
                            </v-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="fonte font-weight-bold"
                                >Forma de pagamento</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                ??????</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                ><span class="white--text"
                                  >.</span
                                ></v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-spacer></v-spacer>
                          <!-- <v-list-item class="">
                            <v-btn
                              small
                              text
                              :color="$theme.primary"
                              dark
                              @click="saleStage = 0"
                              >
                                <v-icon size="18" class="mr-3">mdi-chevron-left</v-icon>
                              Revisar pedido</v-btn
                            >
                          </v-list-item> -->
                        </v-list>
                        <v-spacer></v-spacer>
                        <!-- <v-btn
                          :color="$theme.primary"
                          dark
                          rounded
                          class="fonte elevation-0 font-weight-bold"
                          @click="realizarPedido"
                          >Realizar pedido</v-btn
                        > -->
                      </div>
                    </v-window-item>
                  </v-window>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-flex>
    </div>
    <div
      v-if="false"
      class="expande-horizontal"
      style="position: absolute; left: 9999px;"
    >
      <div
        id="print-html"
        style="font-family: 'Montserrat', sans-serif;padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
      >
        <div
          style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
        >
          <div
            style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
          >
            <!-- <img
              :src="getLoggedUser.tenant[0].profileImage"
              style="height: 70px"
              alt=""
            /> -->
          </div>
          <div
            style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
          >
            <strong style="text-align: center;">
              D'STONES MARMORES E GRANITOS
            </strong>
            <span
              style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
            >
              CNPJ: 38.182.792/0001-35 IE: 03.023.731-9
            </span>
            <span style="font-size: 9pt;text-align: center; flex-wrap: nowrap;">
              ROD.JK 6431 VALE VERDE, MACAPÁ CEP:68.903-419
            </span>
            <span style="font-size: 9pt;text-align: center;flex-wrap: nowrap;">
              FONE: 9681342091
            </span>
            <!-- <span style="font-size: 9pt;">
              Realizado
              {{ moment(lastSell.createdAt).format("DD/MM/YYYY HH:mm") }}
            </span> -->
          </div>
          <div style="display: flex; width: 100%; justify-content: center;">
            <span v-if="receipt.show_header_text.active">
              {{ receipt.show_header_text.text }}
            </span>
            <span v-else>
              BALCÕES, SOLEIRAS, PEITORIS, MESAS, CENTROS, TÚMULOS, ETC...
            </span>
          </div>
        </div>
        <div
          style=";display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
        >
          <h3>PROPOSTA ORÇAMENTARIA</h3>
          <!-- <span>Número: #{{ lastSell.id_transacao }}</span> -->
        </div>
        <div
          style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
        ></div>
        <div
          v-if="receipt.show_client_details && lastSell.compra.cliente"
          style="display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <div>
            <strong style="margin-right: 6px;">
              Cliente:
            </strong>
            {{ lastSell.compra.cliente.name }}
          </div>
        </div>
        <div
          style="display: flex; margin-top: 12px; margin-bottom: 12px; flex-wrap: wrap; width: 100%;height: 1px; border-bottom: 1px #333 dashed;"
        ></div>
        <div>
          <table dark style="min-witdh: 100%; background: #f0f0f0;" dense>
            <template>
              <thead>
                <tr style="background: #EBEBEB; border: 1px solid #f0f0f0;">
                  <td
                    class="font-weight-bold fonte"
                    style="color: #878686; border-right: 1px solid #D2D4CF;"
                  >
                    Quant
                  </td>
                  <td
                    class="font-weight-bold fonte"
                    style="color: #878686; border-right: 1px solid #D2D4CF;"
                  >
                    Item
                  </td>
                  <td
                    class="font-weight-bold fonte"
                    style="color: #878686; border-right: 1px solid #D2D4CF;"
                  >
                    Medida
                  </td>
                  <td class="font-weight-bold fonte" style="color: #878686;">
                    Total
                  </td>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, i) in lastSell.compra.produtos">
                  <tr
                    class="mt-3"
                    :class="{
                      'product-done': item.is_done
                    }"
                    @click="openModalAddProduct(item, `${i}`)"
                    style="background: #f0f0f0; cursor: pointer; border: 1px solid #f0f0f0;"
                    :key="`product-in-list-${i}`"
                  >
                    <td
                      class="fonte fonte-bold"
                      style="color: #878686; border-top: 1px dashed #D2D4CF;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ item.quantity }}
                    </td>
                    <td
                      class="fonte fonte-bold"
                      style="color: #494949; border-top: 1px dashed #D2D4CF;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ item.nome }}
                    </td>
                    <td
                      class="fonte fonte-bold"
                      style="color: #494949; border-top: 1px dashed #D2D4CF;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      <!-- {{ item.comprimento ? item.comprimento : '' }} x {{ item.largura ? item.largura : '' }} -->
                    </td>
                    <td
                      class="fonte green--text fonte-bold"
                      style="color: #878686; border-top: 1px dashed #D2D4CF;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ $helper.formataSaldo(item.seller_price) }}
                    </td>
                  </tr>
                  <template v-for="(ficha, i) in item.ficha_tecnica">
                    <tr
                      :class="{
                        'product-done': ficha.is_done
                      }"
                      style="background: #f0f0f0; border: none;"
                      :key="`item-ficha-prod-${_i}-${i}`"
                      v-show="ficha.quantity && ficha.quantity >= 1"
                    >
                      <td
                        class="fonte fonte-micro"
                        style="color: #878686"
                        @click.prevent="abre_modal_view_caixa(item)"
                      >
                        {{ ficha.quantity }}
                      </td>
                      <td
                        class="fonte fonte-micro"
                        style="color: #878686;"
                        @click.prevent="abre_modal_view_caixa(item)"
                      >
                        {{ ficha.descricao ? ficha.descricao : "" }}
                      </td>
                      <td
                        class="fonte fonte-bold"
                        style="color: #494949;"
                        v-if="ficha.comprimento || ficha.largura"
                        @click.prevent="abre_modal_view_caixa(item)"
                      >
                        {{ ficha.comprimento }} x {{ ficha.largura }}
                        {{ verifyUnit(ficha) }}
                      </td>
                      <td
                        class="fonte fonte-bold"
                        style="color: #494949;"
                        v-else
                        @click.prevent="abre_modal_view_caixa(item)"
                      >
                        {{ verifyUnit(ficha) }}
                      </td>
                      <td
                        class="fonte fonte-micro"
                        style="color: #878686"
                        @click.prevent="abre_modal_view_caixa(item)"
                      >
                        {{
                          $helper.formataSaldo(
                            ficha.quantity * parseFloat(ficha.total)
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                  <!-- <tr
                    light
                    v-show="item.obs"
                    style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                    :key="(i + 1) / 6"
                    xs12
                  >
                    <td class="fonte fonte-micro" style="color: #878686">
                      Obs:
                    </td>
                    <td class="fonte fonte-micro" style="color: #878686">
                      {{ item.obs }}
                    </td>
                    <td></td>
                  </tr> -->
                </template>
              </tbody>
            </template>
          </table>
        </div>
        <div
          style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
        ></div>
        <div
          style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
        >
          <span>(+) SUBTOTAL</span>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.subtotal) }}
          </span>
        </div>

        <div
          style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
        >
          <span>(+) ACABAMENTO</span>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.acabamento || 0) }}
          </span>
        </div>

        <div
          style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
        >
          <span>(-) DESCONTO</span>
          <v-spacer></v-spacer>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.desconto || 0) }}
          </span>
        </div>

        <div
          style="font-weight: bold; justify-content: space-between; margin-top: 24px; width: 100%; display: flex;"
        >
          <span>(=) TOTAL DA COMPRA: </span>
          <span>
            {{ $helper.formataSaldo(lastSell.compra.preco_total) }}
          </span>
        </div>
        <div
          style="margin-bottom: 12px; margin-top: 12px; width: 100%; display: flex; height: 1px; border-bottom: 1px #333 dashed;"
        ></div>
        <div
          style="margin-bottom: 12px; justify-content: center; align-items: center; display: flex; width: 100%;"
        >
          <span
            style="text-align: center;"
            v-if="receipt.show_footer_text.active"
          >
            {{ receipt.show_footer_text.text }}
          </span>
        </div>
        <div
          style="margin-bottom: 12px; justify-content: center; align-items: center; display: flex; width: 100%;"
        >
          <span style="text-align: center;">
            Aviso: Mármores e granitos por sua própria natureza são sujeitos a
            variações de cores e tonalidades
          </span>
        </div>
        <!-- <div
          v-if="pedidoRealizadoPeloMenuRocket"
          style="display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <span style="text-align: center; font-size: 9pt"
            >Pedido realizado pelo</span
          >
          <img
            style="height: 27px; width: 108px;"
            src="img/logo_menu_black.png"
          />
        </div> -->
        <!-- <div
          style="display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <img style="height: 6px; width: 54px;" src="img/logo_black.png" />
        </div> -->
        <!-- <div
          style="font-size: 9pt;display: flex; flex-direction: column; flex-wrap: wrap; width: 100%;justify-content: center; align-items: center;"
        >
          www.sysrocket.com.br
        </div> -->
      </div>
    </div>
    <ModalCreateClient />
    <ModalChangeDelivery ref="modalChangeDelivery" />
    <CreateTransaction
      ref="lancamentoDeCaixa"
      @to_pay="$refs.createInvoice.open('to_pay')"
      @to_receive="$refs.createInvoice.open('to_receive')"
      @stock_down="$refs.createStock.open('stock_down')"
      @stock_up="$refs.createStock.open('stock_up')"
    />
    <CreateInvoice ref="createInvoice" />
    <CreateStock :materials="materialStockableList" ref="createStock" />
    <ViewSale :receipt="receipt" :payment_sys_config="payment_sys_config" />
    <TriagemModal v-model="modalTriagem" @input="initSendToManager" />
  </layout>
</template>

<script>
import ModalViewAbertura from "../components/modalViewAbertura.vue";
import ModalViewFechamento from "../components/modalViewFechamento.vue";
import ModalAcrescimo from "../components/Acrescimo.vue";
import ModalDesconto from "../components/Desconto.vue";
import ModalFechamento from "../components/ModalFechamento.vue";
import ModalSendToManager from "../components/ModalSendToManager.vue";
import ModalAddProduct from "./components/ModalAddProduct.vue";
import ModalChangeTable from "./components/ModalChangeTable.vue";
import ModalChangeDelivery from "./components/ModalChangeDelivery.vue";
import ModalCreateClient from "@/apps/44#Clientes/components/modalView.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/main.js";
import printHtmlToPDF from "print-html-to-pdf";
import Printerr from "../components/printer.vue";
import QrcodeVue from "qrcode.vue";
import CreateTransaction from "./CreateTransaction.vue";
import CreateInvoice from "@/apps/46#Invoices/components/ModalCreate.vue";
import CreateStock from "@/apps/Insumos/components/ModalCreateStock.vue";
import ViewSale from "@/apps/GestorDePedido/components/ViewSale";
import TriagemModal from "@/apps/27#Caixas/components/TriagemAgendadaModal";

export default {
  data() {
    return {
      saleStage: 0,
      modalTriagem: {},
      moment: moment,
      printHtmlToPDF: printHtmlToPDF,
      allCategories: true,
      card: false,
      modalAcrescimo: false,
      modalDesconto: false,
      searchCliente: "",
      inputSearch: "",
      mesas_disponiveis: [],
      productSearchList: [],
      categoria_escolhida: {
        preco_multiplo: []
      },
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "atendimento",
          path: "/atendimento"
        }
      ],
      options: [
        {
          icon: "mdi-close",
          nome: "Fechar Caixa",
          action: () => this.abre_modal_fechamento_caixa()
        },
        {
          icon: "mdi-cash-register",
          nome: "Lançamento de Caixa",
          action: () => this.verifyIfBoxIsOpen()
        }
      ],
      lastSell: {
        compra: {
          produtos: [],
          cliente: {},
          address: {}
        },
        caixa: {
          funcionario: {}
        }
      },
      payment_sys_config: {
        finishing: { tax_porcentage: 30 }
      },
      receipt: {
        show_my_address: { active: true },
        show_my_logo: { active: true },
        show_my_qrcode: { active: true },
        show_client_details: { active: true },
        show_header_text: { active: true, text: "" },
        show_footer_text: { active: true, text: "" }
      },
      get_insumos: {
        docs: []
      }
    };
  },
  components: {
    ModalViewAbertura,
    ModalViewFechamento,
    ModalDesconto,
    ModalAcrescimo,
    ModalFechamento,
    ModalAddProduct,
    ModalChangeTable,
    ModalChangeDelivery,
    ModalSendToManager,
    Printerr,
    ModalCreateClient,
    QrcodeVue,
    CreateTransaction,
    CreateInvoice,
    CreateStock,
    ViewSale,
    TriagemModal
  },
  watch: {
    inputSearch(val) {
      if (val) {
        this.search();
      } else {
        this.categoria_escolhida = {
          preco_multiplo: []
        };
      }
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    // logoBase64() {
    //   const selectedFile = this.getLoggedUser.tenant[0].profileImage;
    //   let imgBase64 = "";
    //   if (selectedFile) {
    //     const reader = new FileReader();

    //     reader.onload = function(e) {
    //       const base64String = e.target.result;
    //       imgBase64 = base64String; // Lê o arquivo como uma URL de dados (base64)
    //       return imgBase64;
    //     };

    //     imgBase64 = reader.readAsDataURL(selectedFile); // Lê o arquivo como uma URL de dados (base64)
    //     return imgBase64;
    //   }
    //   return imgBase64;
    // },
    materialStockableList() {
      const materials = this.get_insumos.docs.filter(
        product => product.type === "Material"
      );
      return materials;
    },
    pedidoRealizadoPeloMenuRocket() {
      return true;
    },
    ...mapGetters([
      "get_caixa",
      "get_produtos",
      "get_produto",
      "get_compra",
      "get_produtocategorias",
      "get_produtocategorias_filtros",
      "get_produtocategoria",
      "get_produtos_filtros",
      "get_caixas",
      "get_clientes",
      "get_clientes_filtros",
      "get_cliente",
      "get_caixas_filtros",
      "getLoggedUser"
    ]),
    paymentIsOk() {
      if (this.get_compra.status_do_pagamento) {
        if (this.get_compra.status_do_pagamento.toLowerCase() === "pendente") {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    verifyIfBoxIsOpen() {
      if (this.get_caixa.status_atual === "fechado") {
        this.createGlobalMessage({
          type: "info",
          message: "Você precisa abrir um caixa para realizar lançamentos!",
          timeout: 6000
        });
      } else {
        this.$refs.lancamentoDeCaixa.open();
      }
    },
    verifyUnit(item) {
      if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) <= 0.1
      ) {
        return "ML";
      } else if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) > 0.1
      ) {
        return "M²";
      } else if (item.type === "Acabamento") {
        return "UND";
      }
    },
    selecionarMesa(mesa) {
      this.get_compra.mesa = mesa;
      this.$refs.modalChangeTable.close();
      this.$forceUpdate();
    },
    attachOpenModalAddProduct() {
      if (this.productSearchList.length) {
        this.$refs.modalAddProduct.open(this.productSearchList[0]);
      }
    },
    openModalAddProduct(produto, withIndex) {
      if (produto.is_done) {
        this.createGlobalMessage({
          type: "info",
          message:
            "Este produto já foi marcado como feito no gestor, não pode ser editado ou removido!",
          timeout: 6000
        });
      } else {
        let prod = produto;
        prod.indexProd = withIndex ? parseInt(withIndex) : false;
        this.$refs.modalAddProduct.open(prod);
      }
    },
    setTypeBuy(tipo) {
      this.get_compra.tipo = "";
      this.get_compra.tipo = tipo;
      if (tipo === "mesa") {
        this.$refs.modalChangeTable.open();
      }
      if (tipo === "balcao") {
        delete this.get_compra.mesa;
      }
      if (tipo === "delivery") {
        this.$refs.modalChangeDelivery.open();
        delete this.get_compra.mesa;
      }
      this.$forceUpdate();
    },
    setCategory(categoria) {
      if (categoria) {
        this.categoria_escolhida = categoria;
        this.allCategories = false;
      } else {
        this.allCategories = true;
        this.categoria_escolhida = {
          preco_multiplo: []
        };
      }
    },
    ...mapActions([
      "createGlobalMessage",
      "add_produto_a_compra",
      "salvar_compra",
      "finalizar_compra",
      "abre_modal_view_caixa",
      "abre_modal_abertura_caixa",
      "abre_modal_fechamento_caixa",
      "calcula_valor_total_da_compra",
      "listar_caixas",
      "listar_clientes",
      "listar_produtos",
      "listar_funcionarios",
      "listar_insumos",
      "listar_produtocategorias",
      "remove_produto_da_compra",
      "createConfirmAction",
      "abre_modal_view_cliente"
    ]),
    finishSell() {
      this.finalizar_compra();
    },
    printReceipt(compra) {
      this.lastSell = compra;
      this.$forceUpdate();
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 300);
      // setTimeout(() => {
      //   var mywindow = window.open("", "PRINT", "height=400,width=600");

      //   mywindow.document.write(
      //     "<html><head><title>" + document.title + "</title>"
      //   );
      //   mywindow.document.write("</head><body >");
      //   mywindow.document.write(
      //     document.getElementById("print-html").innerHTML
      //   );
      //   mywindow.document.write("</body></html>");

      //   mywindow.document.close(); // necessary for IE >= 10
      //   mywindow.focus(); // necessary for IE >= 10*/

      //   mywindow.print();
      //   mywindow.close();
      // }, 800);
    },
    search() {
      if (!this.inputSearch) return;

      this.productSearchList = [];
      this.get_insumos.docs.map(product => {
        if (product.disponivel) {
          if (
            product.nome.toLowerCase().includes(this.inputSearch.toLowerCase())
          ) {
            this.productSearchList.push(product);
          } else if (
            product.code &&
            product.code.toLowerCase().indexOf(this.inputSearch.toLowerCase()) >
              -1
          )
            this.productSearchList.push(product);
        }
      });
    },
    setModalAddProduto(produto, withIndex) {
      let prod = produto;
      prod.indexProd = parseInt(withIndex);
      this.$store.commit("set_produto", prod);
      this.modalStageProduct = true;
    },
    addProductToCart(product) {
      let newProducts = [];
      const products = this.get_compra.produtos;
      let isNew = true;
      products.map((oldProduct, index) => {
        if (
          (product.indexProd || product.indexProd === 0) &&
          product.indexProd === index
        ) {
          newProducts.push(product);
          isNew = false;
        } else {
          newProducts.push(oldProduct);
        }
      });
      if (isNew) {
        newProducts.push(product);
      }
      this.$store.commit("set_compra", {
        ...this.get_compra,
        acabamento: 0,
        produtos: newProducts
      });
      this.$refs.modalAddProduct.close();
      this.createGlobalMessage({
        type: "success",
        message: "Produto adicionado com sucesso!",
        timeout: 6000
      });
      this.calcula_valor_total_da_compra();
    },
    removeProductOfCart(product) {
      if (product.indexProd === 0 || product.indexProd > 0) {
        let new_products = [];
        this.get_compra.produtos.map((p, i) => {
          if (i !== product.indexProd) {
            new_products.push(p);
          }
        });
        this.get_compra.produtos = new_products;
        this.createGlobalMessage({
          type: "success",
          message: "Produto removido com sucesso!",
          timeout: 6000
        });
        this.$forceUpdate();
      }
      this.$refs.modalAddProduct.close();
      this.calcula_valor_total_da_compra();
    },
    initPayment() {
      //validations here pls
      if (this.get_compra.produtos.length) {
        if (!this.$refs.modalFechamento.modal) {
          this.$refs.modalFechamento.open();
        }
      } else {
        this.createGlobalMessage({
          type: "error",
          message: "Não é possível efetuar uma compra sem produtos!",
          timeout: 6000
        });
      }
    },
    initSendToManager() {
      this.get_compra.cliente = this.getLoggedUser;
      this.get_compra.triagem = this.modalTriagem.triage;
      this.get_compra.doctor = this.modalTriagem.doctor;
      this.get_compra.specialty = this.modalTriagem.specialty;
      this.get_compra.disponibility = this.modalTriagem.disponibility;
      this.get_compra.anexo_encaminhamento = this.modalTriagem.anexo_encaminhamento;
      this.get_compra.is_returned = this.modalTriagem.is_returned;
      this.get_compra.is_forwarding = this.modalTriagem.is_forwarding;
      this.get_compra.forwarding_id = this.modalTriagem.forwarding_id;
      this.get_compra.return_id = this.modalTriagem.return_id;
      const type = this.$route.query.type;
      this.get_compra.status = "agendada";
      this.get_compra.tipo_de_consulta = type;
      this.$refs.modalSendToManager.open();
    },
    limparComanda(item) {
      if (item && item.confirmation) {
        this.$store.commit("set_compra", {
          cliente: {},
          produtos: [],
          tipo: "delivery",
          subtotal: 0
        });
        this.$forceUpdate();
      } else {
        this.createConfirmAction({
          message:
            "Deseja limpar a venda? Isso irá apagar todos os produtos e zerar o valor.",
          icone: "mdi-close-circle",
          isScoped: true,
          action: () => this.limparComanda({ confirmation: true }),
          action_value: "ok"
        });
      }
    },
    salvarCompra() {
      this.salvar_compra();
    },
    getConfigPaymentSys() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.payment_sys_config = res.data.payment_sys_config;
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    }
  }
};
</script>

<style scoped>
.item_caixa {
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 6px;
  background: #333;
  color: #f2f2f2;
}

.caixa_font_color {
  color: #fff;
}

.caixa-status--fechado {
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 6px;
  background: rgb(236, 82, 82);
}
.caixa-status--aberto {
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 6px;
  background: green;
}

.activeCategory {
  background-color: #1173ff;
}
.font-green {
  color: #1173ff;
}
.item-list-product {
  border: 1px solid #e2e2e2;
  background: #f7f7f7;
  border-bottom: none;
  /* border-radius: 6px;
  margin-bottom: 6px; */
}
.max-height-list-products {
  max-height: 78vh;
}
.divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
.product-done {
  background: rgb(244, 167, 116) !important;
}
.max-height-list-products::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.max-height-list-products::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.max-height-list-products::-webkit-scrollbar-thumb {
  background-color: #1173ff; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.vitrify-mini {
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(1px);
}
</style>
