<template>
  <v-dialog persistent width="600" v-model="opened">
    <v-flex class="fonte" xs12>
      <v-card style="border-radius: 12px;">
        <v-flex xs12>
          <div
            class="windows-style"
            style="border-top-right-radius: 12px; border-top-right-radius: 12px;"
          >
            <div class="expande-horizontal">
              <v-flex xs12>
                <v-list color="transparent" dense nav>
                  <!-- Tem encaminhamentno -->
                  <v-list-item v-if="step == '0'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{
                          triage.encaminhamento.title
                        }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">
                        {{ triage.encaminhamento.description }}
                      </span>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- anexar encaminhamento -->
                  <v-list-item v-if="step == '1'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{
                          triage.anexar_encaminhamento.title
                        }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">{{
                        triage.anexar_encaminhamento.description
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Não tem encaminhamento -->
                  <v-list-item v-if="step == '2'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{
                          triage.nao_tem_encaminhamento.title
                        }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">{{
                        triage.nao_tem_encaminhamento.description
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Escolher especialidade -->
                  <v-list-item v-if="step == '3'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{
                          triage.escolher_especialidade.title
                        }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">{{
                        triage.escolher_especialidade.description
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Escolher especialista -->
                  <v-list-item v-if="step == '4'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte"
                          >{{ triage.escolher_especialista.title }}
                          {{ specialty.nome }}</span
                        >
                      </v-list-item-title>
                      <span class="fonte grey--text">{{
                        triage.escolher_especialista.description
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Escolher horário -->
                  <v-list-item v-if="step == '5'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">{{
                          triage.escolher_horario.title
                        }}</span>
                      </v-list-item-title>
                      <span class="fonte grey--text">{{
                        triage.escolher_horario.description
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="step == '6'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">
                          Tudo certo
                        </span>
                      </v-list-item-title>
                      <span class="fonte grey--text"
                        >Confira os dados da sua consulta e confirme o
                        agendamendo.</span
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="step == '7'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">
                          Encaminhamento
                        </span>
                      </v-list-item-title>
                      <span class="fonte grey--text"
                        >Veja os encaminhamentos indicados para você:</span
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="step == '8'">
                    <v-avatar
                      @click="$router.push('/dashboard')"
                      :size="31"
                      style="cursor: pointer;"
                      class="mr-2"
                      :color="$theme.primary"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span style="font-size: 12pt;" class="fonte">
                          Retorno
                        </span>
                      </v-list-item-title>
                      <span class="fonte grey--text"
                        >Veja os encaminhamentos indicados para você:</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
          </div>
        </v-flex>
        <div class="pa-3 windows-style-content">
          <v-window v-model="step">
            <v-window-item value="0">
              <div class="expande-horizontal centraliza wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal wrap centraliza py-6">
                    <div style="width: 300px;">
                      <LottieAnimation
                        class="pa-6 pb-0"
                        ref="anim"
                        :loop="true"
                        :animationData="require('@/assets/doctor.json')"
                      />
                    </div>
                    <v-flex class="px-12" xs12>
                      <span
                        class="grey--text fonte centraliza expande-horizontal text-center"
                        style="font-size: 12pt; text-align: center !important;"
                        >Olá, para que possamos lhe encaminhar corretamente,
                        precisamos saber se você tem encaminhamento.</span
                      >
                    </v-flex>
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-1" xs12>
                  <div class="expande-horizontal centraliza px-3">
                    <v-btn
                      rounded
                      class="mr-2"
                      color="primary"
                      @click="process_tem_encaminhamento('não')"
                      >Não
                    </v-btn>
                    <v-btn
                      rounded
                      class=""
                      color="primary"
                      @click="process_tem_encaminhamento('sim')"
                      >Sim
                    </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <!-- anexar comprovante -->
            <v-window-item value="1">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal column">
                    <TriagemFiles v-model="anexo_encaminhamento" />
                    <span
                      class="grey--text fonte expande-horizontal text-center"
                      style="font-size: 12pt; text-align: center !important;"
                      >Anexe o encaminhamento no botão acima</span
                    >
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    <v-btn
                      rounded
                      class="ml-2"
                      color="primary"
                      @click="setStep(0)"
                      text
                      ><v-icon>mdi-chevron-left</v-icon> Anterior
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="anexo_encaminhamento.length > 0"
                      rounded
                      class="mr-2"
                      color="primary"
                      @click="searchSpecialties"
                      >Próximo <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <!-- não tem encaminhamento -->
            <v-window-item value="2">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal centraliza">
                    <span
                      >Como você não tem encaminhamento, você será encaminhado
                      para escolher um clínico geral.</span
                    >
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    <v-btn
                      rounded
                      class="ml-2"
                      color="primary"
                      @click="setStep(0)"
                      text
                      ><v-icon>mdi-chevron-left</v-icon> Anterior
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      rounded
                      class="mr-2"
                      color="primary"
                      @click="
                        searchDoctorsBySpecialty({ nome: 'Clínico Geral' })
                      "
                      >Ok, próximo <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <!-- escolher a especialidade -->
            <v-window-item value="3">
              <div class="expande-horizontal wrap">
                <v-flex v-if="!loading" class="pa-3" xs12>
                  <div
                    v-if="specialties.length > 0"
                    class="expande-horizontal fonte centraliza"
                  >
                    <v-chip
                      :key="specialty._id"
                      v-for="specialty in specialties"
                      @click="searchDoctorsBySpecialty(specialty)"
                      class="mr-2 mb-2"
                    >
                      <v-icon size="17" class="mr-2"
                        >mdi-checkbox-blank-circle-outline</v-icon
                      >
                      {{ specialty.nome }}
                    </v-chip>
                  </div>
                  <span v-else class="expande-horizontal fonte centraliza"
                    >Não há especialidades disponíveis</span
                  >
                </v-flex>
                <v-flex v-else class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    Carregando especialidades disponíveis...
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <!-- escolher médico -->
            <v-window-item value="4">
              <div class="expande-horizontal wrap">
                <v-flex v-if="!loading" class="pa-3" xs12>
                  <div
                    v-if="doctors.length > 0"
                    class="expande-horizontal fonte column wrap"
                  >
                    <v-chip
                      :key="doctor._id"
                      v-for="doctor in doctors"
                      @click="searchDoctorDisponibility(doctor)"
                      class="mr-2 mb-2"
                    >
                      <v-icon size="17" class="mr-2"
                        >mdi-checkbox-blank-circle-outline</v-icon
                      >
                      {{ doctor.nome }}
                    </v-chip>
                  </div>
                  <span v-else class="expande-horizontal fonte centraliza"
                    >Não há médicos disponíveis para esta especialidade</span
                  >
                  <v-btn
                    :color="$theme.primary"
                    depressed
                    rounded
                    dark
                    v-if="!loading"
                    @click="setStep('0')"
                    class="ma-3"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                    Voltar
                  </v-btn>
                </v-flex>
                <v-flex v-else class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    Buscando médicos...
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <!-- escolher horário -->
            <v-window-item value="5">
              <div class="expande-horizontal wrap">
                <v-flex v-if="!loading" class="pa-3" xs12>
                  <div
                    v-if="disponibilities.length > 0"
                    class="expande-horizontal wrap fonte column"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel
                        :key="disponibility._id"
                        v-for="disponibility in disponibilities_group_by_day"
                      >
                        <v-expansion-panel-header>
                          <div class="expande-horizontal">
                            <v-icon size="17" class="mr-2">mdi-calendar</v-icon>
                            {{ disponibility.day }}
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            v-if="disponibility.disponibilities.length > 0"
                            class="expande-horizontal wrap fonte column"
                          >
                            <v-chip
                              :key="disponibility._id"
                              :disabled="
                                disponibility.transaction ? true : false
                              "
                              v-for="disponibility in disponibility.disponibilities"
                              @click="chooseDisponibility(disponibility)"
                              class="mr-2 mb-2"
                            >
                              <v-icon
                                v-if="!disponibility.transaction"
                                size="17"
                                class="mr-2"
                                >mdi-checkbox-blank-circle-outline</v-icon
                              >
                              <v-icon v-else size="17" class="mr-2" color="red"
                                >mdi-check-circle</v-icon
                              >
                              {{ disponibility.visit_hour_start }} até
                              {{ disponibility.visit_hour_end }}
                              <span
                                v-if="disponibility.transaction"
                                class="fonteMini fonte red--text ml-1"
                                >preenchido</span
                              >
                            </v-chip>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <span v-else class="expande-horizontal fonte centraliza"
                    >Não há horários disponíveis para este médico</span
                  >
                  <v-btn
                    :color="$theme.primary"
                    depressed
                    rounded
                    dark
                    v-if="!loading"
                    @click="setStep('4')"
                    class="ma-3"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                    Voltar
                  </v-btn>
                </v-flex>
                <v-flex v-else class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centraliza colimn px-3">
                    Buscando horários disponíveis ( {{ doctor.nome }} )...
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <v-window-item value="6">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-3" xs12>
                  <div class="expande-horizontal py-6 centraliza">
                    <v-flex xs12>
                      <v-list color="transparent" nav dense>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Especialidade</v-list-item-title>
                            <v-list-item-subtitle>{{
                              specialty.nome
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon color="green">mdi-check</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Médico</v-list-item-title>
                            <v-list-item-subtitle>{{
                              doctor.nome
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon color="green">mdi-check</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Data</v-list-item-title>
                            <v-list-item-subtitle>{{
                              disponibility.visit_date
                            }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ disponibility.visit_hour_start }} até
                              {{ disponibility.visit_hour_end }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon color="green">mdi-check</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="is_returned"></v-divider>
                        <v-list-item v-if="is_returned">
                          <v-list-item-content>
                            <v-list-item-title>Tipo</v-list-item-title>
                            <v-list-item-subtitle>Retorno</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon color="green">mdi-check</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="is_forwarding"></v-divider>
                        <v-list-item v-if="is_forwarding">
                          <v-list-item-content>
                            <v-list-item-title>Tipo</v-list-item-title>
                            <v-list-item-subtitle
                              >Encaminhamento</v-list-item-subtitle
                            >
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon color="green">mdi-check</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                  </div>
                </v-flex>
                <v-flex class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centraliza px-3">
                    <v-btn
                      rounded
                      class="mr-2"
                      color="green"
                      dark
                      @click="finalize"
                      >Confirmar agendamento
                    </v-btn>
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <!-- Escolher encaminhamento -->
            <v-window-item value="7">
              <div class="expande-horizontal wrap">
                <v-flex v-if="!loading" class="pa-3" xs12>
                  <div
                    v-if="forwarding_requests.length > 0"
                    class="expande-horizontal fonte column centraliza"
                  >
                    <v-chip
                      :key="forwarding._id"
                      v-for="forwarding in forwarding_requests"
                      @click="selectForwardingRequest(forwarding)"
                      class="mr-2 mb-2"
                    >
                      <v-icon size="17" class="mr-2"
                        >mdi-checkbox-blank-circle-outline</v-icon
                      >
                      Encaminhamento para
                      {{ forwarding.compra.forwarding_specialty.nome }}
                    </v-chip>
                  </div>
                  <span v-else class="expande-horizontal fonte centraliza"
                    >Verificamos que não há um encaminhamento indicado para
                    você, consulte-se com um clínico geral e seja encaminhado
                    para o especialista correto!</span
                  >
                  <div class="expande-horizontal column">
                    <v-btn
                      color="green"
                      dark
                      v-if="forwarding_requests.length === 0 && !loading"
                      @click="
                        searchDoctorsBySpecialty({ nome: 'Clínico Geral' })
                      "
                      class="fonte ma-3 mr-2 ml-0"
                      rounded
                      depressed
                    >
                      Escolha um Clínico Geral
                    </v-btn>
                    <v-btn
                      text
                      small
                      color="red darken-3"
                      dark
                      v-if="forwarding_requests.length === 0 && !loading"
                      @click="$router.push('/dashboard')"
                      class="ma-3 ml-0 fonte"
                    >
                      Sair
                    </v-btn>
                  </div>
                </v-flex>
                <v-flex v-else class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    Buscando seus encaminhamentos...
                  </div>
                </v-flex>
              </div>
            </v-window-item>

            <!-- Escolher retorno -->
            <v-window-item value="8">
              <div class="expande-horizontal wrap">
                <v-flex v-if="!loading" class="pa-3" xs12>
                  <div
                    v-if="return_requests.length > 0"
                    class="expande-horizontal fonte centraliza"
                  >
                    <v-chip
                      :key="return_request._id"
                      v-for="return_request in return_requests"
                      @click="selectReturnRequest(return_request)"
                      class="mr-2 mb-2"
                    >
                      <v-icon size="17" class="mr-2"
                        >mdi-checkbox-blank-circle-outline</v-icon
                      >
                      Retorno com {{ return_request.doctor.nome }}
                    </v-chip>
                  </div>
                  <span v-else class="expande-horizontal fonte"
                    >Verificamos que não há um retorno indicado para você,
                    consulte-se com um clínico geral e seja encaminhado para o
                    especialista certo. Após a primeira consulta, o médico
                    disponibilizará seu retorno!</span
                  >
                  <v-btn
                    dark
                    color="red darken-3"
                    v-if="return_requests.length === 0 && !loading"
                    @click="$router.push('/dashboard')"
                    class="ma-3 ml-0"
                  >
                    Voltar
                  </v-btn>
                </v-flex>
                <v-flex v-else class="pb-1 pt-6" xs12>
                  <div class="expande-horizontal centralize px-3">
                    Buscando seus retornos cadastrados...
                  </div>
                </v-flex>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import TriagemFiles from "./TriagemFiles.vue";
export default {
  components: {
    LottieAnimation,
    TriagemFiles
  },
  computed: {
    disponibilities_group_by_day() {
      const disponibilities = this.disponibilities;
      const disponibilities_group_by_day = [];
      disponibilities.forEach(disponibility => {
        const day = disponibility.visit_date;
        const day_index = disponibilities_group_by_day.findIndex(
          item => item.day === day
        );
        if (day_index === -1) {
          disponibilities_group_by_day.push({
            day,
            disponibilities: [disponibility]
          });
        } else {
          disponibilities_group_by_day[day_index].disponibilities.push(
            disponibility
          );
        }
      });
      return disponibilities_group_by_day;
    }
  },
  methods: {
    setStep(step) {
      this.step = `${step}`;
    },
    process_tem_encaminhamento(resp) {
      if (resp === "sim") {
        this.searchForwardingRequests();
        // this.step = '1' //disponivel o anexo de encaminhamento
      } else {
        this.step = "2";
      }
      this.triage.encaminhamento.response = resp;
    },
    searchForwardingRequests() {
      this.loading = true;
      this.step = "7";
      this.$run("transacoes/list-my-forwarding-requests", {}, "client").then(
        res => {
          this.forwarding_requests = res.data;
          this.loading = false;
        }
      );
    },
    searchReturnRequests() {
      this.step = "8";
      this.loading = true;
      this.$run("transacoes/list-my-return-requests", {}, "client").then(
        res => {
          this.return_requests = res.data;
          this.loading = false;
        }
      );
    },
    selectReturnRequest(return_request) {
      this.specialty = return_request.doctor.specialty;
      this.return_id = return_request._id;
      this.is_returned = true;
      this.searchDoctorDisponibility(return_request.doctor);
    },
    selectForwardingRequest(forwarding_request) {
      this.specialty = forwarding_request.compra.forwarding_specialty;
      this.forwarding_id = forwarding_request._id;
      this.is_forwarding = true;
      this.searchDoctorsBySpecialty(
        forwarding_request.compra.forwarding_specialty
      );
    },
    searchSpecialties() {
      this.step = "3";
      this.loading = true;
      this.$run("produtos/list-all-specialty").then(res => {
        this.specialties = res.data.docs;
        this.loading = false;
      });
    },
    searchDoctorsBySpecialty(specialty) {
      this.step = "4";
      this.loading = true;
      this.specialty = specialty;
      this.$run("funcionarios/list-doctors-by-specialty", { specialty }).then(
        res => {
          this.doctors = res.data.docs;
          this.loading = false;
        }
      );
    },
    searchDoctorDisponibility(doctor) {
      this.step = "5";
      this.loading = true;
      this.doctor = doctor;
      this.$run("transacoes/list-disponibility-by-doctor", { doctor }, "client")
        .then(res => {
          this.disponibilities = res.data.docs;
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          this.createGlobalMessage({
            message: e.response.data.message,
            type: "error",
            timeout: 4000
          });
          setTimeout(() => {
            this.searchDoctorDisponibility(doctor);
          }, 1000);
        });
    },
    chooseDisponibility(disponibility) {
      this.step = "6";
      this.disponibility = disponibility;
    },
    finalize() {
      this.$emit("input", {
        triage: this.triage,
        anexo_encaminhamento: this.anexo_encaminhamento,
        specialty: this.specialty,
        doctor: this.doctor,
        disponibility: this.disponibility,
        is_forwarding: this.is_forwarding,
        is_returned: this.is_returned,
        forwarding_id: this.forwarding_id,
        return_id: this.return_id
      });
      this.opened = false;
      this.step = "0";
    }
  },
  data() {
    return {
      forwarding_requests: [],
      forwarding_id: "",
      return_requests: [],
      return_id: "",
      is_forwarding: false,
      is_returned: false,
      anexo_encaminhamento: [],
      specialties: [],
      specialty: {},
      doctors: [],
      doctor: {},
      loading: false,
      disponibilities: [],
      disponibility: {},
      opened: true,
      step: "0",
      files: [],
      triage: {
        //1
        encaminhamento: {
          title: "Você tem encaminhamanto?",
          description: "",
          response: ""
        },
        //2
        anexar_encaminhamento: {
          title: "Anexe o encaminhamento",
          description: "Clique no botão abaixo e anexe o encaminhamento?",
          response: []
        },
        //3
        nao_tem_encaminhamento: {
          title: "Clínico Geral",
          description:
            "Como você ainda não possui encaminhamento, você precisa se consultar primeiro com um clínico geral",
          options: []
        },
        escolher_especialidade: {
          title: "Escolha a especialidade",
          description: "Veja as especialidades disponíveis abaixo",
          options: []
        },
        escolher_especialista: {
          title: `Especialidade: `,
          description: "Veja os profissionais disponíveis abaixo",
          options: []
        },
        escolher_horario: {
          title: "Escolha o dia da consulta",
          description: "Veja os dias e horários disponíveis abaixo",
          especialidade: {},
          horario: {}
        }
      }
    };
  },
  mounted() {
    const type = this.$route.query.type;
    if (type === "encaminhamento") {
      this.searchForwardingRequests();
    } else if (type === "retorno") {
      this.searchReturnRequests();
    }
  }
};
</script>
