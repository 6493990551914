<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#f7f7f7">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                {{ get_withdraw.user ? get_withdraw.user.nome : "Saque" }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div class="windows-style-content expande-horizontal wrap">
      <div class="expande-horizontal wrap">
        <v-flex xs12 md4 class="pa-2">
          <v-card
            style="border-radius: 16px;"
            :color="$theme.primary"
            dark
            height="100"
            class="expande-horizontal column"
          >
            <h1 class="fonte ma-3 mb-0 font-weight-light">
              {{
                $helper.formataSaldo(
                  get_withdraw.total_without_taxs || get_withdraw.value
                )
              }}
            </h1>
            <v-flex v-if="get_withdraw.user" xs12 class="white--text pb-3 px-3">
              <span class="fonte">Valor do saque </span>
            </v-flex>
            <v-flex
              v-if="get_withdraw.seller"
              xs12
              class="white--text pb-3 px-3"
            >
              <span class="fonte"
                >Este saque está
                <span class="font-weight-bold">
                  {{ get_withdraw.status | filterStatus }}
                </span>
              </span>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex xs12 md4 class="pa-2">
          <v-card
            style="border-radius: 16px;"
            color="green"
            dark
            height="100"
            class="expande-horizontal column"
          >
            <h1 class="fonte ma-3 mb-0 font-weight-light">
              {{ $helper.formataSaldo(balance.balance) }}
            </h1>
            <v-flex xs12 class="white--text pb-3 px-3">
              <span class="fonte">Saldo do solicitante</span>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex xs12 md4 class="pa-2">
          <v-card
            style="border-radius: 16px;"
            color="#333"
            dark
            height="100"
            class="expande-horizontal column"
          >
            <v-list-item style="background-color: #333;">
              <v-list-item-content>
                <v-list-item-title v-if="get_withdraw.user">
                  {{ get_withdraw.user.nome }}
                </v-list-item-title>
                <v-list-item-title v-if="get_withdraw.seller">
                  {{ get_withdraw.seller.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="get_withdraw.user">
                  {{ get_withdraw.user.cpf }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="get_withdraw.seller">
                  {{ get_withdraw.seller.cpf }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="get_withdraw.user">
                  {{ get_withdraw.user.telefone }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="get_withdraw.seller">
                  {{ get_withdraw.seller.phone }}
                </v-list-item-subtitle>
                <v-divider></v-divider>
                <span class="fonte grey--text">Solicitante</span>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
      </div>
      <v-flex xs12>
        <v-list color="transparent" class="pa-0 ma-0 fonte">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.description }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Nome da conta
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.bank }}
              </v-list-item-title>
              <v-list-item-subtitle>
                banco
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item v-if="get_withdraw.bank_account.agency">
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.agency }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Agência
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item v-if="get_withdraw.bank_account.account_type">
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.account_type }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Tipo de Conta
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item v-if="get_withdraw.bank_account.pix">
            <v-list-item-content>
              <v-list-item-title>
                {{ get_withdraw.bank_account.pix }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Pix
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
    </div>

    <div class="windows-style-bottom expande-horizontal pa-3">
      <v-btn
        v-if="hasBalance()"
        dark
        @click="approve_withdraw"
        tile
        :color="$theme.primary"
      >
        <span class="fonte"> Atualizar paga pago </span>
      </v-btn>
      <v-btn v-if="!hasBalance()" dark tile color="red" depressed>
        <span class="fonte"> Saldo insuficiente </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import ModalViewTransaction from "@/apps/Transacoes/components/modalView.vue";
import extenso from "extenso";

export default {
  // components: { ModalViewTransaction },
  data() {
    return {
      tab: 0,
      transactions: [],
      balance: {
        paid: 0,
        balance: 0
      },
      emit_vale_salario_form: {
        value: ""
      },
      emit_vale_salario_dialog: false
    };
  },
  watch: {
    "get_withdraw._id"() {
      this.getBalance();
    }
  },
  filters: {
    filterStatus(status) {
      switch (status) {
        case "pending":
          return "Aguardando";
        case "approved":
          return "Aprovado";
        case "rejected":
          return "Rejeitado";
        case "waiting":
          return "Aguardando";
      }
    }
  },
  computed: {
    ...mapGetters([
      "get_withdraw",
      "get_insumos",
      "get_permissions",
      "get_permissions_filtros",
      "get_modal_view_withdraw",
      "getLoggedUser"
    ])
    // balance() {
    //   let balance = 0;
    //   const calcBalance = transaction => {
    //     switch (transaction.tipo_de_transacao) {
    //       case "salario":
    //         balance += transaction.valor;
    //         break;
    //       case "vale":
    //         balance -= transaction.valor;
    //         break;
    //     }
    //   };
    //   this.transactions.map(transaction => calcBalance(transaction));
    //   return balance;
    // }
  },
  methods: {
    ...mapActions([
      "criar_withdraw",
      "list_permissions",
      "listar_insumos",
      "atualizar_withdraw",
      "listarProfessores",
      "fecha_modal_view_withdraw",
      "abre_modal_view_transacao",
      "enableLoading",
      "disableLoading"
    ]),
    hasBalance() {
      let value = this.get_withdraw.user
        ? this.get_withdraw.total_without_taxs
        : this.get_withdraw.value;
      return parseFloat(this.balance.balance) > parseFloat(value);
    },
    getBalance() {
      this.balance = {
        paid: 0,
        balance: 0
      };
      if (this.get_withdraw.user) {
        this.$run(
          "funcionarios/view-balance",
          { _id: `${this.get_withdraw.user._id}` },
          "admin"
        ).then(res => {
          this.balance = res.data;
        });
      } else {
        this.$run(
          "balance-report-seller/view-balance",
          { _id: this.get_withdraw.seller._id },
          "erp"
        ).then(res => {
          this.balance = res.data;
        });
      }
    },
    backToList() {
      this.$store.commit("set_withdraw", {});
    },
    approve_withdraw() {
      this.loading = true;
      this.enableLoading();
      this.$run("signed_transaction/approve-withdraw", this.get_withdraw).then(
        res => {
          this.loading = false;
          this.disableLoading();
          this.$store.commit("set_withdraw", res.data);
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: "Status atualizado para aprovado!",
            timeout: 5000
          });
        }
      );
    },
    openValeSalarioEmitter(type) {
      this.emit_vale_salario_dialog = true;
      this.emit_vale_salario_form = {
        withdraw: this.getLoggedUser,
        cliente: this.get_withdraw,
        type: type,
        value: ""
      };
    },
    closeValeSalarioEmitter() {
      this.emit_vale_salario_dialog = false;
    },
    formatNumberToExtense() {
      const value = this.emit_vale_salario_form.value || "0";
      const value_replaced = parseInt(value.replace(",", "."));
      this.emit_vale_salario_form.value_extense = extenso(value_replaced, {
        mode: "currency",
        currency: { type: "BRL" }
      });
      this.$forceUpdate();
    },
    emitVale() {
      if (this.$refs.formValeSalario.validate()) {
        this.$store.dispatch("enableLoading");
        this.$run("transacoes/criar-vale", this.emit_vale_salario_form).then(
          res => {
            this.emit_vale_salario_form._id = res._id ? res._id : res.data._id;
            this.$store.dispatch("disableLoading");
            this.closeValeSalarioEmitter();
            this.getValeSalario();
            this.$refs.emitterValeSalario.generatePdf();
          }
        );
      }
    },
    emitSalario() {
      if (this.$refs.formValeSalario.validate()) {
        this.$store.dispatch("enableLoading");
        this.$run("transacoes/criar-salario", this.emit_vale_salario_form).then(
          res => {
            this.emit_vale_salario_form._id = res._id ? res._id : res.data._id;
            this.$store.dispatch("disableLoading");
            this.closeValeSalarioEmitter();
            this.getValeSalario();
            this.$refs.emitterValeSalario.generatePdf();
          }
        );
      }
    },
    getValeSalario() {
      this.$run("transacoes/get-vale-salario", {
        _id: this.get_withdraw._id
      }).then(res => {
        this.transactions = res.data;
      });
    },
    emitSalarioVale() {
      if (this.emit_vale_salario_form.type === "vale") {
        this.emitVale();
      }
      if (this.emit_vale_salario_form.type === "salario") {
        this.emitSalario();
      }
    }
  },
  created() {
    this.list_permissions_filtros.all = true;
    this.list_permissions();
    this.listar_insumos();
    this.getValeSalario();
  }
};
</script>

<style>
.item-transac {
  background: #f0f0f2;
  border-radius: 6px;
  margin-bottom: 6px;
}
</style>
