<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#333">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                {{
                  get_permission.new ? "Criar permissão" : get_permission.nome
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="get_permission._id">
              <v-btn
                color="red"
                @click="excluir_permission_dialog(get_permission)"
                outlined
                small
                >Excluir</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div
      style="max-height: 85vh; min-height: 84vh; overflow: auto;"
      class="windows-style-content expande-horizontal wrap"
    >
      <v-flex xs12>
        <div class="expande-horizontal wrap py-2 pb-0 fonte">
          <v-flex xs12>
            <v-form ref="form">
              <v-flex class="pa-1" xs12>
                <v-text-field
                  v-model="get_permission.nome"
                  dense
                  flat
                  filled
                  label="Nome da permissão"
                  placeholder="Ex: Diretor"
                  hide-details
                  clearable
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                ></v-text-field>
              </v-flex>
              <v-flex class="pa-1" xs12>
                <v-select
                  v-model="get_permission.rota_inicial"
                  dense
                  flat
                  filled
                  hide-details
                  label="Rota Inicial"
                  placeholder="Ex: Diretor"
                  item-text="nome"
                  item-value="rota"
                  clearable
                  :items="permissions"
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                ></v-select>
              </v-flex>
              <v-flex class="px-1" xs12>
                <div class="expande-horizontal wrap">
                  <v-flex
                    v-for="permission in permissions"
                    :key="`perm-${permission.nome}`"
                    class="pa-1"
                    xs12
                    md3
                  >
                    <v-list
                      style="border-radius: 12px;"
                      dark
                      height="90"
                      color="#444"
                      dense
                      class="ma-0 pa-0"
                    >
                      <v-list-item class="px-2">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon size="12">mdi-lock-outline</v-icon>
                            {{ permission.nome }}
                          </v-list-item-title>
                          <span class="fonte fonteMini grey--text">{{
                            permission.description
                          }}</span>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            small
                            :color="$theme.primary"
                            v-model="
                              get_permission.permission[permission.permission]
                            "
                          ></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </div>
              </v-flex>
            </v-form>
          </v-flex>
          <div class="expande-horizontal pa-2">
            <v-btn
              dark
              @click="valida_form"
              class="black--text elevation-0"
              rounded
              :color="$theme.primary"
            >
              <span class="fonte"> Salvar </span>
            </v-btn>
          </div>
        </div>
      </v-flex>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      permissions: [
        {
          nome: "Início Médico",
          permission: "inicio-medico",
          description: "Menu do médico.",
          rota: "/medico"
        },
        {
          nome: "Início Paciente",
          permission: "inicio-paciente",
          description: "Menu do paciente.",
          rota: "/dashboard"
        },
        {
          nome: "Minha Disponibilidade",
          permission: "minha-agenda",
          description:
            "O usuário poderá informar seus horários de atendimento.",
          rota: "/minha-agenda"
        },
        // {
        //   nome: "Agenda",
        //   permission: "agenda",
        //   description:
        //     "O usuário poderá criar e gerenciar agendamentos para todos os usuários.",
        //   rota: "/agenda"
        // },
        {
          nome: "Especialidades",
          permission: "especialidades",
          description:
            "O usuário poderá visualizar, criar, editar e excluir produtos.",
          rota: "/insumos"
        },
        {
          nome: "Clientes",
          permission: "clientes",
          description:
            "O usuário poderá visualizar, criar, editar e excluir clientes.",
          rota: "/clientes"
        },
        {
          nome: "Solicitações de saque",
          permission: "solicitacoes-saque",
          description: "O usuário poderá visualizar e aprovar saques.",
          rota: "/solicitacoes-de-saque"
        },
        {
          nome: "Saques",
          permission: "meus-saques",
          description: "O usuário poderá visualizar saques e solicitar novos.",
          rota: "/meus-saques"
        },
        {
          nome: "Colaboradores",
          permission: "colaboradores",
          description:
            "O usuário poderá visualizar, criar, editar e excluir colaboradores.",
          rota: "/funcionarios"
        },
        {
          nome: "Permissões",
          permission: "permissoes",
          description:
            "O usuário poderá visualizar, criar, editar e excluir permissões, cuidado.",
          rota: "/permissoes"
        },
        {
          nome: "Sistema do Vendedor",
          permission: "inicio-vendedor",
          description: "O vendedor poderá acompanhar suas vendas e comissões.",
          rota: "/inicio-vendedor"
        },
        {
          nome: "Configurações",
          permission: "configuracoes",
          description:
            "O usuário poderá visualizar e editar os dados da empresa.",
          rota: "/configurar-loja"
        },
        {
          nome: "Vendedores",
          permission: "vendedor",
          description: "O usuário poderá visualizar os vendedores.",
          rota: "/vendedores"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["get_permission", "getLoggedUser"])
  },
  methods: {
    ...mapActions([
      "criar_permission",
      "atualizar_permission",
      "fecha_modal_view_permission"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_permission._id
          ? this.atualizar_permission()
          : this.criar_permission();
      }
    },
    backToList() {
      this.$store.commit("set_permission", {});
      this.$forceUpdate();
    }
  }
};
</script>
